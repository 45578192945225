import React, { memo, useCallback } from "react";
import styled from "styled-components";
import RatingUnselectedStarIcon from "../../../../common/assets/icons/lesson_icons/rating_unselected_star_icon";
import RatingSelectedStarIcon from "../../../../common/assets/icons/lesson_icons/rating_selected_star_icon";

const RatingAndFeedbackSectionContainer = styled.div`
  width: 100%;
`;

const LessonRatingContainer = styled.div`
  & > h5 {
    margin-bottom: 4px;
    color: #000;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
`;

const RatingStarsContainer = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
`;

const RatingStar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 39px;
  height: 39px;

  ${({ disabled }) =>
    !disabled &&
    `
    cursor: pointer;
    
    svg {
      transition: all 0.2s ease;
    }

    &:hover svg {
      transform: scale(1.1);
   }
  `}
`;

const LessonFeedbackContainer = styled.div`
  width: 100%;
  margin-top: 32px;

  & > h5 {
    margin-bottom: 6px;
    text-align: left;
    color: #828282;
    font-size: 10px;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: -0.1px;

    @media (max-width: 500px) {
      font-size: 11px;
    }
  }
`;

const FeedbackInputContainer = styled.div`
  overflow: hidden;
  border-radius: 15px;
`;

const FeedbackInput = styled.textarea`
  resize: none;
  width: 100%;
  min-height: 200px;
  background: #eaeaea;
  padding: 10px 14px;
  color: #000;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;

  ::-webkit-scrollbar {
    width: 3px;
  }
`;

const RatingAndFeedbackSection = ({
  lessonRating,
  setLessonRating,
  feedbackValue,
  setFeedbackValue,
}) => {
  const handleRatingClick = useCallback((starValue) => {
    setLessonRating(starValue);
  }, []);

  const handleFeedbackValue = useCallback((event) => {
    setFeedbackValue(event.target.value);
  }, []);

  return (
    <RatingAndFeedbackSectionContainer>
      <LessonRatingContainer>
        <h5>Пожалуйста, оцените урок</h5>
        <RatingStarsContainer>
          {[1, 2, 3, 4, 5].map((starValue) => (
            <RatingStar
              key={starValue}
              onClick={() => handleRatingClick(starValue)}
            >
              {starValue <= lessonRating ? (
                <RatingSelectedStarIcon />
              ) : (
                <RatingUnselectedStarIcon />
              )}
            </RatingStar>
          ))}
        </RatingStarsContainer>

        <LessonFeedbackContainer>
          <h5>Оставьте отзыв об этом уроке. Команда читает все отзывы!</h5>

          <FeedbackInputContainer>
            <FeedbackInput
              value={feedbackValue}
              onChange={handleFeedbackValue}
              placeholder="Напишите отзыв..."
            />
          </FeedbackInputContainer>
        </LessonFeedbackContainer>
      </LessonRatingContainer>
    </RatingAndFeedbackSectionContainer>
  );
};

export default memo(RatingAndFeedbackSection);
