import React, { memo, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import ChapterNavigationItem from "./chapter_navigation_item";

const BookChapterButtonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  transition: 0.7s max-height;
  overflow: hidden;
  margin-bottom: 10px;
`;

const ChaptersNavigation = ({
  isExpanded,
  book,
  setSelectedVerses,
  currentBibleParams,
  setCurrentBibleParams,
}) => {
  const bookRef = useRef();
  const [chaptersList, setChaptersList] = useState(null);

  const createChaptersArray = (number) =>
    Array.from({ length: number }, (_, index) => index + 1);

  useEffect(() => {
    setChaptersList(createChaptersArray(book.book_chapters));
  }, [book]);

  return (
    <>
      {isExpanded && (
        <BookChapterButtonsContainer isExpanded={isExpanded} ref={bookRef}>
          {chaptersList?.map((chapterNum) => {
            return (
              <ChapterNavigationItem
                key={book.book_code + chapterNum}
                chapterNum={chapterNum}
                book={book}
                setSelectedVerses={setSelectedVerses}
                currentBibleParams={currentBibleParams}
                setCurrentBibleParams={setCurrentBibleParams}
              />
            );
          })}
        </BookChapterButtonsContainer>
      )}
    </>
  );
};

export default memo(ChaptersNavigation);
