import React, { memo, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { useSearchParams } from "react-router-dom";
import BibleChapterArrowIcon from "../../../../common/assets/icons/bible_page_icons/bible_chapter_arrow_icon";
import VerticalLine from "../../../../common/assets/icons/vertical_line";
import { BIBLE_BOOK_NAMES_DATA } from "../../bible_page_data";
import { useBiblePageContext } from "../../bible_page_context";

const VersesHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const BibleChapterAddressWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 24px;
  margin-bottom: 16px;
  gap: ${({ hasSecondTranslation }) => (hasSecondTranslation ? "6px" : "13px")};
`;

const BibleChapterAddressContainer = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 5px;
  padding: 0 10px;
  min-width: 215px;
  height: 100%;
  background: #fff;
  border-radius: 15px;

  @media (max-width: 1100px) {
    min-width: 0px;
    padding-right: 15px;
    width: max-content;
    max-width: ${({ hasSecondTranslation }) =>
      hasSecondTranslation ? "130px" : "max-content"};
  }

  @media (max-width: 830px) {
    width: max-content;
    max-width: max-content;
  }
`;

const BibleChapterArrowsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  height: 100%;
  ${({ hasSecondTranslation }) => hasSecondTranslation && "margin-right: 5px;"}
`;

const BibleChapterArrowButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background: #fff;
  cursor: pointer;
  padding: 0 2px 0 0;
  transition: 0.2s;

  &: hover {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
      rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  }
`;

const BibleChapterArrowButtonRight = styled(BibleChapterArrowButton)`
  padding: 0 0 0 1px;
`;

const BibleChapterArrowRightIcon = styled(BibleChapterArrowIcon)`
  transform: rotate(180deg);
`;

const BibleTranslationVersion = styled.div`
  color: #f8d254;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.4px;
  user-select: none;
  text-transform: uppercase;
`;

const BibleChapterAddress = styled.div`
  display: flex;
  gap: 3px;
  flex-wrap: nowrap;
  font-size: 10px;
  line-height: 120%;
  color: #1e1e1e;
  overflow: hidden;
  letter-spacing: -0.1px;
`;

const ChapterName = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-shrink: 1;
`;

const ChapterNumber = styled.span`
  flex-shrink: 0;
`;

const StrongButton = styled.div`
  display: flex;
  align-items: center;
  color: #1e1e1e;
  font-size: 12px;
  background: #fff;
  padding: 0 13px;
  margin-right: 12px;
  height: 24px;
  border-radius: 15px;
  user-select: none;
  cursor: pointer;
  transition: 0.2s;

  ${({ showStrongsCode }) =>
    showStrongsCode &&
    `
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  `}

  ${({ showStrongsCode }) =>
    !showStrongsCode &&
    `
    &: hover {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
  }
  `}
`;

const VerticalLineStyled = styled(VerticalLine)`
  height: 13px;
`;

const VersesHeader = ({
  currentTranslation,
  chapterData,
  bibleId,
  showStrongsCode,
  hasSecondTranslation,
  setShowStrongsCode,
}) => {
  const { currentTranslationData } = useBiblePageContext();
  const [searchParams, setSearchParams] = useSearchParams();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const strongButtonName =
    hasSecondTranslation && windowWidth >= 830 && windowWidth <= 950
      ? "С"
      : "Стронг";

  const bibleNameData =
    BIBLE_BOOK_NAMES_DATA[currentTranslation?.bible_locale]?.[
      chapterData?.[0]?.book_code
    ];

  const currentBibleName = bibleNameData?.secondName
    ? bibleNameData.secondName
    : bibleNameData?.name || chapterData[0]?.book_code_orig;

  const currentChapterNum = chapterData?.[0]?.chapter_num;
  const currentBookCode = chapterData?.[0]?.book_code;
  const currentBookIndex = currentTranslationData?.findIndex(
    (book) => book.book_code === currentBookCode
  );
  const currentBook = currentTranslationData?.[currentBookIndex];

  const isFirstBook = currentBookIndex === 0;
  const isFirstChapter = currentChapterNum === 1;

  const isLastBook = currentBookIndex === currentTranslationData?.length - 1;
  const isLastChapter = currentChapterNum === currentBook?.book_chapters;

  const handleShowStrongsCodes = useCallback((event) => {
    event.stopPropagation();
    setShowStrongsCode((prevState) => !prevState);
  }, []);

  const updateChapterAndBook = (newBookCode, newChapterNum) => {
    const updatedSearchParams = new URLSearchParams(searchParams);
    updatedSearchParams.set("bookId", newBookCode);
    updatedSearchParams.set("chapterId", newChapterNum);
    setSearchParams(updatedSearchParams);
  };

  const onClickLeftArrow = useCallback(
    (event) => {
      event.stopPropagation();

      if (currentChapterNum > 1) {
        updateChapterAndBook(currentBookCode, currentChapterNum - 1);
      } else if (currentBookIndex > 0) {
        const prevBook = currentTranslationData[currentBookIndex - 1];
        updateChapterAndBook(prevBook.book_code, prevBook.book_chapters);
      }
    },
    [chapterData, currentTranslationData, searchParams, setSearchParams]
  );

  const onClickRightArrow = useCallback(
    (event) => {
      event.stopPropagation();

      if (currentChapterNum < currentBook?.book_chapters) {
        updateChapterAndBook(currentBookCode, currentChapterNum + 1);
      } else if (currentBookIndex < currentTranslationData.length - 1) {
        const nextBook = currentTranslationData[currentBookIndex + 1];
        updateChapterAndBook(nextBook.book_code, 1);
      }
    },
    [chapterData, currentTranslationData, searchParams, setSearchParams]
  );

  return (
    <VersesHeaderContainer>
      <BibleChapterAddressWrapper hasSecondTranslation={hasSecondTranslation}>
        {!!chapterData && (
          <BibleChapterAddressContainer
            hasSecondTranslation={hasSecondTranslation}
          >
            <BibleTranslationVersion>{bibleId}</BibleTranslationVersion>
            <VerticalLineStyled />
            <BibleChapterAddress>
              <ChapterName>{currentBibleName}</ChapterName>
              <ChapterNumber>{currentChapterNum}</ChapterNumber>
            </BibleChapterAddress>
          </BibleChapterAddressContainer>
        )}
        <BibleChapterArrowsContainer
          hasSecondTranslation={hasSecondTranslation}
        >
          {(!isFirstBook || !isFirstChapter) && (
            <BibleChapterArrowButton onClick={onClickLeftArrow}>
              <BibleChapterArrowIcon />
            </BibleChapterArrowButton>
          )}

          {(!isLastBook || !isLastChapter) && (
            <BibleChapterArrowButtonRight onClick={onClickRightArrow}>
              <BibleChapterArrowRightIcon />
            </BibleChapterArrowButtonRight>
          )}
        </BibleChapterArrowsContainer>
      </BibleChapterAddressWrapper>

      {currentTranslation?.bible_code === "rst" && (
        <StrongButton
          showStrongsCode={showStrongsCode}
          onClick={handleShowStrongsCodes}
        >
          {strongButtonName}
        </StrongButton>
      )}
    </VersesHeaderContainer>
  );
};

export default memo(VersesHeader);
