import React, { useCallback } from "react";
import styled from "styled-components";

const BibleStrongRootsContainer = styled.div`
  color: #6b6b6b;
  font-size: 16px;
  line-height: 140%;

  .title {
    margin-right: 5px;
  }
`;

const CodeStrongContainer = styled.span``;

const CodeStrong = styled.span`
  color: #5172ea;
  font-size: 14px;
  line-height: 120%;
  cursor: pointer;
`;

const BibleStrongRoots = ({
  strongRoots,
  setCurrentCodeIndex,
  setCodesStrongHistory,
  currentCodeIndex,
}) => {
  const onClickCode = useCallback(
    (event, code) => {
      event.stopPropagation();

      setCodesStrongHistory((prevState) => {
        const newHistory = prevState.slice(0, currentCodeIndex + 1);

        const updatedHistory = [...newHistory, code];

        setCurrentCodeIndex(newHistory.length);

        return updatedHistory;
      });
    },
    [setCodesStrongHistory, currentCodeIndex]
  );

  return (
    <BibleStrongRootsContainer>
      <span className="title">Однокоренные:</span>

      {strongRoots?.length > 0 ? (
        <>
          {strongRoots?.map((code, index) => (
            <CodeStrongContainer key={code}>
              <CodeStrong onClick={(e) => onClickCode(e, code)}>
                {code}
              </CodeStrong>
              {index < strongRoots.length - 1 && ","}{" "}
            </CodeStrongContainer>
          ))}
        </>
      ) : (
        "-"
      )}
    </BibleStrongRootsContainer>
  );
};

export default BibleStrongRoots;
