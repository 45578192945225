import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { QuestionsFilterOptionsContainer } from "../../../question_styled_components";
import AdminPageCalendarArrow from "../../../../../../common/assets/icons/admin_page_icons/admin_page_calendar_arrow";
import { BASE_API_URL } from "../../../../../../common/endpoints";
import { BIBLE_BOOK_NAMES_DATA } from "../../../../../bible/bible_page_data";
import BiblePlaceDropdownBooks from "./bible_place_dropdown_books";
import SpinnerLoader from "../../../../../../common/components/loaders/spinner_loader";

const BiblePlaceDropdownContainer = styled(QuestionsFilterOptionsContainer)`
  padding: 0;
  left: -5px;
  width: 646px;
  min-height: 200px;
`;

const BiblePlaceDropdownTestament = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 38px;
  padding: 0 11px;
  border-bottom: 1px solid #cacaca;
`;

const TestamentTitle = styled.div`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 700;
  background: linear-gradient(180deg, #5172ea -18.38%, #7a5aee 108.82%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const TestamentArrowLeft = styled.div`
  display: flex;
  align-items: center;
  padding: 0 12px;
  height: 70%;
  margin-top: 2px;
  cursor: pointer;

  &: hover {
    transform: scale(1.2);
    transition: 0.2s;
  }
`;

const TestamentArrowRight = styled(TestamentArrowLeft)`
  transform: rotate(180deg);

  &: hover {
    transform: scale(1.2) rotate(180deg);
    transition: 0.2s;
  }
`;

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  margin-top: 35px;
`;

const BiblePlaceDropdownButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 10px;
  height: 46px;
  width: 100%;
  padding: 0 16px;
  border-top: 1px solid #cacaca;
`;

const DropdownButton = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
  width: max-content;
  padding: 0 17px;
  border-radius: 8px;
  background: rgb(97 105 236);
  color: #fff;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  cursor: default;
  transition: 0.2s;

  ${({ disabled }) =>
    !disabled &&
    `  &:hover {
         cursor: pointer;
         opacity: 1;
         box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
            rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
   }`}
`;

const DropdownCancelButton = styled(DropdownButton)`
  opacity: 0.6;
`;
const DropdownApplyButton = styled(DropdownButton)`
  opacity: ${({ hoverOnCancelButton, disabled }) =>
    hoverOnCancelButton || disabled ? "0.6" : "1"};
`;

const BiblePlaceDropdown = ({
  handleExtendOptions,
  setQuestionsFilterSettings,
}) => {
  const [isBooksLoading, setBooksLoading] = useState(false);
  const [selectedTestament, setSelectedTestament] = useState("ot"); // ot, nt
  const [bibleBooksList, setBibleBooksList] = useState([]);
  const [sortedBooksByTestaments, setSortedBooksByTestaments] = useState([]);
  const [selectedBook, setSelectedBook] = useState({});
  const [selectedChapters, setSelectedChapters] = useState([]);
  const [hoverOnCancelButton, setHoverOnCancelButton] = useState(false);

  const disabledApplyButton = !selectedBook.book_code;

  const testament = selectedTestament === "ot" ? "Ветхий Завет" : "Новый Завет";

  const toggleHoverOnDeleteButton = useCallback(() => {
    setHoverOnCancelButton((prevState) => !prevState);
  }, []);

  useEffect(() => {
    setBooksLoading(true);
    fetch(`${BASE_API_URL}/bible/books?bible=rst`)
      .then((response) => response.json())
      .then((bibleData) => {
        const updateBooksNames = bibleData.books.map((book) => {
          return {
            ...book,
            book_name: BIBLE_BOOK_NAMES_DATA["ru"][book.book_code].name,
            number: BIBLE_BOOK_NAMES_DATA["ru"][book.book_code].number,
          };
        });
        setBibleBooksList(updateBooksNames);
      })
      .finally(() => {
        setBooksLoading(false);
      });
  }, []);

  useEffect(() => {
    if (bibleBooksList) {
      const sortByTestaments = bibleBooksList
        .filter((book) => book.book_place === selectedTestament)
        .sort((a, b) => a.number - b.number);

      setSortedBooksByTestaments(sortByTestaments);
    }
  }, [bibleBooksList, selectedTestament]);

  useEffect(() => {
    setSelectedBook({});
  }, [selectedTestament]);

  const handleBibleTestament = useCallback(() => {
    setSelectedTestament((prevState) => (prevState === "ot" ? "nt" : "ot"));
  }, []);

  const onClickCancelButton = useCallback((event) => {
    handleExtendOptions(event, "");
  }, []);

  const onClickApplyButton = useCallback(
    (event) => {
      if (disabledApplyButton) return;

      setQuestionsFilterSettings((prevState) => {
        return {
          ...prevState,
          question_bible: [
            ...prevState.question_bible,
            {
              bibleBookCode: selectedBook.book_code,
              bibleChapters: selectedChapters,
            },
          ],
        };
      });
      handleExtendOptions(event, "");
    },
    [selectedBook, selectedChapters]
  );

  return (
    <BiblePlaceDropdownContainer onClick={(e) => e.stopPropagation()}>
      <BiblePlaceDropdownTestament>
        <TestamentArrowLeft onClick={handleBibleTestament}>
          <AdminPageCalendarArrow />
        </TestamentArrowLeft>
        <TestamentTitle>{testament}</TestamentTitle>
        <TestamentArrowRight onClick={handleBibleTestament}>
          <AdminPageCalendarArrow />
        </TestamentArrowRight>
      </BiblePlaceDropdownTestament>

      {!isBooksLoading ? (
        <BiblePlaceDropdownBooks
          bibleBooks={sortedBooksByTestaments}
          selectedBook={selectedBook}
          setSelectedBook={setSelectedBook}
          selectedChapters={selectedChapters}
          setSelectedChapters={setSelectedChapters}
        />
      ) : (
        <LoaderContainer>
          <SpinnerLoader />
        </LoaderContainer>
      )}

      <BiblePlaceDropdownButtons>
        <DropdownCancelButton
          onMouseEnter={toggleHoverOnDeleteButton}
          onMouseLeave={toggleHoverOnDeleteButton}
          onClick={onClickCancelButton}
        >
          Отмена
        </DropdownCancelButton>
        <DropdownApplyButton
          hoverOnCancelButton={hoverOnCancelButton}
          onClick={onClickApplyButton}
          disabled={disabledApplyButton}
        >
          Выбрать
        </DropdownApplyButton>
      </BiblePlaceDropdownButtons>
    </BiblePlaceDropdownContainer>
  );
};

export default BiblePlaceDropdown;
