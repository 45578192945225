import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { LESSON_COLORS_THEME } from "../../../../../utils/enums";

const bounceAnimation = {
  initial: { scale: 1 },
  animate: {
    scale: [1, 1.2, 0.9, 1.1, 1],
    transition: {
      duration: 0.7,
      times: [0, 0.3, 0.5, 0.7, 1],
      ease: "easeOut",
    },
  },
};

const noBounceAnimation = {
  initial: { scale: 1 },
  animate: { scale: 1 },
};

const ButtonWrapper = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ButtonBackgroundWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ background }) => background};
  border-radius: ${({ pressedButton }) => (pressedButton ? "18px" : "15px")};
  height: 100%;
  cursor: default;
  overflow: hidden;

  ${({ pressedButton }) =>
    !pressedButton &&
    `
    &:hover {
      box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
      transform: scale(1.05);
      transition: all 0.2s;
      cursor: pointer;
    }
  `}
`;

const DialogueUserAnswerButton = styled.div`
  display: flex;
  align-items: center;
  padding: 7px 27px;
  min-height: 54px;
  max-width: 480px;
  background: rgb(255 255 255 / 0%);
  user-select: none;
  color: ${({ backgroundIsWhite }) => (backgroundIsWhite ? "#1e1e1e" : "#fff")};

  ${({ pressedButton, color, colorTheme }) =>
    pressedButton &&
    `background: ${
      colorTheme === "LIGHT" ? "rgb(255 255 255 / 100%)" : "rgb(68 68 68)"
    };
     color: ${color};`}

  & > p {
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
  }

  @media (max-width: 500px) {
    font-size: 16px;
  }
`;

const AnswerTypeIsButton = ({
  answer,
  componentData,
  inComponent,
  withAnimation,
  colorTheme,
  setLessonData,
  setUserAnswersData,
  userLessonPageRef,
  dialogueComponentRef,
  setActiveBranch,
}) => {
  const answerRef = useRef(null);
  const [showButton, setShowButton] = useState(true);
  const [pressedButton, setPressedButton] = useState(false);
  const [clickAnimation, setClickAnimation] = useState(false);

  const selectedBranches = componentData.selectedBranches;
  const unselected = !selectedBranches.find(
    (branch) => branch === answer.branch
  );

  const textColor =
    LESSON_COLORS_THEME[colorTheme]?.COLORS?.[componentData.background];
  const backgroundColor =
    LESSON_COLORS_THEME[colorTheme]?.GRADIENTS?.[componentData.background];

  useEffect(() => {
    if (
      selectedBranches[answer.level + 1] &&
      selectedBranches[answer.level + 1] === answer.branch &&
      inComponent !== "create_lesson"
    ) {
      setPressedButton(true);
    } else {
      setPressedButton(false);
    }
  }, [selectedBranches]);

  useEffect(() => {
    if (
      selectedBranches[answer.level + 1] &&
      selectedBranches[answer.level + 1] !== answer.branch
    ) {
      setClickAnimation(false);
      setShowButton(false);
    } else {
      setShowButton(true);
    }
  }, [selectedBranches]);

  useEffect(() => {
    if (inComponent === "preview_page") {
      return () =>
        setLessonData((prevState) => {
          const newLessonData = prevState.lesson_data.map((component) =>
            component.id === componentData.id
              ? {
                  ...component,
                  selectedBranches: ["first"],
                }
              : component
          );

          return {
            ...prevState,
            lesson_data: newLessonData,
          };
        });
    }
  }, [withAnimation]);

  const onClickAnswerButton = useCallback(() => {
    if (
      (inComponent === "preview_page" || inComponent === "user_lesson") &&
      unselected
    ) {
      setLessonData((prevState) => {
        const newLessonData = prevState.lesson_data.map((component) =>
          component.id === componentData.id
            ? {
                ...component,
                selectedBranches: [
                  ...component.selectedBranches,
                  answer.branch,
                ],
              }
            : component
        );

        return {
          ...prevState,
          lesson_data: newLessonData,
        };
      });

      const { id, selectedBranches } = componentData;

      setUserAnswersData((prevState) => {
        const updatedAnswer = {
          id,
          selectedBranches: [...selectedBranches, answer.branch],
        };
        const hasElement = prevState.some((element) => element.id === id);

        return hasElement
          ? prevState.map((elem) => (elem.id !== id ? elem : updatedAnswer))
          : [...prevState, updatedAnswer];
      });

      setActiveBranch(answer.branch);

      setClickAnimation(true);
      setTimeout(() => setClickAnimation(false), 700);

      setTimeout(
        () => {
          const parentElement = dialogueComponentRef?.current;

          if (parentElement) {
            userLessonPageRef?.current.scrollBy({
              top: 200,
              behavior: "smooth",
            });
          }
        },
        componentData.data?.[answer.branch] ? 900 : 300
      );
    }
  }, [
    answer.branch,
    componentData,
    inComponent,
    unselected,
    dialogueComponentRef,
    setLessonData,
    setUserAnswersData,
    userLessonPageRef,
  ]);

  return (
    <>
      {showButton && (
        <ButtonWrapper
          animate={clickAnimation ? "animate" : "initial"}
          variants={
            inComponent === "create_lesson" || !unselected
              ? noBounceAnimation
              : bounceAnimation
          }
        >
          <ButtonBackgroundWrapper
            background={backgroundColor}
            pressedButton={pressedButton}
          >
            <DialogueUserAnswerButton
              ref={answerRef}
              pressedButton={pressedButton}
              colorTheme={colorTheme}
              backgroundIsWhite={componentData.background === "WHITE"}
              color={textColor}
              onClick={onClickAnswerButton}
            >
              <p>{answer.answer_text}</p>
            </DialogueUserAnswerButton>
          </ButtonBackgroundWrapper>
        </ButtonWrapper>
      )}
    </>
  );
};

export default memo(AnswerTypeIsButton);
