import React, { useState, useCallback } from "react";
import styled from "styled-components";
import axios from "../../axios_config";
import AuthPasswordInput from "./auth_components/auth_password_input";
import AuthTextInput from "./auth_components/auth_text_input";
import OAuthGoogleButton from "./auth_components/oauth_google_button";
import LoginButton from "./auth_components/auth_login_button";
import { BASE_API_URL } from "../../common/endpoints";
import { useAuthPageContext } from "./auth_page_context";
import { Dot, emailRegex, Loader, passwordRegex } from "./auth_page";
import { useUserDataContext } from "../../user_data_context";
import UserLocationSelect from "../../common/components/user_location_select";

const AuthInputsContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
`;

const AuthBottomButtonsContainer = styled.div`
  margin-top: 26px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const AuthInputsSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 10px;
`;

const ErrorMessage = styled.div`
  position: absolute;
  top: -23px;
  left: 50%;
  transform: translateX(-50%);
  width: max-content;
  color: #f12b2b;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
`;

const getValidationMessages = ({ name, email, password, confirmPassword }) => {
  const validationMsgObject = {};
  if (!name) validationMsgObject.name = "Обязательно для заполнения";
  if (!email) validationMsgObject.email = "Обязательно для заполнения";
  if (email && !emailRegex.test(email))
    validationMsgObject.email = "Некорректный адрес электронной почты";
  if (!password) validationMsgObject.password = "Обязательно для заполнения";
  if (password && !passwordRegex.test(password))
    validationMsgObject.password =
      "Минимум 8 символов (латинские буквы и цифры)";
  if (!confirmPassword)
    validationMsgObject.confirmPassword = "Обязательно для заполнения";
  if (confirmPassword && password && confirmPassword !== password)
    validationMsgObject.confirmPassword = "Пароли не совпадают";

  return validationMsgObject;
};

const RegistrationPage = () => {
  const { userLanguage } = useUserDataContext();
  const {
    setCurrentAuthScreen,
    redirectPath,
    registrationData,
    setRegistrationData,
  } = useAuthPageContext();

  const [validationMessages, setValidationMessages] = useState({});
  const [loading, setLoading] = useState(false);

  const handleRegistrationData = useCallback((key, value) => {
    setRegistrationData((prevData) => ({
      ...prevData,
      [key]: value,
    }));

    if (["email", "name", "password", "confirmPassword"].includes(key)) {
      setValidationMessages((prevMessages) => ({
        ...prevMessages,
        [key]: "",
      }));
    }
  }, []);

  const onSendCodeToMail = async () => {
    if (loading) return;

    setLoading(true);
    setValidationMessages({});

    const newValidationMessages = getValidationMessages({
      name: registrationData.name,
      email: registrationData.email,
      password: registrationData.password,
      confirmPassword: registrationData.confirmPassword,
    });

    setValidationMessages(newValidationMessages);

    if (Object.keys(newValidationMessages).length === 0) {
      try {
        const response = await axios.post(
          `${BASE_API_URL}/auth/sendcode/registration`,
          {
            user_email: registrationData.email,
            user_lang: userLanguage,
            user_name: registrationData.name,
          }
        );

        setCurrentAuthScreen("registration_code");
      } catch (error) {
        const status = error.response?.status;

        if (status === 409) {
          setValidationMessages((prev) => ({
            ...prev,
            email: "Пользователь с такой почтой уже зарегистрирован",
          }));
        } else {
          console.error(
            "Registration failed:",
            error.response?.data?.message || error.message
          );
          setValidationMessages((prev) => ({
            ...prev,
            server: "Ошибка регистрации. Попробуйте позже",
          }));
        }
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  return (
    <div>
      <AuthInputsContainer>
        {validationMessages.server && (
          <ErrorMessage>{validationMessages.server}</ErrorMessage>
        )}

        <AuthTextInput
          placeholder={"Почта"}
          value={registrationData.email}
          onChange={(e) => handleRegistrationData("email", e.target.value)}
          type={"email"}
          validationMessage={validationMessages.email}
        />

        <AuthInputsSection>
          <AuthTextInput
            placeholder={"Имя"}
            value={registrationData.name}
            onChange={(e) => handleRegistrationData("name", e.target.value)}
            type={"name"}
            validationMessage={validationMessages.name}
          />
          <AuthTextInput
            placeholder={"Фамилия"}
            value={registrationData.secondName}
            onChange={(e) =>
              handleRegistrationData("secondName", e.target.value)
            }
            type={"text"}
            require={false}
          />
        </AuthInputsSection>

        <AuthPasswordInput
          placeholder={"Пароль"}
          value={registrationData.password}
          onChange={(e) => handleRegistrationData("password", e.target.value)}
          validationMessage={validationMessages.password}
        />
        <AuthPasswordInput
          placeholder={"Повторите пароль"}
          value={registrationData.confirmPassword}
          onChange={(e) =>
            handleRegistrationData("confirmPassword", e.target.value)
          }
          validationMessage={validationMessages.confirmPassword}
        />

        <UserLocationSelect
          inComponent="registration"
          handleUserData={handleRegistrationData}
        />
      </AuthInputsContainer>

      <AuthBottomButtonsContainer>
        <LoginButton disabled={loading} onClick={onSendCodeToMail}>
          {!loading ? (
            "Зарегистрироваться"
          ) : (
            <Loader>
              Отправка кода на почту
              <Dot>.</Dot>
              <Dot>.</Dot>
              <Dot>.</Dot>
            </Loader>
          )}
        </LoginButton>
        <OAuthGoogleButton
          buttonText={"Продолжить с Google"}
          redirectPath={redirectPath}
        />
      </AuthBottomButtonsContainer>
    </div>
  );
};

export default RegistrationPage;
