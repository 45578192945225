import React, { memo, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import axios from "../../../../../axios_config";
import DialogueComponentBranch from "./dialogue_component_branch";
import { BASE_API_URL } from "../../../../endpoints";
import { onBibleDialogueBotData } from "../../lesson_utils";
import { usePrevious } from "../../../../utils/functions/admin_page_functions";

const DialogueLessonComponentContainer = styled.div`
  min-height: 40px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const DialogueLessonComponent = ({
  componentData,
  inComponent,
  withAnimation,
  colorTheme = "LIGHT",
  courseMainColor,
  courseMainGradient,
  setLessonData = () => {},
  setTextEditorComponentHtml = () => {},
  setUserAnswersData = () => {},
  userLessonPageRef,
  isEdite = false,
}) => {
  const dialogueComponentRef = useRef(null);
  const [dialogueBotData, setDialogueBotData] = useState({});
  const [activeBranch, setActiveBranch] = useState(null);

  const prevIsEdit = usePrevious(isEdite);

  useEffect(() => {
    if (componentData.dialog_bot._id === "on_bible") {
      setDialogueBotData(onBibleDialogueBotData);
    } else if (!(prevIsEdit && !isEdite)) {
      try {
        axios
          .get(`${BASE_API_URL}/users/${componentData.dialog_bot._id}`)
          .then((response) => setDialogueBotData(response.data));
      } catch (error) {
        console.error("Ошибка при запросе:", error);
      }
    }
  }, [componentData.dialog_bot._id]);

  return (
    <DialogueLessonComponentContainer ref={dialogueComponentRef}>
      {componentData?.selectedBranches?.map((branch, index) => {
        return (
          componentData.data?.[branch] && (
            <DialogueComponentBranch
              key={branch}
              branch={branch}
              componentData={componentData}
              branchIndex={index}
              dialogueBotData={dialogueBotData}
              inComponent={inComponent}
              withAnimation={withAnimation}
              colorTheme={colorTheme}
              courseMainColor={courseMainColor}
              courseMainGradient={courseMainGradient}
              setLessonData={setLessonData}
              setUserAnswersData={setUserAnswersData}
              setTextEditorComponentHtml={setTextEditorComponentHtml}
              userLessonPageRef={userLessonPageRef}
              dialogueComponentRef={dialogueComponentRef}
              setActiveBranch={setActiveBranch}
              activeBranch={activeBranch}
            />
          )
        );
      })}
    </DialogueLessonComponentContainer>
  );
};

export default memo(DialogueLessonComponent);
