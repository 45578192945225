import React, { memo, useCallback, useEffect, useState } from "react";
import styled from "styled-components";

const BookChapterButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  color: white;
  cursor: pointer;
  user-select: none;
  border-radius: 5px;
  transition: 0.2s;
  font-size: 16px;
  line-height: 150%;

  background: ${({ isActive }) =>
    isActive
      ? "linear-gradient(180deg, #5172EA -18.38%, #7A5AEE 108.82%)"
      : "#D9D9D9"};

  ${({ isActive }) =>
    !isActive &&
    `
      &:hover {
         background: #bfbebe;
      }
    `}

  @media (max-width: 744px) {
    width: 40px;
    height: 40px;
    ${({ isActive }) => !isActive && "background: #CACACA"};
  }
`;

const ChapterNavigationItem = ({
  book,
  chapterNum,
  currentBibleParams,
  setCurrentBibleParams,
  setSelectedVerses,
}) => {
  const [selectedChapter, setSelectedChapter] = useState(false);

  useEffect(() => {
    const isSelected =
      +chapterNum === +currentBibleParams.chapterId &&
      book.book_code === currentBibleParams.bookId;
    setSelectedChapter(isSelected);
  }, [currentBibleParams]);

  const handleBibleParams = useCallback(
    (event) => {
      event.stopPropagation();
      if (chapterNum) {
        setCurrentBibleParams((prevState) => ({
          ...prevState,
          chapterId: chapterNum,
          bookId: book.book_code,
        }));
        setSelectedVerses([]);
      }
    },
    [chapterNum, book, setCurrentBibleParams]
  );

  return (
    <BookChapterButton isActive={selectedChapter} onClick={handleBibleParams}>
      {chapterNum}
    </BookChapterButton>
  );
};

export default memo(ChapterNavigationItem);
