import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { SettingsBlockFixedWrapper } from "../../../create_lesson_components/create_lesson_styled_components";
import CreateLessonSaveButton from "../../../create_lesson_components/create_lesson_save_button";
import AnswerOption from "./answer_option";
import { createComponentId } from "../../../../../../common/components/lesson/lesson_utils";

const TestSettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  min-height: 219px;
  width: 434px;
  padding: 26px 24px;
  max-height: 83vh;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 3px;
  }
`;

const TestAnswerOptions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const TestQuestionWrapper = styled.div`
  max-height: 268px;
  width: 100%;
`;

const TestQuestionInput = styled.textarea`
  resize: none;
  max-height: 268px;
  width: 100%;
  padding: 8px 15px;
  border-radius: 8px;
  background: #fff;
  font-size: 16px;
  line-height: 24px;
  color: #1e1e1e;
`;

const DEFAULT_SETTINGS_DATA_TEST = {
  type: "TEST",
  data: [
    { option_text: "", right_answer: false, user_answer: false },
    { option_text: "", right_answer: false, user_answer: false },
  ],
  question: "",
  id: "",
};

const TestSettings = ({
  settingsBlockData,
  editBlockIndex,
  setSettingsBlockData,
  setSettingsBlockMemoData,
  onClickSaveButton,
}) => {
  const [disabledSaveButton, setDisabledSaveButton] = useState(true);
  const questionRef = useRef(null);

  useEffect(() => {
    if (editBlockIndex === null) {
      const defaultTestData = {
        ...DEFAULT_SETTINGS_DATA_TEST,
        id: createComponentId(),
      };

      setSettingsBlockData(defaultTestData);
      setSettingsBlockMemoData(defaultTestData);
    }
  }, [editBlockIndex]);

  const handleQuestionInputValue = useCallback((event) => {
    setSettingsBlockData((prevState) => ({
      ...prevState,
      question: event.target.value,
    }));
  }, []);

  useEffect(() => {
    if (questionRef.current && settingsBlockData.question) {
      questionRef.current.style.height = "auto";
      questionRef.current.style.height =
        Math.min(questionRef.current.scrollHeight, 268) + "px";
    }
  }, [settingsBlockData.question]);

  useEffect(() => {
    const allOptionsHaveText = settingsBlockData.data?.every(
      (item) => !!item.option_text
    );
    const atLeastOneIsRight = settingsBlockData.data?.some(
      (item) => item.right_answer === true
    );

    if (settingsBlockData.question && allOptionsHaveText && atLeastOneIsRight) {
      setDisabledSaveButton(false);
    } else {
      setDisabledSaveButton(true);
    }
  }, [settingsBlockData]);

  return (
    <SettingsBlockFixedWrapper onMouseDown={(e) => e.stopPropagation()}>
      <TestSettingsContainer>
        <TestQuestionWrapper>
          <TestQuestionInput
            placeholder="Напишите вопрос..."
            ref={questionRef}
            rows={1}
            type="text"
            value={settingsBlockData.question}
            onChange={handleQuestionInputValue}
          />
        </TestQuestionWrapper>

        <TestAnswerOptions>
          {settingsBlockData.data?.map((option, index) => {
            return (
              <AnswerOption
                key={index + "-option"}
                option={option}
                optionIndex={index}
                setSettingsBlockData={setSettingsBlockData}
              />
            );
          })}
        </TestAnswerOptions>

        <CreateLessonSaveButton
          settingsBlockData={settingsBlockData}
          onClickSaveButton={onClickSaveButton}
          disabled={disabledSaveButton}
        />
      </TestSettingsContainer>
    </SettingsBlockFixedWrapper>
  );
};

export default memo(TestSettings);
