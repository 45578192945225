import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Toggle from "../../../../common/components/toggle";
import ModalWindowMainButtons from "./modal_window_main_buttons";

const UnbindMentorModalWindowWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 9999;
`;

const UnbindMentorModalWindowContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 390px;
  padding: 23px 19px;
  border-radius: 25px;
  background: #fff;
`;

const ToggleWrapper = styled.div`
  width: 100%;
`;

const UnbindMentorContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin: 28px 0 33px;
  color: #1e1e1e;
  font-size: 10px;
  width: 100%;
`;

const UnbindMentorInputWrapper = styled.div`
  overflow: hidden;
  border-radius: 8px;
  border: 1px solid #5172ea;
  display: inline-block;

  ${({ isEmpty }) => isEmpty && "border: 2px solid red;"}
`;

const UnbindMentorInput = styled.textarea`
  height: fit-content;
  resize: none;
  min-height: 40px;
  max-height: 80px;
  width: 100%;
  padding: 8px 15px;
  background: #fff;
  font-size: 16px;
  line-height: 24px;
  color: #1e1e1e;

  ::-webkit-scrollbar {
    width: 3px;
  }
`;

const UnbindMentorModalWindow = ({
  usersList,
  setOpen,
  setUnbindMentorCause,
  setUserRolesSettingsData,
  userRolesSettingsData,
}) => {
  const unbindMentorInputRef = useRef(null);
  const [disabledConfirmButton, setDisabledConfirmButton] = useState(false);
  const [autoChangeMentor, setAutoChangeMentor] = useState(true);
  const [emptyInput, setEmptyInput] = useState(false);
  const [causeInputValue, setCauseInputValue] = useState("");

  const handleClose = useCallback((event) => {
    event.stopPropagation();
    setCauseInputValue("");
    setOpen(false);
  }, []);

  const handleCauseForUnbindInput = useCallback((event) => {
    setCauseInputValue(event.target.value);
  }, []);

  useEffect(() => {
    if (unbindMentorInputRef.current) {
      unbindMentorInputRef.current.style.height = "auto";
      unbindMentorInputRef.current.style.height =
        unbindMentorInputRef.current.scrollHeight + "px";
    }
  }, [causeInputValue, unbindMentorInputRef]);

  useEffect(() => {
    if (causeInputValue.length < 3) {
      setDisabledConfirmButton(true);
    } else {
      setDisabledConfirmButton(false);
    }
  }, [causeInputValue]);

  const autoChangeUserMentor = useCallback(
    (currentMentorId) => {
      const newUserMentor = usersList.find(
        (user) =>
          user.user_name &&
          user.user_access.is_mentor &&
          user.user_access.mentor_role.user_disciples <
            user.user_access.mentor_role.max_disciples_amount &&
          user._id !== currentMentorId &&
          user._id !== userRolesSettingsData._id
      );

      setUserRolesSettingsData((prevState) => ({
        ...prevState,
        user_mentor: { _id: newUserMentor._id },
      }));
    },
    [usersList, userRolesSettingsData]
  );

  const onClickConfirmButton = useCallback(() => {
    if (causeInputValue.length > 2) {
      if (autoChangeMentor) {
        autoChangeUserMentor();
        setUnbindMentorCause(causeInputValue);
        setOpen(false);
      } else {
        setUserRolesSettingsData((prevState) => ({
          ...prevState,
          user_mentor: {},
        }));
        setUnbindMentorCause(causeInputValue);
        setOpen(false);
      }
    } else {
      setEmptyInput(true);
      setTimeout(() => setEmptyInput(false), 1500);
    }
  }, [causeInputValue, autoChangeMentor]);

  const handleSelectToggle = useCallback(() => {
    setAutoChangeMentor((prevState) => !prevState);
  }, []);

  return (
    <UnbindMentorModalWindowWrapper
      onClick={handleClose}
      onMouseDown={(e) => e.stopPropagation()}
    >
      <UnbindMentorModalWindowContainer
        onClick={(e) => e.stopPropagation()}
        onMouseDown={(e) => e.stopPropagation()}
      >
        <ToggleWrapper>
          <Toggle
            title={"Переназначить (автоматически)"}
            selected={autoChangeMentor}
            handleSelectToggle={handleSelectToggle}
          />
        </ToggleWrapper>

        <UnbindMentorContainer>
          Опишите подробнее причину открепления*
          <UnbindMentorInputWrapper isEmpty={emptyInput}>
            <UnbindMentorInput
              ref={unbindMentorInputRef}
              placeholder="Причина открепления"
              type="text"
              rows={1}
              value={causeInputValue}
              onChange={handleCauseForUnbindInput}
            />
          </UnbindMentorInputWrapper>
        </UnbindMentorContainer>

        <ModalWindowMainButtons
          handleClose={handleClose}
          onClickConfirmButton={onClickConfirmButton}
          isDisableConfirmButton={disabledConfirmButton}
        />
      </UnbindMentorModalWindowContainer>
    </UnbindMentorModalWindowWrapper>
  );
};

export default memo(UnbindMentorModalWindow);
