import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import axios from "../../../axios_config";
import VerticalLine from "../../../common/assets/icons/vertical_line";
import { APPLICATION_ROUTES } from "../../../common/utils/routes";
import { BASE_API_URL } from "../../../common/endpoints";

const LessonIsFinishedModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 9999;
`;

const LessonIsFinishedModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 475px;
  padding: 36px 40px 40px;
  border-radius: 25px;
  background: #fff;
  user-select: none;
  text-align: center;
  font-family: Inter;

  @media (max-width: 500px) {
    width: 95%;
    border-radius: 15px;
    padding: 26px 30px 30px;
  }

  h5 {
    color: #000;
    font-size: 28px;
    line-height: 130%;
    font-weight: 600;

    @media (max-width: 470px) {
      font-size: 24px;
    }

    @media (max-width: 415px) {
      font-size: 22px;
    }
  }

  h6 {
    width: 85%;
    color: #000;
    font-size: 18px;
    line-height: 130%;
    font-weight: 600;

    @media (max-width: 470px) {
      font-size: 16px;
    }

    @media (max-width: 415px) {
      font-size: 14px;
    }
  }
`;

const ModalButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #5172ea;
  cursor: pointer;
`;

const ButtonStyles = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 16px;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: 0.24px;
  transition: 0.2s;
  user-select: none;

  &:hover {
    font-size: 17px;
    color: #5172ea;
    transition: 0.2s;
  }
`;

const RetakeLessonButton = styled(ButtonStyles)`
  color: rgba(81, 114, 234, 0.4);
  width: 49%;
`;

const ReviewLessonButton = styled(ButtonStyles)`
  width: 49%;
  color: ${({ isHoverRetakeButton }) =>
    isHoverRetakeButton ? "rgba(81, 114, 234, 0.4)" : "#5172ea"};
`;

const VerticalLineStyled = styled(VerticalLine)`
  height: 22px;
`;

const LessonIsFinishedModalWindow = ({
  lessonIsFinishedModalData,
  setLessonIsFinishedModalData,
}) => {
  const navigate = useNavigate();
  const [isHoverRetakeButton, setIsHoverRetakeButton] = useState(false);

  const { lessonId, lessonNum, userStudyId } = lessonIsFinishedModalData;

  const toggleHoverOnRestButton = () => {
    setIsHoverRetakeButton((prevState) => !prevState);
  };

  const onClickLessonReview = (e) => {
    e.stopPropagation();
    navigate(`${APPLICATION_ROUTES.USER_LESSON}/${lessonId}?n=${lessonNum}`);
  };

  const onClickLessonRetake = async (e) => {
    e.stopPropagation();

    const newUserAnswersData = {
      study_answers: [],
    };

    try {
      await axios.patch(
        `${BASE_API_URL}/userstudy/${userStudyId}`,
        newUserAnswersData
      );

      localStorage.removeItem(lessonId);
      navigate(`${APPLICATION_ROUTES.USER_LESSON}/${lessonId}?n=${lessonNum}`);
    } catch (error) {
      console.error("Ошибка создания перепрохождения урока:", error);
    }
  };

  const handleClose = (e) => {
    e.stopPropagation();
    setLessonIsFinishedModalData(null);
  };

  return (
    <LessonIsFinishedModalWrapper
      onMouseDown={(e) => e.stopPropagation()}
      onClick={handleClose}
    >
      <LessonIsFinishedModalContainer
        onMouseDown={(e) => e.stopPropagation()}
        onClick={(e) => e.stopPropagation()}
      >
        <h5>Вы уже прошли этот урок!</h5>

        <h6>Выберите дальнейшее действие с этим уроком</h6>

        <ModalButtonsContainer>
          <ReviewLessonButton
            isHoverRetakeButton={isHoverRetakeButton}
            onClick={onClickLessonReview}
          >
            Просмотреть
          </ReviewLessonButton>
          <VerticalLineStyled color={"#5172EA"} />
          <RetakeLessonButton
            onMouseEnter={toggleHoverOnRestButton}
            onMouseLeave={toggleHoverOnRestButton}
            onClick={onClickLessonRetake}
          >
            Пройти заново
          </RetakeLessonButton>
        </ModalButtonsContainer>
      </LessonIsFinishedModalContainer>
    </LessonIsFinishedModalWrapper>
  );
};

export default LessonIsFinishedModalWindow;
