import React, { memo } from "react";
import styled from "styled-components";
import BibleModalWindowVerses from "./bible_modal_window_verses";
import BibleModalSearchResults from "./bible_modal_search_results";
import BibleModalNavigation from "./bible_modal_navigation";
import InsertionVariantButtons from "./insertion_variant_buttons";

const BibleModalWindowRightBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 236px;
`;

const BibleModalWindowCancelButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 21px;
  min-height: 30px;
  border-radius: 8px;
  border: 2px solid #fff;
  cursor: pointer;

  > span {
    font-size: 16px;
    line-height: 16px;
    background: linear-gradient(180deg, #5172ea -18.38%, #7a5aee 108.82%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &:hover {
    background: rgb(223 223 223);
    transition: 0.2s;
  }
`;

const BibleModalWindowDesktopContainer = styled.div`
  position: relative;
  display: flex;
  gap: 18px;
  padding: 18px;
  width: 691px;
  height: calc(100vh - 30px);
  max-height: 676px;
  border-radius: 25px;
  background: #eaeaea;
  z-index: 9999;
`;

const BibleModalWindowDesktop = ({
  activeScreenDesktop,
  currentBibleParams,
  currentTranslation,
  selectedVerses,
  setSelectedVerses,
  bibleFontSettings,
  inComponent,
  searchIsLoading,
  bibleModalSearchResults,
  bibleModalSearchInput,
  bibleModalSearchPlace,
  bibleTranslations,
  currentTranslationData,
  setCurrentTranslationData,
  setBibleModalSearchResults,
  setBibleModalSearchInput,
  setActiveScreenDesktop,
  setCurrentBibleParams,
  setBibleTranslations,
  setSearchIsLoading,
  setBibleModalSearchPlace,
  setEditedVersesData,
  setChatInputValue,
  handleAddBibleVerses,
  handleCloseBibleModalWindow,
}) => {
  const stopPropagationOnClick = (e) => e.stopPropagation();

  return (
    <BibleModalWindowDesktopContainer
      onClick={stopPropagationOnClick}
      onMouseDown={stopPropagationOnClick}
    >
      {activeScreenDesktop === "bible_modal_chapter" && (
        <BibleModalWindowVerses
          currentBibleParams={currentBibleParams}
          currentTranslation={currentTranslation}
          currentTranslationData={currentTranslationData}
          bibleFontSettings={bibleFontSettings}
          selectedVerses={selectedVerses}
          setSelectedVerses={setSelectedVerses}
          setCurrentBibleParams={setCurrentBibleParams}
          inComponent={inComponent}
        />
      )}
      {activeScreenDesktop === "bible_modal_search" && (
        <BibleModalSearchResults
          searchIsLoading={searchIsLoading}
          bibleModalSearchResults={bibleModalSearchResults}
          currentTranslation={currentTranslation}
          setBibleModalSearchResults={setBibleModalSearchResults}
          setBibleModalSearchInput={setBibleModalSearchInput}
          setActiveScreenDesktop={setActiveScreenDesktop}
          setCurrentBibleParams={setCurrentBibleParams}
          setSelectedVerses={setSelectedVerses}
        />
      )}
      <BibleModalWindowRightBlock>
        <BibleModalNavigation
          bibleTranslations={bibleTranslations}
          currentTranslation={currentTranslation}
          setBibleTranslations={setBibleTranslations}
          currentBibleParams={currentBibleParams}
          setCurrentBibleParams={setCurrentBibleParams}
          setSelectedVerses={setSelectedVerses}
          bibleModalSearchPlace={bibleModalSearchPlace}
          bibleModalSearchInput={bibleModalSearchInput}
          setBibleModalSearchInput={setBibleModalSearchInput}
          setSearchIsLoading={setSearchIsLoading}
          activeScreenDesktop={activeScreenDesktop}
          setActiveScreenDesktop={setActiveScreenDesktop}
          setBibleModalSearchResults={setBibleModalSearchResults}
          setBibleModalSearchPlace={setBibleModalSearchPlace}
          currentTranslationData={currentTranslationData}
          setCurrentTranslationData={setCurrentTranslationData}
        />

        <BibleModalWindowCancelButton onClick={handleCloseBibleModalWindow}>
          <span>Отмена</span>
        </BibleModalWindowCancelButton>
      </BibleModalWindowRightBlock>
      {activeScreenDesktop === "bible_modal_chapter" &&
        inComponent !== "simple_modal" && (
          <InsertionVariantButtons
            selectedVerses={selectedVerses}
            setSelectedVerses={setSelectedVerses}
            currentTranslation={currentTranslation}
            currentBibleParams={currentBibleParams}
            handleAddBibleVerses={handleAddBibleVerses}
            handleCloseBibleModalWindow={handleCloseBibleModalWindow}
            setChatInputValue={setChatInputValue}
            setEditedVersesData={setEditedVersesData}
            inComponent={inComponent}
          />
        )}
    </BibleModalWindowDesktopContainer>
  );
};

export default memo(BibleModalWindowDesktop);
