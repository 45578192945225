import React from "react";

const HideArrowIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
    >
      <g clipPath="url(#clip0_10174_9707)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.205791 2.29658C0.484737 2.01182 0.936998 2.01182 1.21594 2.29658L4.99658 6.15598L8.77722 2.29658C9.05617 2.01182 9.50843 2.01182 9.78737 2.29658C10.0663 2.58133 10.0663 3.04302 9.78737 3.32777L5.50166 7.70277C5.22271 7.98753 4.77045 7.98753 4.49151 7.70277L0.205791 3.32777C-0.0731545 3.04302 -0.0731545 2.58133 0.205791 2.29658Z"
          fill="#5172EA"
        />
      </g>
      <defs>
        <clipPath id="clip0_10174_9707">
          <rect width="10" height="10" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default HideArrowIcon;
