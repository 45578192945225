import React, { memo, useCallback, useEffect, useState } from "react";
import UserLocationSelect from "../../common/components/user_location_select";
import styled from "styled-components";
import InputControlButtons from "./input_control_buttons";

const UserProfileGeneralContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
  max-width: 432px;
  margin: 0 auto;
  padding-top: 54px;
`;

const UserNameInputs = styled.div`
  display: flex;
  gap: 8px;
`;

const TextInput = styled.input`
  height: 40px;
  width: 100%;
  padding: 0 68px 0 15px;
  border-radius: 8px;
  color: #1e1e1e;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px; /* 150% */
  transition: 0.2s;

  &:focus,
  :hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
`;

const InputWrapper = styled.div`
  position: relative;
  max-width: 100%;
`;

const GeneralInfoItem = styled.div`
  h6 {
    color: #828282;
    font-size: 10px;
    line-height: 150%;
    letter-spacing: -0.1px;
  }
`;

const Button = styled.div`
  padding: 8px 20px;
  border-radius: 10px;
  background: lightblue;
  width: max-content;
  cursor: pointer;
`;

const UserProfileGeneral = ({ userData }) => {
  const [profileGeneralData, setProfileGeneralData] = useState({});
  const [profileGeneralDataMemo, setProfileGeneralDataMemo] = useState({});

  useEffect(() => {
    if (!userData.user_name) return;

    const currentUserData = {
      name: userData.user_name,
      secondName: userData.user_second_name,
      country: userData.user_country,
      city: userData.user_city,
      phone: userData.user_phone,
      countryId: userData.user_place_details?.country?.place_id || null,
      cityId: userData.user_place_details?.city?.place_id || null,
    };

    setProfileGeneralData(currentUserData);
    setProfileGeneralDataMemo(currentUserData);
  }, [userData]);

  const handleUserGeneralData = useCallback((key, value) => {
    setProfileGeneralData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  }, []);

  return (
    <UserProfileGeneralContainer>
      <UserNameInputs>
        <InputWrapper>
          <TextInput
            placeholder={"Имя"}
            value={profileGeneralData?.name || ""}
            onChange={(e) => handleUserGeneralData("name", e.target.value)}
            type={"name"}
          />
          <InputControlButtons />
        </InputWrapper>
        <InputWrapper>
          <TextInput
            placeholder={"Фамилия"}
            value={profileGeneralData?.secondName || ""}
            onChange={(e) =>
              handleUserGeneralData("secondName", e.target.value)
            }
            type={"secondName"}
          />
          <InputControlButtons />
        </InputWrapper>
      </UserNameInputs>

      <GeneralInfoItem>
        <h6>Местоположение</h6>
        <InputWrapper>
          <UserLocationSelect
            userData={userData}
            handleUserData={handleUserGeneralData}
            inComponent="user_profile"
          />
        </InputWrapper>
      </GeneralInfoItem>

      <GeneralInfoItem>
        <h6>Контакты</h6>
        <InputWrapper>
          <TextInput
            placeholder={"Контакты"}
            value={profileGeneralData?.phone || ""}
            onChange={(e) => handleUserGeneralData("phone", e.target.value)}
            type={"phone"}
          />
          <InputControlButtons />
        </InputWrapper>
      </GeneralInfoItem>

      <Button>Отмена</Button>
      <Button>Сохранить</Button>
    </UserProfileGeneralContainer>
  );
};

export default memo(UserProfileGeneral);
