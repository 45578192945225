import React, { memo, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import axios from "../../../../axios_config";
import { COURSE_CERTIFICATE_IMAGES } from "../../../../common/utils/enums";
import { BASE_API_URL } from "../../../../common/endpoints";
import CertificateHeaderButtons from "./certificate_header_buttons";
import CertificateContent from "./certificate_content";
import { formatDate } from "../../../../common/utils/functions/time_functions";

const CertificateModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  padding: 40px 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  overflow: auto;
  z-index: 9999;
`;

const CertificateModalContainer = styled.div`
  background: #fff;
  height: max-content;
  border-radius: 25px;
  box-shadow: 0px 2.737px 30.1px -8px rgba(0, 0, 0, 0.47);
  overflow: hidden;

  @media (max-width: 625px) {
    border-radius: 15px;
  }
`;

const CertificatePreviewWrapper = styled.div`
  background: #fff;
  width: 607px;
  min-height: 764px;

  @media (max-width: 625px) {
    width: 350px;
    min-height: max-content;
  }
`;

const CertificateFooterContainer = styled.div`
  width: 100%;
  height: 206px;
  background-image: url(${({ backgroundImage }) => backgroundImage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @media (max-width: 625px) {
    width: 350px;
    height: 121px;
  }
`;

const CourseCertificateModal = ({
  courseData,
  userData,
  setCourseCertificateModal,
  setCourseFeedbackModal,
}) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const [error, setError] = useState("");

  const courseEndDate = userData.user_activity?.find(
    (course) => course.course_id === courseData._id
  )?.course_completed_at;

  const certificateDate = formatDate(courseEndDate || new Date().toISOString());

  const courseStudentName = userData.user_name
    ? `${userData.user_name} ${userData.user_second_name}`
    : "";

  const certificateFooterImage =
    COURSE_CERTIFICATE_IMAGES?.[
      courseData.course_certificate?.certificate_image
    ]?.largeImage;

  const handleClose = useCallback((event) => {
    event.stopPropagation();
    setCourseCertificateModal(false);
    setCourseFeedbackModal(true);
  }, []);

  const onClickDownloadCertificate = useCallback(
    async (event) => {
      event.stopPropagation();
      setIsDownloading(true);
      setError(null);

      try {
        const certificateData = {
          user_name: userData.user_name,
          user_second_name: userData.user_second_name,
          course_name: courseData.course_name,
          certificate_description:
            courseData.course_certificate?.certificate_description,
          certificate_type: courseData.course_certificate?.certificate_image,
          course_completed_at: certificateDate,
        };

        const response = await axios.post(
          `${BASE_API_URL}/courses/certificate`,
          certificateData,
          { responseType: "blob" }
        );

        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/pdf" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "certificate.pdf");
        document.body.appendChild(link);
        link.click();

        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(url);
      } catch (error) {
        setError("Ошибка при получении сертификата");
      } finally {
        setIsDownloading(false);
      }
    },
    [userData, courseData, certificateDate]
  );

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => setError(null), 4000);

      return () => clearTimeout(timer);
    }
  }, [error]);

  return (
    <CertificateModalWrapper
      onClick={handleClose}
      onMouseDown={(e) => e.stopPropagation()}
    >
      <CertificateModalContainer onClick={(e) => e.stopPropagation()}>
        <CertificateHeaderButtons
          isDownloading={isDownloading}
          onClickDownloadCertificate={onClickDownloadCertificate}
          error={error}
          onClose={handleClose}
        />

        <CertificatePreviewWrapper>
          <CertificateContent
            courseStudentName={courseStudentName}
            courseTitle={courseData.course_name || ""}
            certificateDate={certificateDate}
            certificateDescription={
              courseData.course_certificate?.certificate_description
            }
          />

          <CertificateFooterContainer
            backgroundImage={certificateFooterImage}
          />
        </CertificatePreviewWrapper>
      </CertificateModalContainer>
    </CertificateModalWrapper>
  );
};

export default memo(CourseCertificateModal);
