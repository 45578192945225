import React, { memo } from "react";
import styled from "styled-components";
import VideoComponentPlayer from "./video_component_player";
import { LESSON_COLORS_THEME } from "../../../../utils/enums";

const VideoComponentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 14px;
  width: 100%;
  border-radius: 15px;
  background: ${({ background }) => background};
  padding: ${({ videoWithTitle }) => (videoWithTitle ? "22px 23px 19px" : 0)};
`;

const VideoComponentTitle = styled.div`
  width: 100%;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  word-wrap: break-word;
  overflow-wrap: break-word;
`;

const VideoLessonComponent = ({
  componentData,
  colorTheme = "LIGHT",
  inComponent = "",
}) => {
  const videoWithTitle = componentData.with_title;
  const backgroundColor =
    LESSON_COLORS_THEME[colorTheme]?.GRADIENTS?.[componentData.background];

  return (
    <VideoComponentContainer
      background={videoWithTitle ? backgroundColor : "transparent"}
      videoWithTitle={videoWithTitle}
    >
      <VideoComponentPlayer
        componentData={componentData}
        backgroundColor={backgroundColor}
        inComponent={inComponent}
      />

      {videoWithTitle && (
        <VideoComponentTitle>{componentData.video_title}</VideoComponentTitle>
      )}
    </VideoComponentContainer>
  );
};

export default memo(VideoLessonComponent);
