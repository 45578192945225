import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";
import BiblePageSidebar from "./bible_page_sidebar";
import { ToastNotification } from "../../common/components/toast_notification";
import { BIBLE_PAGE_DEFAULT_URL } from "./bible_page_data";
import { BiblePageProvider, useBiblePageContext } from "./bible_page_context";
import { BibleVersesHeightsProvider } from "./bible_page_desktop/bible_chapter_desktop/bible_verses_heights_context";
import BiblePageDesktop from "./bible_page_desktop";
import useWindowBreakpoint from "../../common/hooks/use_window_breakpoint";
import BiblePageMobile from "./bible_page_mobile";
import { BASE_API_URL } from "../../common/endpoints";
import { createPortal } from "react-dom";
import { fetchAndSortBibleBooks } from "../../common/utils/functions/bible_page_functions";

const BiblePageWrapper = styled.div`
  position: relative;
  height: 100%;
`;

const BiblePageHOC = (WrappedComponent) => (props) => {
  return (
    <BiblePageProvider>
      <BibleVersesHeightsProvider>
        <WrappedComponent {...props} />
      </BibleVersesHeightsProvider>
    </BiblePageProvider>
  );
};

const BiblePage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const bibleId = searchParams.get("bibleId");
  const bibleId2 = searchParams.get("2bibleId");

  const { setCurrentTranslationData } = useBiblePageContext();

  const [bibleTranslations, setBibleTranslations] = useState(null);
  const [currentTranslationFirst, setCurrentTranslationFirst] = useState(null);
  const [currentTranslationSecond, setCurrentTranslationSecond] =
    useState(null);
  const [activeScreenDesktop, setActiveScreenDesktop] =
    useState("bible_chapter"); // bible_chapter, bible_search, bible_compare
  const [selectedVerses, setSelectedVerses] = useState({});
  const [bibleSearchInput, setBibleSearchInput] = useState("");
  const [bibleSearchPlace, setBibleSearchPlace] = useState(""); // "" (whole bible), ot, nt
  const [bibleSearchResults, setBibleSearchResults] = useState({});
  const [selectedSearchVerse, setSelectedSearchVerse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const isMobile = useWindowBreakpoint(744);

  const bibleSidebarIsOpen = !!selectedVerses.bible;

  useEffect(() => {
    const favoriteTranslations = JSON.parse(
      localStorage.getItem("favoriteTranslations") || "[]"
    );

    if (favoriteTranslations.length && bibleTranslations?.length) {
      setBibleTranslations((prevTranslations) =>
        prevTranslations.map((translation) => ({
          ...translation,
          isFavorite: favoriteTranslations.includes(translation.bible_code),
        }))
      );
    }
  }, [bibleTranslations?.length]);

  const lastUrl = localStorage.getItem("lastBibleUrl");

  useEffect(() => {
    if (lastUrl && !bibleId) {
      navigate(lastUrl);
      setActiveScreenDesktop("bible_chapter");
      setBibleSearchResults({});
      setBibleSearchInput("");
    }
  }, [lastUrl, bibleId]);

  useEffect(() => {
    if (bibleId) {
      localStorage.setItem("lastBibleUrl", location.pathname + location.search);
    }
  }, [location.pathname, location.search]);

  useEffect(() => {
    if (!bibleId && !lastUrl) {
      navigate(BIBLE_PAGE_DEFAULT_URL);
      setActiveScreenDesktop("bible_chapter");
      setBibleSearchResults({});
      setBibleSearchInput("");
    }
  }, [bibleId]);

  useEffect(() => {
    if (bibleId2) {
      const secondTranslate = bibleTranslations?.filter((translation) => {
        return translation.bible_code === bibleId2;
      })[0];
      setCurrentTranslationSecond(secondTranslate);
    } else {
      setCurrentTranslationSecond(null);
    }
  }, [bibleTranslations, bibleId2]);

  useEffect(() => {
    const fetchBibles = async () => {
      try {
        const { data } = await axios.get(`${BASE_API_URL}/bible/transl`);

        setBibleTranslations(
          data.bibles.map((transl) => ({
            ...transl,
            isFavorite: false,
          }))
        );
      } catch (error) {
        console.error("Ошибка при загрузке переводов Библии:", error);
        setBibleTranslations([]);
      }
    };

    fetchBibles();
  }, []);

  useEffect(() => {
    const loadTranslationData = async () => {
      if (currentTranslationFirst) {
        try {
          const sortedBooks = await fetchAndSortBibleBooks(
            currentTranslationFirst.bible_code,
            currentTranslationFirst.bible_locale
          );

          setCurrentTranslationData(sortedBooks);
        } catch (error) {
          console.error("Ошибка загрузки книг Библии", error);
        }
      }
    };

    loadTranslationData();
  }, [currentTranslationFirst]);

  return (
    <BiblePageWrapper bibleSidebarIsOpen={bibleSidebarIsOpen}>
      {(activeScreenDesktop === "bible_chapter" ||
        activeScreenDesktop === "bible_search") &&
        createPortal(
          <BiblePageSidebar
            activeScreenDesktop={activeScreenDesktop}
            selectedVerses={selectedVerses}
            selectedSearchVerse={selectedSearchVerse}
            setActiveScreenDesktop={setActiveScreenDesktop}
            setSelectedVerses={setSelectedVerses}
            currentBibleLocale={currentTranslationFirst?.bible_locale}
          />,
          document.body
        )}

      {!isMobile ? (
        <BiblePageDesktop
          activeScreenDesktop={activeScreenDesktop}
          bibleTranslations={bibleTranslations}
          bibleSearchPlace={bibleSearchPlace}
          bibleSearchInput={bibleSearchInput}
          bibleSearchResults={bibleSearchResults}
          selectedVerses={selectedVerses}
          setSelectedVerses={setSelectedVerses}
          currentTranslationFirst={currentTranslationFirst}
          currentTranslationSecond={currentTranslationSecond}
          selectedSearchVerse={selectedSearchVerse}
          setBibleSearchResults={setBibleSearchResults}
          setBibleSearchInput={setBibleSearchInput}
          setSelectedSearchVerse={setSelectedSearchVerse}
          setBibleSearchPlace={setBibleSearchPlace}
          setActiveScreenDesktop={setActiveScreenDesktop}
          setBibleTranslations={setBibleTranslations}
          setCurrentTranslationFirst={setCurrentTranslationFirst}
          setCurrentTranslationSecond={setCurrentTranslationSecond}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      ) : (
        <BiblePageMobile
          selectedSearchVerse={selectedSearchVerse}
          bibleSearchPlace={bibleSearchPlace}
          bibleSearchInput={bibleSearchInput}
          bibleSearchResults={bibleSearchResults}
          selectedVerses={selectedVerses}
          setSelectedVerses={setSelectedVerses}
          currentTranslationFirst={currentTranslationFirst}
          currentTranslationSecond={currentTranslationSecond}
          bibleTranslations={bibleTranslations}
          setCurrentTranslationFirst={setCurrentTranslationFirst}
          setCurrentTranslationSecond={setCurrentTranslationSecond}
          setBibleTranslations={setBibleTranslations}
          setBibleSearchResults={setBibleSearchResults}
          setBibleSearchInput={setBibleSearchInput}
          setSelectedSearchVerse={setSelectedSearchVerse}
          setBibleSearchPlace={setBibleSearchPlace}
        />
      )}
      <ToastNotification />
    </BiblePageWrapper>
  );
};

export default BiblePageHOC(BiblePage);
