import React, { memo, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { useAppContext } from "../../../../app_context.js";
import BibleModalWindowDesktop from "./bible_modal_window_desktop/index.js";
import useWindowBreakpoint from "../../../hooks/use_window_breakpoint.js";
import BibleModalWindowMobile from "./bible_modal_window_mobile/index.js";
import { fetchAndSortBibleBooks } from "../../../utils/functions/bible_page_functions.js";

const DEFAULT_BIBLE_PARAMS = {
  bibleId: "rst",
  bookId: "Gen",
  chapterId: 1,
};

const BibleModalWindowWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 9999;
`;

const BibleModalWindow = ({
  editedVersesData,
  setEditedVersesData,
  setOpenBibleModalWindow,
  setChatInputValue,
  handleAddBibleVerses = () => {},
  inComponent,
}) => {
  const { bibleFontSettings } = useAppContext();
  const [bibleTranslations, setBibleTranslations] = useState([]);
  const [selectedVerses, setSelectedVerses] = useState([]);
  const [bibleModalSearchPlace, setBibleModalSearchPlace] = useState("");
  const [bibleModalSearchInput, setBibleModalSearchInput] = useState("");
  const [searchIsLoading, setSearchIsLoading] = useState(false);
  const [bibleModalSearchResults, setBibleModalSearchResults] = useState({});
  const [currentTranslation, setCurrentTranslation] = useState(null);
  const [currentTranslationData, setCurrentTranslationData] = useState([]);
  const [activeScreenDesktop, setActiveScreenDesktop] = useState(
    "bible_modal_chapter"
  ); // bible_modal_chapter, bible_modal_search
  const [activeScreenMobile, setActiveScreenMobile] = useState(
    "bible_modal_chapter"
  ); // bible_modal_chapter, bible_modal_search, bible_modal_navigation, bible_modal_translation
  const [currentBibleParams, setCurrentBibleParams] =
    useState(DEFAULT_BIBLE_PARAMS);

  const isMobile = useWindowBreakpoint(744);

  useEffect(() => {
    if (Object.values(editedVersesData).length && bibleTranslations) {
      setCurrentBibleParams(editedVersesData.versesParams);
      setSelectedVerses(editedVersesData.versesData);
    }
  }, [editedVersesData]);

  useEffect(() => {
    if (isMobile) {
      setActiveScreenMobile("bible_modal_chapter");
    }
  }, [currentBibleParams]);

  useEffect(() => {
    if (currentBibleParams.bibleId && bibleTranslations.length) {
      const translation = bibleTranslations.find(
        (transl) => transl.bible_code === currentBibleParams.bibleId
      );
      setCurrentTranslation(translation);
    }
  }, [currentBibleParams, bibleTranslations]);

  useEffect(() => {
    const loadTranslationData = async () => {
      if (currentTranslation) {
        try {
          const sortedBooks = await fetchAndSortBibleBooks(
            currentTranslation.bible_code,
            currentTranslation.bible_locale
          );

          setCurrentTranslationData(sortedBooks);
        } catch (error) {
          console.error("Ошибка загрузки книг Библии", error);
        }
      }
    };

    loadTranslationData();
  }, [currentTranslation]);

  const handleCloseBibleModalWindow = useCallback((e) => {
    e.stopPropagation();
    setOpenBibleModalWindow(false);
    setSelectedVerses([]);
    setActiveScreenDesktop("bible_modal_chapter");
    setBibleModalSearchResults({});
    setEditedVersesData({});
    setCurrentBibleParams(DEFAULT_BIBLE_PARAMS);
  }, []);

  return (
    <BibleModalWindowWrapper
      onMouseDown={(e) => e.stopPropagation()}
      onClick={handleCloseBibleModalWindow}
    >
      {!isMobile ? (
        <BibleModalWindowDesktop
          activeScreenDesktop={activeScreenDesktop}
          currentBibleParams={currentBibleParams}
          currentTranslation={currentTranslation}
          selectedVerses={selectedVerses}
          currentTranslationData={currentTranslationData}
          setSelectedVerses={setSelectedVerses}
          bibleFontSettings={bibleFontSettings}
          inComponent={inComponent}
          searchIsLoading={searchIsLoading}
          bibleModalSearchResults={bibleModalSearchResults}
          bibleModalSearchInput={bibleModalSearchInput}
          bibleModalSearchPlace={bibleModalSearchPlace}
          bibleTranslations={bibleTranslations}
          setCurrentTranslationData={setCurrentTranslationData}
          setBibleModalSearchResults={setBibleModalSearchResults}
          setBibleModalSearchInput={setBibleModalSearchInput}
          setActiveScreenDesktop={setActiveScreenDesktop}
          setCurrentBibleParams={setCurrentBibleParams}
          setBibleTranslations={setBibleTranslations}
          setSearchIsLoading={setSearchIsLoading}
          setBibleModalSearchPlace={setBibleModalSearchPlace}
          setEditedVersesData={setEditedVersesData}
          setChatInputValue={setChatInputValue}
          handleAddBibleVerses={handleAddBibleVerses}
          handleCloseBibleModalWindow={handleCloseBibleModalWindow}
        />
      ) : (
        <BibleModalWindowMobile
          activeScreenMobile={activeScreenMobile}
          setActiveScreenMobile={setActiveScreenMobile}
          bibleFontSettings={bibleFontSettings}
          currentBibleParams={currentBibleParams}
          inComponent={inComponent}
          selectedVerses={selectedVerses}
          setSelectedVerses={setSelectedVerses}
          bibleTranslations={bibleTranslations}
          currentTranslation={currentTranslation}
          currentTranslationData={currentTranslationData}
          setCurrentTranslationData={setCurrentTranslationData}
          setBibleTranslations={setBibleTranslations}
          setCurrentBibleParams={setCurrentBibleParams}
          setCurrentTranslation={setCurrentTranslation}
          bibleModalSearchPlace={bibleModalSearchPlace}
          setBibleModalSearchPlace={setBibleModalSearchPlace}
          bibleModalSearchInput={bibleModalSearchInput}
          setBibleModalSearchInput={setBibleModalSearchInput}
          bibleModalSearchResults={bibleModalSearchResults}
          setBibleModalSearchResults={setBibleModalSearchResults}
          searchIsLoading={searchIsLoading}
          setSearchIsLoading={setSearchIsLoading}
          handleCloseBibleModalWindow={handleCloseBibleModalWindow}
          handleAddBibleVerses={handleAddBibleVerses}
          setChatInputValue={setChatInputValue}
          setEditedVersesData={setEditedVersesData}
        />
      )}
    </BibleModalWindowWrapper>
  );
};

export default memo(BibleModalWindow);
