import React, { memo, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import UserActivationPassword from "./user_activation_password";
import axios from "axios";
import { BASE_API_URL } from "../../../../common/endpoints";
import { useUserDataContext } from "../../../../user_data_context";
import UserLocationSelect from "../../../../common/components/user_location_select";

const UserActivationModalWindowWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 9999;
`;

const UserActivationModalWindowContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 480px;
  gap: 21px;
  padding: 26px 24px;
  border-radius: 25px;
  background: #eaeaea;

  @media (max-width: 440px) {
    width: 95%;
    border-radius: 15px;
  }
`;

const UserActivationModalWindowTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

const TitleTextContainer = styled.div`
  display: flex;
  align-items: center;
  height: max-content;
  font-size: 16px;
  font-weight: 600;
  line-height: 120%;
  user-select: none;

  & > p {
    text-align: center;
    background: linear-gradient(180deg, #5172ea -18.38%, #7a5aee 108.82%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

const EmojiContainer = styled.span`
  font-size: 20px;
  line-height: 100%;
`;

const UserNameContainer = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 100%;
`;

const UserActivationModalWindowInputs = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 21px;
  max-width: 100%;
`;

export const UserActivationModalWindowInput = styled.div`
  position: relative;
`;

export const RequireIcon = styled.div`
  position: absolute;
  top: -8px;
  right: -1px;
  color: #5172ea;
  font-weight: 600;
  user-select: none;
`;

export const InputStyles = styled.input`
  width: 100%;
  height: 40px;
  padding: 0 15px;
  border-radius: 10px;
  background: #fff;
  font-size: 16px;
  line-height: 24px;
  color: #1e1e1e;

  &:focus {
    box-shadow: 3.65px 3.65px 6.388px 0px rgba(0, 0, 0, 0.14);
    transition: 0.2s;
  }
`;

export const ErrorMessage = styled.div`
  position: absolute;
  top: -15px;
  left: 0;
  font-size: 10px;
  letter-spacing: 0.24px;
  color: red;
`;

const FinishRegistrationButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 52px;
  max-height: 52px;
  border-radius: 16px;
  background: linear-gradient(180deg, #5172ea -18.38%, #7a5aee 108.82%);
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.24px;
  cursor: pointer;
  user-select: none;
  ${({ enableButton }) =>
    !enableButton &&
    `
      cursor: default;
      opacity: 0.5;
  `}

  &:hover {
    box-shadow: 3.65px 3.65px 6.388px 0px rgba(0, 0, 0, 0.14);
    transition: 0.2s;
  }
`;

const passwordRegex = /^(?=.*\d)[A-Za-z\d@$!%*#?&-_+=/\\.,():;~]{8,}$/;

const DEFAULT_USER_DATA = {
  name: "",
  secondName: "",
  password: "",
  city: "",
  country: "",
  countryId: null,
  cityId: null,
};

const UserActivationModalWindow = ({
  setOpen,
  setOpenAfterActivationModal,
  userActivationToken,
  setInviteUserName,
}) => {
  const { fetchUserData } = useUserDataContext();
  const [inputsValues, setInputValues] = useState(DEFAULT_USER_DATA);
  const [confirmPasswordInput, setConfirmPasswordInput] = useState("");
  const [enableButton, setEnableButton] = useState(false);
  const [errorMessage, setErrorMessage] = useState(""); // require, incorrect_password, passwords_not_equal

  useEffect(() => {
    if (inputsValues.name) {
      setInviteUserName(inputsValues.name);
    }
  }, [inputsValues.name]);

  useEffect(() => {
    if (
      !inputsValues.name ||
      !inputsValues.password ||
      !inputsValues.country ||
      !confirmPasswordInput
    ) {
      setEnableButton(false);
    } else {
      setEnableButton(true);
    }
  }, [inputsValues, confirmPasswordInput]);

  const handleInputChange = useCallback((key, value) => {
    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [key]: value,
    }));

    if (["name", "password"].includes(key)) {
      setErrorMessage("");
    }
  }, []);

  const handleConfirmPasswordInput = useCallback((event) => {
    setConfirmPasswordInput(event.target.value);
  }, []);

  const handleErrorMessage = (message, timeout = 3000) => {
    setErrorMessage(message);
    setTimeout(() => setErrorMessage(""), timeout);
  };

  const activateUser = async (
    userData,
    activationToken,
    fetchUserDataCallback,
    setOpenCallback,
    setModalOpenCallback
  ) => {
    try {
      const response = await axios.post(
        `${BASE_API_URL}/auth/activate?activation=${activationToken}`,
        userData
      );

      if (response?.data?.access_token) {
        localStorage.setItem("access_token", response.data.access_token);
        fetchUserDataCallback();
      }

      setOpenCallback(false);
      setModalOpenCallback(true);
    } catch (error) {
      console.error("Error making POST request:", error);
    }
  };

  const onFinishRegistrationButton = useCallback(() => {
    if (!enableButton) {
      return handleErrorMessage("require");
    }

    if (!passwordRegex.test(inputsValues.password)) {
      return handleErrorMessage("incorrect_password");
    }

    if (inputsValues.password !== confirmPasswordInput) {
      return handleErrorMessage("passwords_not_equal");
    }

    const USER_DATA = {
      user_name: inputsValues.name,
      user_second_name: inputsValues.secondName,
      user_password: inputsValues.password,
      user_city: inputsValues.city,
      user_country: inputsValues.country,
      user_country_place_id: inputsValues.countryId,
      user_locality_place_id: inputsValues.cityId,
      user_type: "user",
    };

    activateUser(
      USER_DATA,
      userActivationToken,
      fetchUserData,
      setOpen,
      setOpenAfterActivationModal
    );
  }, [
    enableButton,
    inputsValues,
    confirmPasswordInput,
    passwordRegex,
    userActivationToken,
    fetchUserData,
    setOpen,
    setOpenAfterActivationModal,
  ]);

  return (
    <UserActivationModalWindowWrapper>
      <UserActivationModalWindowContainer onClick={(e) => e.stopPropagation()}>
        <UserActivationModalWindowTitle>
          <TitleTextContainer>
            <p>Приветствуем Вас на платформе OnBible!</p>
          </TitleTextContainer>
          <TitleTextContainer>
            <p>Вы почти завершили регистрацию</p>
            <EmojiContainer>&#127881;</EmojiContainer>
          </TitleTextContainer>
          <TitleTextContainer>
            <p>Осталось заполнить несколько полей</p>
          </TitleTextContainer>
        </UserActivationModalWindowTitle>

        <UserActivationModalWindowInputs>
          {errorMessage === "require" && (
            <ErrorMessage>
              Не заполненные все обязательные поля (*)
            </ErrorMessage>
          )}

          <UserNameContainer>
            <UserActivationModalWindowInput>
              <InputStyles
                type="text"
                placeholder="Имя"
                name="name"
                autoComplete="off"
                value={inputsValues.name}
                onChange={(e) => handleInputChange("name", e.target.value)}
              />
              <RequireIcon>*</RequireIcon>
            </UserActivationModalWindowInput>
            <UserActivationModalWindowInput>
              <InputStyles
                type="text"
                placeholder="Фамилия"
                name="secondName"
                autoComplete="off"
                value={inputsValues.secondName}
                onChange={(e) =>
                  handleInputChange("secondName", e.target.value)
                }
              />
            </UserActivationModalWindowInput>
          </UserNameContainer>

          <UserActivationPassword
            inputsValues={inputsValues}
            confirmPasswordInput={confirmPasswordInput}
            handleInputChange={handleInputChange}
            handleConfirmPasswordInput={handleConfirmPasswordInput}
            errorMessage={errorMessage}
          />

          <UserLocationSelect
            inComponent="activation_modal"
            handleUserData={handleInputChange}
          />
        </UserActivationModalWindowInputs>

        <FinishRegistrationButton
          enableButton={enableButton}
          onClick={onFinishRegistrationButton}
        >
          Завершить регистрацию
        </FinishRegistrationButton>
      </UserActivationModalWindowContainer>
    </UserActivationModalWindowWrapper>
  );
};

export default memo(UserActivationModalWindow);
