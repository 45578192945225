import React, { memo } from "react";
import BlackArrowDownIcon from "../../../../../../common/assets/icons/admin_page_icons/black_arrow_down_icon";
import {
  QuestionsFilterOption,
  QuestionsFilterOptionArrow,
} from "../../../question_styled_components";
import CalendarDropdown from "./calendar_dropdown";

const QuestionsFilterByDate = ({
  extendedOption,
  handleExtendOptions,
  setQuestionsFilterSettings,
}) => {
  return (
    <QuestionsFilterOption
      isExtended={extendedOption === "date"}
      onClick={(e) => handleExtendOptions(e, "date")}
    >
      Дата
      <QuestionsFilterOptionArrow isExtended={extendedOption === "date"}>
        <BlackArrowDownIcon />
      </QuestionsFilterOptionArrow>
      {extendedOption === "date" && (
        <CalendarDropdown
          setQuestionsFilterSettings={setQuestionsFilterSettings}
          handleExtendOptions={handleExtendOptions}
        />
      )}
    </QuestionsFilterOption>
  );
};

export default memo(QuestionsFilterByDate);
