import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import axios from "../../axios_config";
import { useAuthPageContext } from "./auth_page_context";
import { BASE_API_URL } from "../../common/endpoints";
import AuthTextInput from "./auth_components/auth_text_input";
import { useUserDataContext } from "../../user_data_context";
import { Dot, Loader } from "./auth_page";

const AuthCodePageContainer = styled.div`
  width: 402px;
  margin: 0 auto;
  padding-top: 85px;
  text-align: center;

  h5 {
    color: #1e1e1e;
    font-size: 14px;
    font-weight: 400;
    line-height: 185.714%;
  }

  p {
    margin-bottom: 14px;
    color: #828282;
    font-size: 12px;
    font-weight: 400;
    line-height: 26px;
  }

  @media (max-width: 410px) {
    width: 100%;
    padding: 85px 10px 0;
  }
`;

const AuthCodePageButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-top: 20px;
`;

const AuthCodePageButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  border-radius: 10px;
  background: linear-gradient(180deg, #5172ea -18.38%, #7a5aee 108.82%);
  height: 55px;
  transition: 0.2s;
  cursor: pointer;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  line-height: 140%;

  &:hover {
    box-shadow: 3.65px 3.65px 6.388px 0px rgba(0, 0, 0, 0.14);
  }
`;

const BackToAuthButton = styled(AuthCodePageButton)`
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }
`;

const SendCodeAndFormButton = styled(AuthCodePageButton)`
  ${({ isHoverOnBack }) => isHoverOnBack && "opacity: 0.5;"}
  ${({ disabled }) =>
    disabled &&
    `
      opacity: 0.25;
      cursor: default;
    
      &:hover {
         box-shadow: none;
      }
  `}
`;

const ErrorMessageContainer = styled.div`
  margin-top: 6px;
  color: #f12b2b;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
`;

const AuthCodePage = () => {
  const navigate = useNavigate();
  const { fetchUserData } = useUserDataContext();
  const {
    currentAuthScreen,
    setCurrentAuthScreen,
    redirectPath,
    verificationToken,
    registrationData,
  } = useAuthPageContext();

  const [authCode, setAuthCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isHoverOnBack, setIsHoverOnBack] = useState(false);
  const [loading, setLoading] = useState(false);

  const userFormData = {
    user_name: registrationData.name,
    user_second_name: registrationData.secondName,
    user_email: registrationData.email,
    user_password: registrationData.password,
    user_country: registrationData.country,
    user_city: registrationData.city,
    user_country_place_id: registrationData.countryId,
    user_locality_place_id: registrationData.cityId,
    user_role: "user",
    user_type: "user",
  };

  useEffect(() => {
    setErrorMessage("");
  }, [authCode]);

  const toggleHoverOnBackButton = useCallback(() => {
    setIsHoverOnBack((prevState) => !prevState);
  }, []);

  const handleCodeInputValue = useCallback((event) => {
    setAuthCode(event.target.value);
    setErrorMessage("");
  }, []);

  const onClickBackToAuthButton = useCallback((event) => {
    event.stopPropagation();
    setCurrentAuthScreen("");
  }, []);

  const onSendCodeAndRegistrFormData = async (event) => {
    event.stopPropagation();

    if (
      !authCode ||
      loading ||
      errorMessage ||
      currentAuthScreen !== "registration_code"
    )
      return;

    setLoading(true);

    try {
      const response = await axios.post(`${BASE_API_URL}/auth/register`, {
        ...userFormData,
        verification_code: authCode,
      });

      const accessToken = response.data.access_token;
      localStorage.setItem("access_token", accessToken);

      fetchUserData();

      navigate(redirectPath, { replace: true });
    } catch (error) {
      const status = error.response?.status;

      const errorMessages = {
        409: "Пользователь с такой почтой уже зарегистрирован",
        422: "Код неверный! Проверьте код и повторите попытку",
        410: "Срок действия кода истек (5 мин)",
      };

      setErrorMessage(
        status && errorMessages[status]
          ? errorMessages[status]
          : "Ошибка регистрации. Попробуйте позже"
      );

      console.error(
        "Registration failed:",
        error.response?.data?.message || error.message
      );
    } finally {
      setLoading(false);
    }
  };

  const onSendCodeToPasswordRecovery = async (event) => {
    event.stopPropagation();

    if (
      !authCode ||
      loading ||
      errorMessage ||
      currentAuthScreen !== "password_recovery_code"
    )
      return;

    if (authCode.length !== 6) {
      return setErrorMessage("Код верификации должен содержать 6 символов");
    }

    setLoading(true);

    try {
      const response = await axios.post(`${BASE_API_URL}/auth/verify-code`, {
        user_email: registrationData.email,
        verification_code: authCode,
        verification_token: verificationToken,
      });

      if (
        response.status === 201 ||
        response.data.message === "Verification code is valid"
      ) {
        setCurrentAuthScreen("new_password");
        return;
      }

      console.warn("Неожиданный успешный ответ:", response);
    } catch (error) {
      const status = error.response?.status;

      const errorMessages = {
        422: "Код неверный! Проверьте код и повторите попытку.",
        410: "Срок действия кода истек (5 мин).",
      };

      setErrorMessage(
        status in errorMessages
          ? errorMessages[status]
          : "Ошибка восстановления пароля. Попробуйте позже."
      );

      console.error(
        "Verification failed:",
        error.response?.data || error.message || error
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <AuthCodePageContainer>
      <h5>
        Введите 6-значный код{" "}
        {currentAuthScreen === "registration_code"
          ? "активации"
          : "верификации"}{" "}
        отправленный на <b>{registrationData.email}</b>
      </h5>
      <p>(Если код не пришел, убедитесь, что адрес почты верный!)</p>

      <AuthTextInput
        placeholder={"Введите код"}
        value={authCode}
        onChange={handleCodeInputValue}
        type={"text"}
      />

      {!!errorMessage && (
        <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>
      )}

      <AuthCodePageButtonsContainer>
        <BackToAuthButton
          onMouseEnter={toggleHoverOnBackButton}
          onMouseLeave={toggleHoverOnBackButton}
          onClick={onClickBackToAuthButton}
        >
          Назад
        </BackToAuthButton>
        <SendCodeAndFormButton
          isHoverOnBack={isHoverOnBack}
          disabled={!authCode || errorMessage || loading}
          onClick={
            currentAuthScreen === "registration_code"
              ? onSendCodeAndRegistrFormData
              : onSendCodeToPasswordRecovery
          }
        >
          {!loading ? (
            "Отправить"
          ) : (
            <Loader>
              Отправка
              <Dot>.</Dot>
              <Dot>.</Dot>
              <Dot>.</Dot>
            </Loader>
          )}
        </SendCodeAndFormButton>
      </AuthCodePageButtonsContainer>
    </AuthCodePageContainer>
  );
};

export default AuthCodePage;
