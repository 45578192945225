import React, { memo } from "react";
import styled from "styled-components";
import TranslationPanel from "../bible_modal_navigation/translation_panel";
import BibleModalBooksNav from "./bible_modal_books_nav";
import BibleModalSearchPanel from "./bible_modal_search_panel";
import BibleModalSearchPlaceButtons from "./bible_modal_search_place_buttons";

const BibleModalNavigationContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  gap: 12px;
  width: 100%;
  height: 100%;
`;

const BibleModalNavigation = ({
  bibleTranslations,
  currentTranslation,
  currentBibleParams,
  setBibleTranslations,
  setCurrentBibleParams,
  setSelectedVerses,
  bibleModalSearchPlace,
  bibleModalSearchInput,
  currentTranslationData,
  setCurrentTranslationData,
  setBibleModalSearchInput,
  setSearchIsLoading,
  activeScreenDesktop,
  setActiveScreenDesktop,
  setBibleModalSearchResults,
  setBibleModalSearchPlace,
}) => {
  return (
    <BibleModalNavigationContainer>
      <BibleModalSearchPanel
        currentBibleParams={currentBibleParams}
        bibleModalSearchPlace={bibleModalSearchPlace}
        bibleModalSearchInput={bibleModalSearchInput}
        setBibleModalSearchInput={setBibleModalSearchInput}
        setSearchIsLoading={setSearchIsLoading}
        activeScreenDesktop={activeScreenDesktop}
        setActiveScreenDesktop={setActiveScreenDesktop}
        setBibleModalSearchResults={setBibleModalSearchResults}
      />

      <TranslationPanel
        currentTranslation={currentTranslation}
        bibleTranslations={bibleTranslations}
        setBibleTranslations={setBibleTranslations}
        setCurrentBibleParams={setCurrentBibleParams}
        setSelectedVerses={setSelectedVerses}
      />

      {activeScreenDesktop === "bible_modal_chapter" && (
        <BibleModalBooksNav
          currentTranslation={currentTranslation}
          currentBibleParams={currentBibleParams}
          setSelectedVerses={setSelectedVerses}
          setCurrentBibleParams={setCurrentBibleParams}
          currentTranslationData={currentTranslationData}
        />
      )}

      {activeScreenDesktop === "bible_modal_search" && (
        <BibleModalSearchPlaceButtons
          bibleModalSearchPlace={bibleModalSearchPlace}
          setBibleModalSearchPlace={setBibleModalSearchPlace}
        />
      )}
    </BibleModalNavigationContainer>
  );
};

export default memo(BibleModalNavigation);
