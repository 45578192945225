import React from "react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import UserImage from "../../user_image";
import { APPLICATION_ROUTES } from "../../../utils/routes";
import { UserIcon, UserIconContainer } from "./header_styled_components";
import useWindowBreakpoint from "../../../hooks/use_window_breakpoint";

const HeaderUserProfileButtonsContainer = styled.div`
  position: relative;

  @media (max-width: 1024px) {
    height: 53px;
  }
`;

const UserProfileButtonContainer = styled.div`
  @media (max-width: 1024px) {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  @media (max-width: 530px) {
    padding-top: 5px;
  }
`;

const AuthPageIcon = styled(UserIconContainer)`
  @media (max-width: 1024px) {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
`;

const UserName = styled.div`
  position: absolute;
  top: 50%;
  right: 33px;
  transform: translateY(-50%);
  font-size: 16px;
  color: rgb(142, 142, 142);
  letter-spacing: 0.4px;
  white-space: nowrap;

  @media (max-width: 1024px) {
    position: static;
    transform: none;
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    color: ${({ isActive }) => (isActive ? "#1e1e1e" : "rgb(142, 142, 142)")};
    letter-spacing: 0px;
  }

  @media (max-width: 470px) {
    font-size: 14px;
    font-weight: 400;
  }
`;

const AuthButtonText = styled.div`
  position: absolute;
  top: 50%;
  right: 33px;
  transform: translateY(-50%);
  font-size: 16px;
  color: rgb(142, 142, 142);

  @media (max-width: 1024px) {
    position: static;
    transform: none;
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    color: ${({ isActive }) => (isActive ? "#1e1e1e" : "rgb(142, 142, 142)")};
  }

  @media (max-width: 470px) {
    font-size: 14px;
    font-weight: 400;
  }
`;

const HeaderUserProfileButtons = ({ userData, isTablet }) => {
  const location = useLocation();
  const isLoginActive = location.pathname === APPLICATION_ROUTES.LOGIN;
  const isRegitrationActive =
    location.pathname === APPLICATION_ROUTES.REGISTRATION;
  const isProfileActive = location.pathname === APPLICATION_ROUTES.PROFILE;

  const isMobile = useWindowBreakpoint(530);

  return (
    <HeaderUserProfileButtonsContainer>
      {!userData._id ? (
        <AuthPageIcon to={APPLICATION_ROUTES.LOGIN}>
          <UserIcon
            active={isLoginActive || isRegitrationActive || !isTablet}
          />
          {isTablet && (
            <AuthButtonText isActive={isLoginActive || isRegitrationActive}>
              Войти
            </AuthButtonText>
          )}
        </AuthPageIcon>
      ) : (
        <UserProfileButtonContainer>
          <Link to={APPLICATION_ROUTES.PROFILE}>
            <UserImage
              userData={userData}
              size={isMobile ? "36px" : isTablet ? "26px" : "26px"}
              fontSize={isMobile ? "14px" : isTablet ? "12px" : "10px"}
              color={"#fff"}
              fontWeight={400}
              background={
                "linear-gradient(88deg, #05baa4 -19%, #2046e9 129.9%)"
              }
              isButton={true}
            />
          </Link>
          {!isMobile && (
            <UserName isActive={isProfileActive}>{userData.user_name}</UserName>
          )}
        </UserProfileButtonContainer>
      )}
    </HeaderUserProfileButtonsContainer>
  );
};

export default HeaderUserProfileButtons;
