import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import { OnBibleLogo, HeaderDonateIcon } from "../../assets/icons";
import { APPLICATION_ROUTES } from "../../utils/routes";
import {
  HomeIcon,
  ChatIcon,
  BibleIcon,
  MainNavContainer,
  HeaderButtonsContainer,
  AppHeaderContainer,
  StudyIcon,
  UserIcon,
  DonateIconContainer,
  HeaderLogoContainer,
  UserIconContainer,
  AdminButtonContainer,
  HideHeaderButton,
  HideHeaderButtonWrapper,
} from "./header_components/header_styled_components";
import HeaderMenuItem from "./header_components/header_menu_item";
import HeaderLanguageSelector from "./header_components/header_language_selector";
import HeaderMoreMenu from "./header_components/header_more_menu";
import { useAppContext } from "../../../app_context";
import HeaderAdminIcon from "../../assets/icons/header_icons/header_admin_icon";
import { useUserDataContext } from "../../../user_data_context";
import HeaderUserProfileButtons from "./header_components/header_user_profile_buttons";
import useWindowBreakpoint from "../../hooks/use_window_breakpoint";
import HideArrowIcon from "../../assets/icons/header_icons/hide_arrow_icon";

const NAV_LINKS = [
  {
    path: APPLICATION_ROUTES.HOME,
    icon: HomeIcon,
    text: "Главная",
  },
  {
    path: APPLICATION_ROUTES.STUDY,
    icon: StudyIcon,
    text: "Учиться",
  },
  {
    path: APPLICATION_ROUTES.BIBLE,
    icon: BibleIcon,
    text: "Библия",
  },
  {
    path: APPLICATION_ROUTES.CHATS,
    icon: ChatIcon,
    text: "Чаты",
  },
];

const Header = () => {
  const { showAppHeader, setShowAppHeader, showHideHeaderButton } =
    useAppContext();
  const { userData } = useUserDataContext();

  const isTablet = useWindowBreakpoint(1024);

  const showAdminButton =
    userData?.user_role === "superadmin" ||
    userData?.user_access?.is_admin_of_platform ||
    userData?.user_access?.is_admin_of_courses ||
    userData?.user_access?.is_admin_of_groups ||
    userData?.user_access?.is_author;

  const appNavItems = NAV_LINKS.filter((link) => {
    if (link.path === APPLICATION_ROUTES.CHATS) {
      return userData?.user_role === "superadmin";
    }
    return true;
  });

  const handleHideHeader = useCallback((event) => {
    event.stopPropagation();
    setShowAppHeader((prevState) => !prevState);
  }, []);

  return (
    <div style={{ position: "relative" }}>
      {showAppHeader && (
        <AppHeaderContainer>
          <HeaderLogoContainer>
            <Link to={APPLICATION_ROUTES.HOME}>
              <OnBibleLogo />
            </Link>
          </HeaderLogoContainer>

          <MainNavContainer>
            {appNavItems.map((menuItem) => (
              <HeaderMenuItem key={menuItem.path} menuItem={menuItem} />
            ))}

            {/* <HeaderMoreMenu /> */}

            {isTablet && (
              <HeaderUserProfileButtons
                userData={userData}
                isTablet={isTablet}
              />
            )}
          </MainNavContainer>

          <HeaderButtonsContainer>
            {/* <DonateIconContainer to={APPLICATION_ROUTES.DONATE}>
              <HeaderDonateIcon />
            </DonateIconContainer> */}

            <HeaderUserProfileButtons userData={userData} />

            {/* <HeaderLanguageSelector /> */}

            {showAdminButton && (
              <UserIconContainer to={APPLICATION_ROUTES.ADMIN}>
                <AdminButtonContainer>
                  <HeaderAdminIcon />
                </AdminButtonContainer>
              </UserIconContainer>
            )}
          </HeaderButtonsContainer>
        </AppHeaderContainer>
      )}

      {showHideHeaderButton && (
        <HideHeaderButtonWrapper
          showAppHeader={showAppHeader}
          onClick={handleHideHeader}
        >
          <HideHeaderButton showAppHeader={showAppHeader}>
            <HideArrowIcon showAppHeader={showAppHeader} />
          </HideHeaderButton>
        </HideHeaderButtonWrapper>
      )}
    </div>
  );
};

export default Header;
