import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import axios from "../../../../axios_config";
import CourseAuthors from "./course_authors";
import CourseAdmins from "./course_admins";
import CourseComplexityLevel from "./course_complexity_level";
import CourseAbout from "./course_about";
import CourseVideo from "./course_video";
import CourseStatus from "./course_status";
import CourseFeedback from "./course_feedback";
import { BASE_API_URL } from "../../../../common/endpoints";
import UnsavedChangesModalWindow from "../../../../common/components/modal_windows/unsaved_changes_modal_window";
import { useUserDataContext } from "../../../../user_data_context";
import StaffListModalWindow from "../courses_modal_window/staff_list_modal_window";
import AdminPageSettingsButtons from "../../admin_page_components/admin_page_settings_buttons";
import CourseCertificate from "./course_certificate";
import CourseDuration from "./course_duration";
import CourseCardImage from "./course_card_image";
import CourseHeaderImage from "./course_header_image";
import CourseMainColor from "./course_main_color";
import { useSearchParams } from "react-router-dom";
import { compareObjects } from "../../../../common/utils/functions/compare_objects";

export const CourseSettingsInput = styled.textarea`
  resize: none;
  max-height: 90px;
  width: 100%;
  padding: 8px 15px;
  border-radius: 8px;
  background: #fff;
  font-size: 16px;
  line-height: 24px;
  color: #1e1e1e;
`;

const CourseSettingsWrapper = styled.div`
  position: relative;
  width: 437px;
`;

const CourseSettingsContainer = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: auto;
  padding: 26px 24px 100px;
  width: 434px;
  border-left: 1px solid #828282;
  max-height: calc(100vh - 80px);

  ::-webkit-scrollbar {
    width: 0px;
  }
`;

const CourseSettingsListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 28px;
`;

const InputWrapper = styled.div`
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  background: #fff;
`;

const CourseTitleInput = styled.textarea`
  resize: none;
  max-height: 88px;
  width: 100%;
  padding: 8px 15px;
  font-size: 16px;
  line-height: 24px;
  color: #1e1e1e;

  ::-webkit-scrollbar {
    width: 3px;
  }
`;

const CourseDescriptionInput = styled(CourseTitleInput)``;

const WhiteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
`;

const checkImagesForDeletion = (courseSettingsData, courseSettingsMemoData) => {
  const deletionImageList = [];

  if (
    courseSettingsMemoData?.course_preview_image &&
    courseSettingsMemoData.course_preview_image !==
      courseSettingsData.course_preview_image
  ) {
    deletionImageList.push(courseSettingsMemoData.course_preview_image);
  }

  if (
    courseSettingsMemoData?.course_header_image &&
    courseSettingsMemoData.course_header_image !==
      courseSettingsData.course_header_image
  ) {
    deletionImageList.push(courseSettingsMemoData.course_header_image);
  }

  if (
    courseSettingsMemoData?.course_video_image &&
    courseSettingsMemoData.course_video_image !==
      courseSettingsData.course_video_image
  ) {
    deletionImageList.push(courseSettingsMemoData.course_video_image);
  }

  return deletionImageList;
};

const checkImagesForNotSaveDeletion = (
  courseSettingsData,
  courseSettingsMemoData
) => {
  const deletionImageList = [];

  if (
    courseSettingsData?.course_preview_image &&
    courseSettingsData.course_preview_image !==
      courseSettingsMemoData.course_preview_image
  ) {
    deletionImageList.push(courseSettingsData.course_preview_image);
  }

  if (
    courseSettingsData?.course_header_image &&
    courseSettingsData.course_header_image !==
      courseSettingsMemoData.course_header_image
  ) {
    deletionImageList.push(courseSettingsData.course_header_image);
  }

  if (
    courseSettingsData?.course_video_image &&
    courseSettingsData.course_video_image !==
      courseSettingsMemoData.course_video_image
  ) {
    deletionImageList.push(courseSettingsData.course_video_image);
  }

  return deletionImageList;
};

const deleteImages = async (deletionImageList) => {
  const deletePromises = deletionImageList.map(async (imageUrl) => {
    const imageName = new URL(imageUrl).pathname.split("/").pop();
    try {
      return await axios.delete(
        `${BASE_API_URL}/uploads/deletefile/${imageName}`
      );
    } catch (error) {
      console.error("Ошибка при удалении картинки:", error);
    }
  });

  await Promise.all(deletePromises);
};

const AdminPageCourseSettings = ({
  activePreviewsBlock,
  courseSettingsData,
  courseSettingsMemoData,
  setCourseSettingsData,
  setCourseSettingsMemoData,
  setSelectedUserInfo,
  openEditCourseModal,
  setOpenEditCourseModal,
  closeCourseSettings,
  fetchCourses,
}) => {
  const { userData } = useUserDataContext();
  const [searchParams, setSearchParams] = useSearchParams();

  const courseTitleInputRef = useRef(null);
  const courseDescriptionInputRef = useRef(null);

  const [openStaffListModal, setOpenStaffListModal] = useState(false); // authors, admins, feedback
  const [showCheckIcon, setShowCheckIcon] = useState(false);

  const isCurrentVideoImage =
    courseSettingsData.course_video_image ===
    courseSettingsMemoData.course_video_image;

  const handleCourseTitleInput = useCallback((event) => {
    setCourseSettingsData((prevState) => ({
      ...prevState,
      course_name: event.target.value,
    }));
  }, []);

  useEffect(() => {
    if (courseTitleInputRef.current) {
      courseTitleInputRef.current.style.height = "auto";
      courseTitleInputRef.current.style.height =
        courseTitleInputRef.current.scrollHeight + "px";
    }
  }, [courseSettingsData.course_name, courseTitleInputRef]);

  const handleCourseDescriptionInput = useCallback((event) => {
    setCourseSettingsData((prevState) => ({
      ...prevState,
      course_description: event.target.value,
    }));
  }, []);

  useEffect(() => {
    if (courseDescriptionInputRef.current) {
      courseDescriptionInputRef.current.style.height = "auto";
      courseDescriptionInputRef.current.style.height =
        courseDescriptionInputRef.current.scrollHeight + "px";
    }
  }, [courseSettingsData.course_description, courseDescriptionInputRef]);

  useEffect(() => {
    const unequal = !compareObjects(courseSettingsData, courseSettingsMemoData);

    if (unequal) {
      setShowCheckIcon(false);
    } else {
      setShowCheckIcon(true);
    }
  }, [courseSettingsData, courseSettingsMemoData]);

  const onClickSaveButton = useCallback(async () => {
    const { _id } = courseSettingsData;

    try {
      if (_id === "new_course") {
        const newCourse = { ...courseSettingsData };
        delete newCourse._id;
        newCourse["course_creator"] = { _id: userData._id };

        const response = await axios.post(`${BASE_API_URL}/courses`, newCourse);

        searchParams.set("courseId", response.data.course._id);
        setSearchParams(searchParams);

        const newCourseResponseData = {
          ...courseSettingsData,
          _id: response.data.course._id,
        };

        setCourseSettingsMemoData(newCourseResponseData);
        setCourseSettingsData(newCourseResponseData);
      } else {
        if (compareObjects(courseSettingsData, courseSettingsMemoData)) return;

        const updatedCourse = { ...courseSettingsData };
        updatedCourse["course_modified_by"] = { _id: userData._id };
        updatedCourse["course_modified_at"] = new Date().toISOString();

        const response = await axios.patch(
          `${BASE_API_URL}/courses/${_id}`,
          updatedCourse
        );

        setCourseSettingsMemoData(response.data);
        setCourseSettingsData(response.data);

        const deletionImageList = checkImagesForDeletion(
          courseSettingsData,
          courseSettingsMemoData
        );
        await deleteImages(deletionImageList);
      }

      fetchCourses();
      setShowCheckIcon(true);
    } catch (error) {
      console.error("Ошибка при запросе:", error);
    }
  }, [courseSettingsData, courseSettingsMemoData, userData, searchParams]);

  const onClickNotSaveButton = useCallback(async () => {
    try {
      const deletionImageList = checkImagesForNotSaveDeletion(
        courseSettingsData,
        courseSettingsMemoData
      );

      await deleteImages(deletionImageList);

      closeCourseSettings();
    } catch (error) {
      console.error("Ошибка при удалении картинок:", error);
    }
  }, [
    courseSettingsData,
    courseSettingsMemoData,
    deleteImages,
    closeCourseSettings,
  ]);

  const updateImageSettings = useCallback((image, key) => {
    setCourseSettingsData((prevState) => ({
      ...prevState,
      [key]: image,
    }));
  }, []);

  const onClickModalSaveButton = async () => {
    await onClickSaveButton();
    closeCourseSettings();
  };

  return (
    <CourseSettingsWrapper>
      {courseSettingsData._id && activePreviewsBlock === "courses" && (
        <CourseSettingsContainer
          onClick={(e) => e.stopPropagation()}
          onMouseDown={(e) => e.stopPropagation()}
        >
          <CourseSettingsListContainer>
            <InputWrapper>
              <CourseTitleInput
                ref={courseTitleInputRef}
                placeholder="Название курса"
                type="text"
                rows={1}
                value={courseSettingsData.course_name || ""}
                onChange={handleCourseTitleInput}
              />
            </InputWrapper>

            <InputWrapper>
              <CourseDescriptionInput
                ref={courseDescriptionInputRef}
                placeholder="Описание"
                type="text"
                rows={1}
                value={courseSettingsData.course_description || ""}
                onChange={handleCourseDescriptionInput}
              />
            </InputWrapper>

            <CourseAuthors
              authorsList={courseSettingsData.course_authors}
              setCourseSettingsData={setCourseSettingsData}
              setOpenStaffListModal={setOpenStaffListModal}
              setSelectedUserInfo={setSelectedUserInfo}
            />

            <CourseAdmins
              adminsList={courseSettingsData.course_admins}
              setCourseSettingsData={setCourseSettingsData}
              setOpenStaffListModal={setOpenStaffListModal}
              setSelectedUserInfo={setSelectedUserInfo}
            />

            <CourseCardImage
              courseSettingsData={courseSettingsData}
              courseSettingsMemoData={courseSettingsMemoData}
              updateImageSettings={updateImageSettings}
            />

            <CourseHeaderImage
              courseSettingsData={courseSettingsData}
              courseSettingsMemoData={courseSettingsMemoData}
              updateImageSettings={updateImageSettings}
            />

            <CourseComplexityLevel
              courseSettingsData={courseSettingsData}
              setCourseSettingsData={setCourseSettingsData}
            />

            <CourseAbout
              courseSettingsData={courseSettingsData}
              setCourseSettingsData={setCourseSettingsData}
            />

            <WhiteWrapper>
              <CourseVideo
                courseSettingsData={courseSettingsData}
                setCourseSettingsData={setCourseSettingsData}
                updateImageSettings={updateImageSettings}
                isCurrentVideoImage={isCurrentVideoImage}
              />

              <CourseFeedback
                courseSettingsData={courseSettingsData}
                setCourseSettingsData={setCourseSettingsData}
                setOpenStaffListModal={setOpenStaffListModal}
                setSelectedUserInfo={setSelectedUserInfo}
              />

              <CourseCertificate
                courseSettingsData={courseSettingsData}
                setCourseSettingsData={setCourseSettingsData}
                courseStudentName={`${userData.user_name} ${userData.user_second_name}`}
              />

              <CourseDuration
                courseSettingsData={courseSettingsData}
                setCourseSettingsData={setCourseSettingsData}
              />
            </WhiteWrapper>

            <CourseStatus
              courseSettingsData={courseSettingsData}
              setCourseSettingsData={setCourseSettingsData}
            />

            <CourseMainColor
              courseSettingsData={courseSettingsData}
              setCourseSettingsData={setCourseSettingsData}
            />

            <AdminPageSettingsButtons
              onClickSaveButton={onClickSaveButton}
              onClickCancelButton={closeCourseSettings}
              showCheckIcon={showCheckIcon}
            />
          </CourseSettingsListContainer>
        </CourseSettingsContainer>
      )}

      {openEditCourseModal && (
        <UnsavedChangesModalWindow
          setOpen={setOpenEditCourseModal}
          onSaveSettingsButton={onClickModalSaveButton}
          onClickNotSaveButton={onClickNotSaveButton}
        />
      )}

      {openStaffListModal && (
        <StaffListModalWindow
          courseSettingsData={courseSettingsData}
          openStaffListModal={openStaffListModal}
          setCourseSettingsData={setCourseSettingsData}
          setOpen={setOpenStaffListModal}
        />
      )}
    </CourseSettingsWrapper>
  );
};

export default memo(AdminPageCourseSettings);
