import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";
import { LESSON_BG_COLOR } from "..";
import CloseMessageIcon from "../../../../common/assets/icons/study_page_icons/close_message_icon";
import { useUserDataContext } from "../../../../user_data_context";

const CourseLesson = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 36px;
  height: 36px;
  color: #ffffff;
  border-radius: 11px;
  user-select: none;
  background: ${({ background }) => background};

  &:hover {
    box-shadow: 4.732px 4.732px 8.281px 0px rgba(0, 0, 0, 0.12);

    transition: 0.2s;
  }

  @media (max-width: 412px) {
    min-width: 30px;
    height: 30px;
  }
`;

const CourseLessonMessage = styled.div`
  width: 187px;
  height: 48px;
  padding: 6px 10px;
  background: #5172ea;
  border-radius: 12px 12px 12px 0px;
  z-index: 9;

  @media (max-width: 885px) {
    border-radius: 12px;
  }

  & > p {
    color: #fff;
    font-size: 10px;
    font-weight: 400;
    line-height: 120%;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    white-space: normal;
  }
`;

const CloseMessageIconContainer = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  width: 8px;
  height: 8px;
  background: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const LessonsInfoItem = ({
  index,
  lesson,
  showMessageId,
  lessonsRef,
  isNewCourse,
  courseStatus,
  lessonId,
  userCourseLessons,
  inComponent,
  authError,
  setShowMessageId,
  handleClickOnLesson,
}) => {
  const messageRef = useRef(null);
  const { userData } = useUserDataContext();

  const [messagePosition, setMessagePosition] = useState(null);
  const [lessonStatus, setLessonStatus] = useState("closed"); // finished, in_process, next, closed

  const lessonNumber = index + 1;

  const userLesson = userCourseLessons?.find(
    (lesson) => lesson._id === lessonId
  );

  const userStudyId = userLesson?.user_study_id;

  const lessonBackgroundColor =
    lessonStatus === "closed"
      ? LESSON_BG_COLOR.CLOSED
      : lessonStatus === "in_process" || lessonStatus === "next"
      ? LESSON_BG_COLOR.CURRENT
      : LESSON_BG_COLOR.FINISHED;

  useEffect(() => {
    if (inComponent === "admin_page") {
      setLessonStatus("finished");
    } else if (
      courseStatus === "closed" ||
      courseStatus === "announcement" ||
      authError
    ) {
      setLessonStatus("closed");
    } else {
      if (isNewCourse && index === 0) {
        setLessonStatus("next");
      } else if (userLesson) {
        if (userLesson.lesson_status === "in_process") {
          setLessonStatus("in_process");
        } else if (userLesson.lesson_status === "finished") {
          setLessonStatus("finished");
        }
      } else {
        const lastFinishedIndex = userCourseLessons
          ?.map((lesson) => lesson.lesson_status)
          ?.lastIndexOf("finished");

        const nextIndex = lastFinishedIndex + 1;

        if (
          index === nextIndex &&
          !userCourseLessons?.some(
            (lesson) => lesson.lesson_status === "in_process"
          )
        ) {
          setLessonStatus("next");
        } else {
          setLessonStatus("closed");
        }
      }
    }
  }, [
    inComponent,
    isNewCourse,
    index,
    courseStatus,
    userCourseLessons,
    lessonId,
    authError,
  ]);

  const handleClick = useCallback(
    (e) => {
      if (e.target.closest(".course-lesson") !== e.currentTarget) return;

      const rect = e.currentTarget.getBoundingClientRect();
      const messageWidth = 187;
      const messageHeight = 48;

      const top = Math.max(rect.top - messageHeight + window.scrollY + 10, 0);
      let left;

      if (window.innerWidth < 775) {
        const parentRect = lessonsRef.current.getBoundingClientRect();
        left = parentRect.left + (parentRect.width - messageWidth) / 2;
      } else if (window.innerWidth < 885) {
        left = rect.left + (rect.width - messageWidth) / 2;
      } else {
        left = Math.min(rect.left + 26, window.innerWidth - messageWidth);
      }

      setMessagePosition({
        top: top,
        left: left,
      });

      handleClickOnLesson(
        e,
        lesson._id,
        lessonStatus,
        lessonNumber,
        userStudyId
      );
    },
    [lesson, lessonStatus, lessonNumber, userData, userStudyId]
  );

  useEffect(() => {
    const handleClick = () => {
      setShowMessageId(null);
    };

    if (showMessageId === lesson._id) {
      document.addEventListener("click", handleClick);
    }

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [showMessageId, lesson._id]);

  return (
    <CourseLesson
      className="course-lesson"
      background={lessonBackgroundColor}
      onClick={handleClick}
    >
      {lessonNumber}

      {showMessageId === lesson._id &&
        messagePosition &&
        createPortal(
          <CourseLessonMessage
            ref={messageRef}
            style={{
              position: "absolute",
              top: `${messagePosition.top}px`,
              left: `${messagePosition.left}px`,
            }}
          >
            <p>
              Чтобы получить доступ к этому уроку необходимо завершить
              предыдущий 🙂️
            </p>
            <CloseMessageIconContainer>
              <CloseMessageIcon />
            </CloseMessageIconContainer>
          </CourseLessonMessage>,
          lessonsRef.current
        )}
    </CourseLesson>
  );
};

export default memo(LessonsInfoItem);
