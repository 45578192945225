import React from "react";
import styled from "styled-components";
import VerticalLine from "../../../../../../assets/icons/vertical_line";
import RemoveIcon from "../../../../../../assets/icons/remove_icon";
import useWindowBreakpoint from "../../../../../../hooks/use_window_breakpoint";

const TranslationHeaderContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 4px;
  width: 100%;
  height: 30px;
  cursor: pointer;

  @media (max-width: 744px) {
    height: 48px;
  }
`;

const HeaderInfoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  flex: 1;
  padding: 0 14px;
  height: 100%;
  transition: 0.2s;
  overflow: hidden;
  cursor: pointer;

  @media (max-width: 744px) {
    gap: 5px;
  }
`;

const SelectedTranslationAbbreviation = styled.div`
  color: #f8d254;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 133.333% */
  white-space: nowrap;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  user-select: none;

  @media (max-width: 744px) {
    font-size: 14px;
    line-height: 122%;
  }
`;

const SelectedTranslationText = styled.div`
  color: #828282;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: -0.2px;

  @media (max-width: 744px) {
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: -0.14px;
    width: 76%;
    flex: 1;
  }
`;

const CloseTranslationButton = styled.div`
  padding: 8px 16px 6px 4px;
`;

const RemoveIconStyled = styled(RemoveIcon)`
  width: 22px;
  height: 22px;
`;

const TranslationPanelHeader = ({ currentTranslation, onClickCloseButton }) => {
  const isMobile = useWindowBreakpoint(744);

  return (
    <TranslationHeaderContainer>
      <HeaderInfoContainer>
        {currentTranslation && (
          <SelectedTranslationAbbreviation>
            {currentTranslation.bible_code}
          </SelectedTranslationAbbreviation>
        )}

        <VerticalLine height={isMobile ? "20px" : "15px"} />

        <SelectedTranslationText>
          {currentTranslation?.bible_name || "Выберите перевод"}
        </SelectedTranslationText>
      </HeaderInfoContainer>

      {isMobile && (
        <CloseTranslationButton onClick={onClickCloseButton}>
          <RemoveIconStyled color="#383838" />
        </CloseTranslationButton>
      )}
    </TranslationHeaderContainer>
  );
};

export default TranslationPanelHeader;
