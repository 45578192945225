import React, { memo, useCallback, useEffect, useState } from "react";
import axios from "axios";
import styled from "styled-components";
import RemoveIcon from "../../../../../assets/icons/remove_icon";
import Checkbox from "../../../../checkbox";
import { SearchIcon } from "../../../../../assets/icons";

const BibleModalSearchPanelWrapper = styled.div`
  position: relative;
`;

const BibleModalSearchPanelContainer = styled.form`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6px;
  flex: 1;
  min-height: 30px;
  padding: 0 10px 0 14px;
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.12);
  ${({ inputIsEmpty }) => inputIsEmpty && "border: 1px solid red;"}

  &: hover {
    box-shadow: 4.462px 4.462px 7.808px 0px rgba(0, 0, 0, 0.1);
    transition: 0.2s;
  }
`;

const BibleModalSearchInput = styled.input`
  flex: 1;
  font-size: 13px;
  width: 100%;
  letter-spacing: -0.13px;
`;

const SearchIconContainer = styled.button`
  display: flex;
  align-items: center;
  width: 15px;
  height: 16px;
  cursor: pointer;

  &:hover {
    scale: 1.1;
    transition: 0.2s;
  }
`;

const RemoveIconStyled = styled(RemoveIcon)`
  width: 13px;
  height: 13px;
`;

const SearchCheckboxContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
  width: 100%;
  margin-top: 12px;
  font-size: 12px;
`;

const BibleModalSearchPanel = ({
  activeScreenDesktop,
  bibleModalSearchPlace,
  bibleModalSearchInput,
  setActiveScreenDesktop,
  setBibleModalSearchResults,
  setBibleModalSearchInput,
  currentBibleParams,
  setSearchIsLoading,
}) => {
  const [searchWholeWord, setWholeWordCheckbox] = useState(false);
  const [searchWithRegister, setWithRegisterCheckbox] = useState(false);
  const [inputIsEmpty, setInputIsEmpty] = useState(false);
  const [showedInputIcon, setShowedInputIcon] = useState("search_icon"); //search_icon, remove_icon

  const onSearchSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      if (
        bibleModalSearchInput?.length > 1 &&
        /^\p{L}+$/u.test(bibleModalSearchInput)
      ) {
        setSearchIsLoading(true);
        setActiveScreenDesktop("bible_modal_search");
        try {
          const response = await axios.post(
            "https://api.on-bible.com/bible/verses/lookup",
            {
              bible_code: currentBibleParams.bibleId,
              search_value: bibleModalSearchInput,
              search_option_place: bibleModalSearchPlace,
              search_option_whole_word: searchWholeWord,
              search_option_with_register: searchWithRegister,
            }
          );
          setBibleModalSearchResults({
            searchBible: currentBibleParams.bibleId,
            searchTestament: bibleModalSearchPlace,
            searchWord: bibleModalSearchInput,
            searchResults: response.data.results,
          });
        } catch (error) {
          console.error("Ошибка при отправке запроса", error);
        }

        setShowedInputIcon("remove_icon");
        setSearchIsLoading(false);
      } else {
        setInputIsEmpty(true);

        setTimeout(() => {
          setInputIsEmpty(false);
        }, 2000);
      }
    },
    [
      currentBibleParams,
      bibleModalSearchInput,
      bibleModalSearchPlace,
      searchWholeWord,
      searchWithRegister,
    ]
  );

  useEffect(() => {
    if (bibleModalSearchInput && activeScreenDesktop === "bible_modal_search") {
      const fakeEvent = { preventDefault: () => {} };
      onSearchSubmit(fakeEvent);
    }

    if (
      !bibleModalSearchInput?.length &&
      activeScreenDesktop === "bible_modal_search"
    ) {
      setInputIsEmpty(true);

      setTimeout(() => {
        setInputIsEmpty(false);
      }, 2000);
    }
  }, [
    currentBibleParams,
    bibleModalSearchPlace,
    searchWholeWord,
    searchWithRegister,
  ]);

  useEffect(() => {
    if (activeScreenDesktop !== "bible_modal_search") {
      setWholeWordCheckbox(false);
      setWithRegisterCheckbox(false);
      setShowedInputIcon("search_icon");
    }
  }, [activeScreenDesktop]);

  useEffect(() => {
    setShowedInputIcon("search_icon");
  }, [bibleModalSearchInput]);

  const onRemoveIconClick = useCallback((event) => {
    event.stopPropagation();
    setBibleModalSearchInput("");
    setShowedInputIcon("search_icon");
  }, []);

  const handleSearchInput = useCallback((event) => {
    setBibleModalSearchInput(event.target.value);
  }, []);

  const handleWholeWordCheckbox = useCallback((event) => {
    event.stopPropagation();
    setWholeWordCheckbox((prevState) => !prevState);
  }, []);

  const handleWithRegisterCheckbox = useCallback((event) => {
    event.stopPropagation();
    setWithRegisterCheckbox((prevState) => !prevState);
  }, []);

  return (
    <BibleModalSearchPanelWrapper>
      <BibleModalSearchPanelContainer
        inputIsEmpty={inputIsEmpty}
        onSubmit={onSearchSubmit}
      >
        <BibleModalSearchInput
          placeholder={"Поиск по Библии"}
          value={bibleModalSearchInput}
          onChange={handleSearchInput}
        />

        {showedInputIcon === "search_icon" && (
          <SearchIconContainer type="submit">
            <SearchIcon />
          </SearchIconContainer>
        )}

        {showedInputIcon === "remove_icon" && (
          <SearchIconContainer onClick={onRemoveIconClick}>
            <RemoveIconStyled color="#383838" />
          </SearchIconContainer>
        )}
      </BibleModalSearchPanelContainer>

      {activeScreenDesktop === "bible_modal_search" && (
        <SearchCheckboxContainer>
          <Checkbox
            checked={searchWholeWord}
            onClickCheckbox={handleWholeWordCheckbox}
            color={searchWholeWord ? "#1e1e1e" : "#828282"}
            fontSize={"10px"}
            letterSpacing={"0.4px"}
            component={"bible_modal_search"}
          >
            Целое слово
          </Checkbox>

          <Checkbox
            checked={searchWithRegister}
            onClickCheckbox={handleWithRegisterCheckbox}
            color={searchWithRegister ? "#1e1e1e" : "#828282"}
            fontSize={"10px"}
            letterSpacing={"0.4px"}
            component={"bible_modal_search"}
          >
            Учитывать регистр
          </Checkbox>
        </SearchCheckboxContainer>
      )}
    </BibleModalSearchPanelWrapper>
  );
};

export default memo(BibleModalSearchPanel);
