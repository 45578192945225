import React, { useCallback } from "react";
import { toast, Slide } from "react-toastify";
import copy from "copy-to-clipboard";
import { BiblePageSidebarItem } from "./";
import BiblePageShareIcon from "../../../common/assets/icons/bible_page_icons/bible_page_share_icon";

const BiblePageSidebarShare = ({ selectedSearchVerse, currentBibleScreen }) => {
  const showNotification = () => {
    toast("Ссылка скопирована!", {
      position: "top-left",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      closeButton: false,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: "light",
      pauseOnFocusLoss: false,
      transition: Slide,
    });
  };

  const handleShareLink = useCallback(() => {
    if (currentBibleScreen === "bible_chapter") {
      const url = window.location.href;
      try {
        const isCopied = copy(url);
        if (isCopied) {
          showNotification();
        } else {
          console.error("Не удалось скопировать ссылку.");
          alert("Ошибка при копировании ссылки.");
        }
      } catch (error) {
        console.error("Ошибка копирования: ", error);
        alert("Ошибка при копировании ссылки.");
      }
    }

    if (currentBibleScreen === "bible_search") {
      const bibleId = selectedSearchVerse.bible_code;
      const bookId = selectedSearchVerse.book_code;
      const chapterId = selectedSearchVerse.chapter_num;
      const selectVerses = selectedSearchVerse.verse_num;

      const params = `/bible/?bibleId=${bibleId}&bookId=${bookId}&chapterId=${chapterId}&selectVerses=${selectVerses}&selectBible=${bibleId}`;
      const host = window.location.origin;
      const fullUrl = host + params;

      try {
        const isCopied = copy(fullUrl);
        if (isCopied) {
          showNotification();
        } else {
          console.error("Не удалось скопировать ссылку.");
          alert("Ошибка при копировании ссылки.");
        }
      } catch (error) {
        console.error("Ошибка копирования: ", error);
        alert("Ошибка при копировании ссылки.");
      }
    }
  }, [currentBibleScreen, selectedSearchVerse]);

  return (
    <BiblePageSidebarItem onClick={handleShareLink}>
      <BiblePageShareIcon />
      <p>Поделиться</p>
    </BiblePageSidebarItem>
  );
};

export default BiblePageSidebarShare;
