import React, { useCallback } from "react";
import styled from "styled-components";
import BibleChapterArrowIcon from "../../../../../assets/icons/bible_page_icons/bible_chapter_arrow_icon";

const BibleChapterArrowsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  height: 100%;
`;

const BibleChapterArrowButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background: #fff;
  cursor: pointer;
  padding: 0 2px 0 0;
  transition: 0.2s;

  &: hover {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
      rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  }
`;

const BibleChapterArrowButtonRight = styled(BibleChapterArrowButton)`
  padding: 0 0 0 1px;
`;

const BibleChapterArrowRightIcon = styled(BibleChapterArrowIcon)`
  transform: rotate(180deg);
`;

const BibleChapterArrows = ({
  chapterData,
  currentTranslationData,
  currentBibleParams,
  setCurrentBibleParams,
}) => {
  const currentChapterNum = currentBibleParams.chapterId;
  const currentBookCode = currentBibleParams.bookId;
  const currentBookIndex = currentTranslationData?.findIndex(
    (book) => book.book_code === currentBookCode
  );
  const currentBook = currentTranslationData?.[currentBookIndex];

  const isFirstBook = currentBookIndex === 0;
  const isFirstChapter = currentChapterNum === 1;

  const isLastBook = currentBookIndex === currentTranslationData?.length - 1;
  const isLastChapter = currentChapterNum === currentBook?.book_chapters;

  const updateChapterAndBook = (newBookCode, newChapterNum) => {
    setCurrentBibleParams((prevState) => ({
      ...prevState,
      bookId: newBookCode,
      chapterId: newChapterNum,
    }));
  };

  const onClickLeftArrow = useCallback(
    (event) => {
      event.stopPropagation();

      if (currentChapterNum > 1) {
        updateChapterAndBook(currentBookCode, currentChapterNum - 1);
      } else if (currentBookIndex > 0) {
        const prevBook = currentTranslationData[currentBookIndex - 1];
        updateChapterAndBook(prevBook.book_code, prevBook.book_chapters);
      }
    },
    [
      chapterData,
      currentTranslationData,
      setCurrentBibleParams,
      currentChapterNum,
      currentBookCode,
      currentBookIndex,
    ]
  );

  const onClickRightArrow = useCallback(
    (event) => {
      event.stopPropagation();

      if (currentChapterNum < currentBook?.book_chapters) {
        updateChapterAndBook(currentBookCode, currentChapterNum + 1);
      } else if (currentBookIndex < currentTranslationData.length - 1) {
        const nextBook = currentTranslationData[currentBookIndex + 1];
        updateChapterAndBook(nextBook.book_code, 1);
      }
    },
    [
      chapterData,
      currentTranslationData,
      currentChapterNum,
      currentBook,
      currentBookCode,
      currentBookIndex,
    ]
  );

  return (
    <BibleChapterArrowsContainer>
      {(!isFirstBook || !isFirstChapter) && (
        <BibleChapterArrowButton onClick={onClickLeftArrow}>
          <BibleChapterArrowIcon />
        </BibleChapterArrowButton>
      )}

      {(!isLastBook || !isLastChapter) && (
        <BibleChapterArrowButtonRight onClick={onClickRightArrow}>
          <BibleChapterArrowRightIcon />
        </BibleChapterArrowButtonRight>
      )}
    </BibleChapterArrowsContainer>
  );
};

export default BibleChapterArrows;
