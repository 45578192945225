import React, { memo } from "react";
import BibleModalBooksNav from "../bible_modal_window_desktop/bible_modal_navigation/bible_modal_books_nav";
import styled from "styled-components";

const BibleNavigationMobileContainer = styled.div`
  background: #eaeaea;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding-top: 15px;
`;

const BibleNavigationMobile = ({
  currentTranslation,
  currentBibleParams,
  activeScreenMobile,
  currentTranslationData,
  setCurrentTranslationData,
  setCurrentBibleParams,
  setSelectedVerses,
  setActiveScreenMobile,
}) => {
  return (
    <BibleNavigationMobileContainer>
      <BibleModalBooksNav
        currentTranslation={currentTranslation}
        currentBibleParams={currentBibleParams}
        activeBibleMobileScreen={activeScreenMobile}
        currentTranslationData={currentTranslationData}
        setCurrentTranslationData={setCurrentTranslationData}
        setCurrentBibleParams={setCurrentBibleParams}
        setSelectedVerses={setSelectedVerses}
        setActiveScreenMobile={setActiveScreenMobile}
      />
    </BibleNavigationMobileContainer>
  );
};

export default memo(BibleNavigationMobile);
