import React, { memo } from "react";
import styled from "styled-components";
import VerticalLine from "../../../../../../assets/icons/vertical_line";

const VerticalLineStyled = styled(VerticalLine)`
  height: 13px;
`;

const BiblePartsButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
  width: 100%;
  ${({ activeScreenDesktop }) =>
    activeScreenDesktop !== "bible_search" &&
    "border-bottom: 1px solid #cacaca;"}

  @media (max-width: 744px) {
    height: 32px;
    background: #fff;
    border-bottom: none;
    border-radius: 15px;
    width: max-content;
  }
`;

const BiblePartButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ isLeftButton }) =>
    isLeftButton ? "padding-left: 10px;" : "padding-right: 10px;"}
  width: 50%;
  color: ${({ isSelected }) => (isSelected ? "#1E1E1E" : "#828282")};
  font-size: 12px;
  font-weight: 600;
  line-height: 24px;
  cursor: pointer;
  user-select: none;

  @media (max-width: 744px) {
    height: 100%;
    width: max-content;
    font-size: 14px;
    font-weight: 600;
    ${({ isLeftButton }) =>
      isLeftButton ? "padding: 0 13px 0 19px;" : "padding: 0 19px 0 13px;"}
  }
`;

const BibleTestamentButtonsMobile = ({
  activeBibleMobileScreen,
  selectedTestament,
  handleTestaments,
}) => {
  return (
    <BiblePartsButtonsContainer
      activeBibleMobileScreen={activeBibleMobileScreen}
    >
      <BiblePartButton
        isLeftButton={true}
        isSelected={selectedTestament === "ot"}
        onClick={() => handleTestaments("ot")}
      >
        Ветхий Завет
      </BiblePartButton>
      <VerticalLineStyled />
      <BiblePartButton
        isSelected={selectedTestament === "nt"}
        onClick={() => handleTestaments("nt")}
      >
        Новый Завет
      </BiblePartButton>
    </BiblePartsButtonsContainer>
  );
};

export default memo(BibleTestamentButtonsMobile);
