import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import styled from "styled-components";
import { BASE_API_URL } from "../../../../endpoints";
import ModalWindowTranslations from "../bible_modal_window_desktop/bible_modal_navigation/translation_panel/modal_window_translations";
import LanguageSelect from "../../../../../pages/bible/bible_page_desktop/bible_navigation_panel_desktop/bible_translations_panel/language_select";
import {
  BIBLE_TRANSLATIONS_LANGUAGES,
  USER_LANGUAGE,
} from "../../../../../pages/bible/bible_page_data";
import TranslationPanelHeader from "../bible_modal_window_desktop/bible_modal_navigation/translation_panel/translation_panel_header";

const BibleTranslationMobileContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% + 20px);
  padding: 15px 0 20px 0;
  background: #fff;
`;

const BibleTranslationMobile = ({
  bibleTranslations,
  currentTranslation,
  setBibleTranslations,
  setCurrentTranslation,
  setCurrentBibleParams,
  setSelectedVerses,
  setActiveScreenMobile,
}) => {
  const [currentScreen, setCurrentScreen] = useState("translations"); //translations, languages
  const [currentBibleLanguage, setCurrentBibleLanguage] = useState(null);

  const translationsLanguages = Object.values(BIBLE_TRANSLATIONS_LANGUAGES);

  useEffect(() => {
    if (currentTranslation?.bible_code) {
      setCurrentBibleLanguage(
        BIBLE_TRANSLATIONS_LANGUAGES[currentTranslation.bible_locale]
      );
    } else {
      setCurrentBibleLanguage(BIBLE_TRANSLATIONS_LANGUAGES[USER_LANGUAGE]);
    }
  }, [currentTranslation]);

  const fetchTranslations = async () => {
    try {
      const response = await axios.get(`${BASE_API_URL}/bible/transl`);
      const translations = response.data;
      setBibleTranslations(
        translations.bibles.map((transl) => {
          return { ...transl, isFavorite: false };
        })
      );
    } catch (error) {
      console.error("Error fetching translations:", error);
    }
  };

  useEffect(() => {
    fetchTranslations();
  }, []);

  const onClickCloseButton = useCallback(() => {
    setActiveScreenMobile("bible_modal_chapter");
  }, []);

  return (
    <BibleTranslationMobileContainer>
      <TranslationPanelHeader
        currentTranslation={currentTranslation}
        onClickCloseButton={onClickCloseButton}
      />

      {currentScreen === "translations" && (
        <ModalWindowTranslations
          setCurrentTranslation={setCurrentTranslation}
          bibleTranslations={bibleTranslations}
          setBibleTranslations={setBibleTranslations}
          translationsLanguages={translationsLanguages}
          setCurrentScreen={setCurrentScreen}
          currentBibleLanguage={currentBibleLanguage}
          translation={currentTranslation}
          setCurrentBibleParams={setCurrentBibleParams}
          setSelectedVerses={setSelectedVerses}
        />
      )}

      {currentScreen === "languages" && (
        <LanguageSelect
          translationsLanguages={translationsLanguages}
          setCurrentScreen={setCurrentScreen}
          currentBibleLanguage={currentBibleLanguage}
          setCurrentBibleLanguage={setCurrentBibleLanguage}
        />
      )}
    </BibleTranslationMobileContainer>
  );
};

export default BibleTranslationMobile;
