import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import axios from "../../../axios_config";
import { BASE_API_URL } from "../../../common/endpoints";
import UsersList from "./users_list";
import UserRolesSettings from "./user_roles_settings";
import ScrollToTopButton from "../../../common/components/scroll_to_top_button";
import { compareObjects } from "../../../common/utils/functions/compare_objects";
import AdminUsersInfoModalWindow from "./admin_users_modal_windows/admin_users_info_modal_window";
import { useNavigate } from "react-router-dom";
import { useUserDataContext } from "../../../user_data_context";

const AdminPageUsersWrapper = styled.div`
  position: relative;
  flex: 1;
`;

const AdminPageUsersContainer = styled.div`
  display: flex;
  flex: 1;
  overflow: auto;
  height: calc(100vh - 80px);

  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    background-color: #fff;
  }
`;

const AdminPageUsers = () => {
  const navigate = useNavigate();
  const adminPageUsersRef = useRef(null);
  const { userData, accessRights } = useUserDataContext();

  const [usersList, setUsersList] = useState([]);
  const [activeSettingsBlock, setActiveSettingsBlock] = useState(""); // user_roles, create_invite
  const [userRolesSettingsData, setUserRolesSettingsData] = useState({});
  const [userRolesSettingsMemoData, setUserRolesSettingsMemoData] = useState(
    {}
  );
  const [openEditUserModal, setOpenEditUserModal] = useState(false);
  const [selectedUserInfo, setSelectedUserInfo] = useState({});

  const hasAccess =
    accessRights.isSuperadmin || accessRights.isAdminOfPlatformUsers;

  useEffect(() => {
    if (userData._id && !hasAccess) {
      navigate("/bible");
    }

    if (userData._id && hasAccess) {
      localStorage.setItem("lastAdminUrl", location.pathname);
    }
  }, [accessRights, userData]);

  const fetchUsers = async () => {
    axios
      .get(BASE_API_URL + "/users")
      .then((response) => {
        setUsersList(response.data);
      })
      .catch((error) => {
        console.error("Ошибка при запросе:", error);
      });
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    if (activeSettingsBlock === "create_invite") {
      setUsersList((prevState) => [
        { ...userRolesSettingsData, _id: "new_invite" },
        ...prevState,
      ]);
    } else {
      setUsersList((prevState) =>
        prevState.filter((user) => user._id !== "new_invite")
      );
    }
  }, [activeSettingsBlock]);

  const closeUserSettings = useCallback(() => {
    setUserRolesSettingsData({});
    setUserRolesSettingsMemoData({});
    setActiveSettingsBlock("");
    setOpenEditUserModal(false);
    fetchUsers();
  }, []);

  const onCloseUserSettings = useCallback(() => {
    if (compareObjects(userRolesSettingsData, userRolesSettingsMemoData)) {
      closeUserSettings();
    } else {
      setOpenEditUserModal(true);
    }
  }, [userRolesSettingsData, userRolesSettingsMemoData]);

  if (!hasAccess) return;

  return (
    <AdminPageUsersWrapper>
      <AdminPageUsersContainer
        ref={adminPageUsersRef}
        onMouseDown={onCloseUserSettings}
      >
        <UsersList
          usersList={usersList}
          activeSettingsBlock={activeSettingsBlock}
          userRolesSettingsData={userRolesSettingsData}
          userRolesSettingsMemoData={userRolesSettingsMemoData}
          setActiveSettingsBlock={setActiveSettingsBlock}
          setUserRolesSettingsData={setUserRolesSettingsData}
          setUserRolesSettingsMemoData={setUserRolesSettingsMemoData}
          setSelectedUserInfo={setSelectedUserInfo}
          setOpenEditUserModal={setOpenEditUserModal}
        />
        <UserRolesSettings
          activeSettingsBlock={activeSettingsBlock}
          userRolesSettingsData={userRolesSettingsData}
          userRolesSettingsMemoData={userRolesSettingsMemoData}
          usersList={usersList}
          setUserRolesSettingsData={setUserRolesSettingsData}
          setUsersList={setUsersList}
          closeUserSettings={closeUserSettings}
          openEditUserModal={openEditUserModal}
          setUserRolesSettingsMemoData={setUserRolesSettingsMemoData}
          setOpenEditUserModal={setOpenEditUserModal}
          setSelectedUserInfo={setSelectedUserInfo}
          fetchUsers={fetchUsers}
        />
      </AdminPageUsersContainer>

      {selectedUserInfo.data?._id && (
        <AdminUsersInfoModalWindow
          selectedUserInfo={selectedUserInfo}
          setSelectedUserInfo={setSelectedUserInfo}
          closeUserSettings={closeUserSettings}
          fetchUsers={fetchUsers}
        />
      )}
      <ScrollToTopButton parentRef={adminPageUsersRef} />
    </AdminPageUsersWrapper>
  );
};

export default memo(AdminPageUsers);
