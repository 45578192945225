import { useState, useEffect } from "react";

const useWindowBreakpoint = (breakpoint) => {
  const [isBelowBreakpoint, setIsBelowBreakpoint] = useState(
    window.innerWidth <= breakpoint
  );

  useEffect(() => {
    const handleResize = () => {
      setIsBelowBreakpoint(window.innerWidth <= breakpoint);
    };

    window.addEventListener("resize", handleResize);

    // Initial check
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, [breakpoint]);

  return isBelowBreakpoint;
};

export default useWindowBreakpoint;
