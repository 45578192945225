import React, { memo, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";
import SearchVerse from "./search_verse";
import VerticalLine from "../../../../common/assets/icons/vertical_line";
import SpinnerLoader from "../../../../common/components/loaders/spinner_loader";
import BibleChapterModalWindow from "../../../../common/components/modal_windows/bible_chapter_modal_window";
import BackToBibleChapterButton from "../../back_to_bible_chapter_button";

const BiblePageSearchResultsContainer = styled.div`
  flex: 1;
  padding: 15px 0 20px 20px;
  height: 100%;
  overflow: hidden;
  border-radius: 15px;
  border: 2px solid #fff;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
`;

const BiblePageSearchInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const BibleAndTestamentContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 0 10px;
  min-width: 215px;
  width: max-content;
  height: 24px;
  min-height: 24px;
  background: #fff;
  border-radius: 15px;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
`;

const BibleSearchAbbreviation = styled.div`
  color: #f8d254;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
`;

const BibleSearchTestament = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 10px;
  line-height: 120%;
  color: #1e1e1e;
  letter-spacing: -0.1px;
`;

const VerticalLineStyled = styled(VerticalLine)`
  height: 13px;
`;

const BiblePageSearchResultCount = styled.div`
  display: flex;
  align-items: center;
  padding-right: 22px;
  font-size: 10px;
  line-height: 140%;
  letter-spacing: -0.1px;
  color: #1e1e1e;
`;

const SearchVersesResults = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;
  padding-right: 17px;
  max-height: calc(100% - 40px);
  overflow: auto;

  ::-webkit-scrollbar {
    width: 4px;
  }
`;

const EmptySearchResult = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  padding: 0 50px 0 40px;
  font-size: 16px;
  line-height: 140%;
  color: #383838;
  text-align: center;
`;

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100% - 40px);
  width: 100%;
`;

const BibleSearchDesktop = ({
  isLoading,
  bibleSearchResults,
  selectedSearchVerse,
  currentTranslation,
  bibleSearchInput,
  setBibleSearchResults,
  setBibleSearchInput,
  setSelectedSearchVerse,
  setActiveScreenDesktop,
}) => {
  const searchVersesResultsRef = useRef(null);
  const [visibleResultsCount, setVisibleResultsCount] = useState(20);
  const [bibleChapterModalData, setBibleChapterModalData] = useState({});

  const totalResults = bibleSearchResults?.searchResults?.length || 0;
  const visibleSearchResults =
    bibleSearchResults?.searchResults?.slice(0, visibleResultsCount) || [];

  const searchTestament =
    bibleSearchResults.searchTestament === "ot"
      ? "Ветхий Завет"
      : bibleSearchResults.searchTestament === "nt"
      ? "Новый Завет"
      : "Вся Библия";

  const bibleId = currentTranslation.bible_code;

  const testamentForEmptyResult =
    bibleSearchResults.searchTestament === "ot"
      ? "Ветхом Завете"
      : bibleSearchResults.searchTestament === "nt"
      ? "Новом Завете"
      : "";

  useEffect(() => {
    return () => {
      setBibleSearchResults({});
      setBibleSearchInput("");
      setSelectedSearchVerse(null);
    };
  }, []);

  useEffect(() => {
    if (searchVersesResultsRef.current) {
      searchVersesResultsRef.current.scrollTop = 0;
    }
  }, [bibleSearchResults.searchResults]);

  useEffect(() => {
    setVisibleResultsCount(20);
  }, [bibleSearchResults.searchResults]);

  useEffect(() => {
    const scrollContainer = searchVersesResultsRef.current;
    const handleScroll = () => {
      if (scrollContainer) {
        const scrolledHeight =
          scrollContainer.scrollTop + scrollContainer.clientHeight;
        const totalHeight = scrollContainer.scrollHeight;
        if (scrolledHeight >= totalHeight) {
          const newVisibleResults = visibleResultsCount + 20;
          if (newVisibleResults <= totalResults) {
            setVisibleResultsCount(newVisibleResults);
          }
        }
      }
    };
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, [visibleResultsCount, totalResults]);

  return (
    <BiblePageSearchResultsContainer>
      {!isLoading && (
        <BiblePageSearchInfoContainer>
          <Wrapper>
            <BackToBibleChapterButton
              setActiveScreenDesktop={setActiveScreenDesktop}
              navigatePath={"bible_chapter"}
            />

            <BibleAndTestamentContainer>
              <BibleSearchAbbreviation>{bibleId}</BibleSearchAbbreviation>
              <VerticalLineStyled />
              <BibleSearchTestament>{searchTestament}</BibleSearchTestament>
            </BibleAndTestamentContainer>
          </Wrapper>

          <BiblePageSearchResultCount>
            Найдено стихов: {bibleSearchResults?.searchResults?.length || 0}
          </BiblePageSearchResultCount>
        </BiblePageSearchInfoContainer>
      )}

      {!isLoading &&
        !bibleSearchResults?.searchResults?.length &&
        bibleSearchInput && (
          <EmptySearchResult>
            <p>
              По запросу{" "}
              <b>«{bibleSearchResults.searchWord || bibleSearchInput}»</b>
              {testamentForEmptyResult && (
                <> в {testamentForEmptyResult}</>
              )}{" "}
              ничего не найдено
            </p>
          </EmptySearchResult>
        )}

      {!isLoading ? (
        <SearchVersesResults ref={searchVersesResultsRef}>
          {visibleSearchResults?.map((verse) => (
            <SearchVerse
              key={verse._id}
              verse={verse}
              bibleSearchWord={bibleSearchResults.searchWord}
              currentTranslation={currentTranslation}
              selectedSearchVerse={selectedSearchVerse}
              setSelectedSearchVerse={setSelectedSearchVerse}
              setBibleChapterModalData={setBibleChapterModalData}
            />
          ))}
        </SearchVersesResults>
      ) : (
        <LoaderContainer>
          <SpinnerLoader />
        </LoaderContainer>
      )}

      {bibleChapterModalData.bibleId &&
        createPortal(
          <BibleChapterModalWindow
            bibleModalData={bibleChapterModalData}
            setOpen={setBibleChapterModalData}
            inComponent="bible_search"
            bibleLocale={currentTranslation.bible_locale}
            setBibleActiveSection={setActiveScreenDesktop}
          />,
          document.body
        )}
    </BiblePageSearchResultsContainer>
  );
};

export default memo(BibleSearchDesktop);
