import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import VerticalLine from "../../../../../../assets/icons/vertical_line";
import SearchVerse from "../../../bible_modal_window_desktop/bible_modal_search_results/search_verse";
import SpinnerLoader from "../../../../../loaders/spinner_loader";

const BiblePageSearchResultsContainer = styled.div`
  height: calc(100% - 52px);
  overflow: hidden;
`;

const BiblePageSearchInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px 12px;
`;

const BibleAndTestamentContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 0 12px;
  min-width: 215px;
  width: max-content;
  height: 24px;
  min-height: 24px;
  background: #fff;
  border-radius: 15px;
`;

const BibleSearchAbbreviation = styled.div`
  color: #f8d254;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
`;

const BibleSearchTestament = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 10px;
  line-height: 120%;
  color: #1e1e1e;
  letter-spacing: -0.1px;
`;

const BiblePageSearchResultCount = styled.div`
  display: flex;
  align-items: center;
  font-size: 10px;
  line-height: 140%;
  letter-spacing: -0.1px;
  color: #1e1e1e;
`;

const SearchVersesResults = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;
  padding: 0 12px 100px;
  height: 100%;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 3px;
  }

  ::-webkit-scrollbar-track {
    background-color: #eaeaea;
  }
`;

const EmptySearchResult = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  padding: 0 50px 0 40px;
  font-size: 16px;
  line-height: 140%;
  color: #383838;
  text-align: center;
`;

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100% - 40px);
  width: 100%;
`;

const VerticalLineStyled = styled(VerticalLine)`
  margin-top: 1px;
`;

const BibleModalSearchResultsMobile = ({
  currentTranslation,
  setCurrentBibleParams,
  setSelectedVerses,
  isLoading,
  bibleSearchTranslate,
  bibleSearchResults,
  bibleSearchInput,
  setActiveScreenMobile,
}) => {
  const searchVersesResultsRef = useRef(null);

  const [visibleResultsCount, setVisibleResultsCount] = useState(20);

  const totalResults = bibleSearchResults?.searchResults?.length || 0;
  const visibleSearchResults =
    bibleSearchResults?.searchResults?.slice(0, visibleResultsCount) || [];

  const searchTestament =
    bibleSearchResults.searchTestament === "ot"
      ? "Ветхий Завет"
      : bibleSearchResults.searchTestament === "nt"
      ? "Новый Завет"
      : "Вся Библия";

  const testamentForEmptyResult =
    bibleSearchResults.searchTestament === "ot"
      ? "Ветхом Завете"
      : bibleSearchResults.searchTestament === "nt"
      ? "Новом Завете"
      : "";

  useEffect(() => {
    if (searchVersesResultsRef.current) {
      searchVersesResultsRef.current.scrollTop = 0;
    }
  }, [bibleSearchResults.searchResults]);

  useEffect(() => {
    setVisibleResultsCount(20);
  }, [bibleSearchResults.searchResults]);

  useEffect(() => {
    const scrollContainer = searchVersesResultsRef.current;
    const handleScroll = () => {
      if (scrollContainer) {
        const scrolledHeight =
          scrollContainer.scrollTop + scrollContainer.clientHeight;
        const totalHeight = scrollContainer.scrollHeight;
        if (scrolledHeight >= totalHeight) {
          const newVisibleResults = visibleResultsCount + 20;
          if (newVisibleResults <= totalResults) {
            setVisibleResultsCount(newVisibleResults);
          }
        }
      }
    };
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, [visibleResultsCount, totalResults]);

  return (
    <BiblePageSearchResultsContainer>
      {!isLoading && (
        <BiblePageSearchInfoContainer>
          <BibleAndTestamentContainer>
            <BibleSearchAbbreviation>
              {bibleSearchTranslate}
            </BibleSearchAbbreviation>
            <VerticalLineStyled height="13px" />
            <BibleSearchTestament>{searchTestament}</BibleSearchTestament>
          </BibleAndTestamentContainer>

          <BiblePageSearchResultCount>
            Найдено стихов: {bibleSearchResults?.searchResults?.length || 0}
          </BiblePageSearchResultCount>
        </BiblePageSearchInfoContainer>
      )}

      {!isLoading && !bibleSearchResults?.searchResults?.length && (
        <EmptySearchResult>
          <p>
            По запросу{" "}
            <b>«{bibleSearchResults.searchWord || bibleSearchInput}»</b>
            {testamentForEmptyResult && <> в {testamentForEmptyResult}</>}{" "}
            ничего не найдено
          </p>
        </EmptySearchResult>
      )}

      {!isLoading ? (
        <SearchVersesResults ref={searchVersesResultsRef}>
          {visibleSearchResults?.map((verse) => (
            <SearchVerse
              key={verse._id}
              verse={verse}
              bibleSearchWord={bibleSearchResults.searchWord.trim()}
              currentTranslation={currentTranslation}
              setActiveScreenDesktop={setActiveScreenMobile}
              setCurrentBibleParams={setCurrentBibleParams}
              setSelectedVerses={setSelectedVerses}
            />
          ))}
        </SearchVersesResults>
      ) : (
        <LoaderContainer>
          <SpinnerLoader />
        </LoaderContainer>
      )}
    </BiblePageSearchResultsContainer>
  );
};

export default BibleModalSearchResultsMobile;
