import React, { useCallback } from "react";
import styled from "styled-components";
import {
  insertAsSeparateVerses,
  insertWholeChapter,
  isCombinedVerses,
} from "../../../../utils/functions/bible_page_functions";
import AsTextVariantIcon from "../../../../assets/icons/bible_modal_window_icons/as_text_variant_icon";
import AsLinkVariantIcon from "../../../../assets/icons/bible_modal_window_icons/as_link_variant_icon";
import CheckIcon from "../../../../assets/icons/bible_modal_window_icons/check_icon";
import CancelIcon from "../../../../assets/icons/bible_modal_window_icons/cancel_icon";

const InsertionVariantButtonsContainer = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 36px;
  display: flex;
  justify-content: center;
  gap: 34px;
  width: max-content;
`;

const VariantButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 4.46154px 4.46154px 7.80769px 0px rgba(0, 0, 0, 0.12);

  svg {
    ${({ enabled }) => !enabled && "opacity: 0.5;"}
  }
`;

const InsertionVariantButtonsMobile = ({
  selectedVerses,
  setSelectedVerses,
  currentTranslation,
  currentBibleParams,
  handleAddBibleVerses,
  handleCloseBibleModalWindow,
  setChatInputValue,
  setEditedVersesData,
  inComponent,
}) => {
  const enabledButtons =
    !!selectedVerses.length || inComponent === "text_editor";

  const insertAsTextInChatInput = useCallback(
    (e) => {
      e.stopPropagation();

      if (selectedVerses.length) {
        const addedVerses = isCombinedVerses(
          selectedVerses,
          currentTranslation?.bible_locale
        );
        const versesAndAddress =
          addedVerses.verseText + " " + `(${addedVerses.verseAddress})`;

        setChatInputValue((prevState) => prevState + versesAndAddress);
        handleCloseBibleModalWindow(e);
        setSelectedVerses([]);
      }
    },
    [selectedVerses]
  );

  const insertAsLinkInChatInput = useCallback(
    (e) => {
      e.stopPropagation();

      if (selectedVerses.length) {
        handleAddBibleVerses(
          isCombinedVerses(selectedVerses, currentTranslation?.bible_locale)
        );
        handleCloseBibleModalWindow(e);
        setSelectedVerses([]);
      }
    },
    [selectedVerses]
  );

  const insertVerseDefault = useCallback(
    (e, currentTranslation) => {
      e.stopPropagation();

      if (
        selectedVerses.length &&
        inComponent !== "create_lesson-bible_settings"
      ) {
        handleAddBibleVerses(
          isCombinedVerses(selectedVerses, currentTranslation?.bible_locale)
        );

        handleCloseBibleModalWindow(e);
        setSelectedVerses([]);
        setEditedVersesData({});
      }

      if (!selectedVerses.length && inComponent === "text_editor") {
        handleAddBibleVerses(
          insertWholeChapter(
            currentBibleParams,
            currentTranslation?.bible_locale
          )
        );
      }

      if (
        selectedVerses.length &&
        inComponent === "create_lesson-bible_settings"
      ) {
        handleAddBibleVerses(
          insertAsSeparateVerses(
            currentBibleParams,
            selectedVerses,
            currentTranslation?.bible_locale
          )
        );

        handleCloseBibleModalWindow(e);
        setSelectedVerses([]);
        setEditedVersesData({});
      }
    },
    [selectedVerses, inComponent, currentBibleParams]
  );

  return (
    <InsertionVariantButtonsContainer onClick={(e) => e.stopPropagation()}>
      {inComponent === "chat_page" ? (
        <>
          <VariantButton
            enabled={enabledButtons}
            onClick={insertAsTextInChatInput}
          >
            <AsTextVariantIcon />
          </VariantButton>
          <VariantButton
            enabled={enabledButtons}
            onClick={insertAsLinkInChatInput}
          >
            <AsLinkVariantIcon />
          </VariantButton>
        </>
      ) : (
        <VariantButton
          enabled={enabledButtons}
          onClick={(e) => insertVerseDefault(e, currentTranslation)}
        >
          <CheckIcon />
        </VariantButton>
      )}

      <VariantButton enabled={true} onClick={handleCloseBibleModalWindow}>
        <CancelIcon />
      </VariantButton>
    </InsertionVariantButtonsContainer>
  );
};

export default InsertionVariantButtonsMobile;
