import React, { memo, useCallback } from "react";
import styled from "styled-components";
import GeneralIcon from "../../common/assets/icons/user_profile_page_icons/general_icon";
import SecurityIcon from "../../common/assets/icons/user_profile_page_icons/security_icon";
import StudyIcon from "../../common/assets/icons/user_profile_page_icons/study_icon";
import QuestionsIcon from "../../common/assets/icons/user_profile_page_icons/questions_icon";

const sidebarItems = [
  {
    icon: GeneralIcon,
    name: "general",
  },
  {
    icon: SecurityIcon,
    name: "security",
  },
  {
    icon: StudyIcon,
    name: "study",
  },
  {
    icon: QuestionsIcon,
    name: "questions",
  },
];

const UserProfilePageContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  height: max-content;
  width: max-content;
  margin: 0 auto;
  padding: 30px 0 30px 30px;
`;

const SidebarButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 54px;
  height: 54px;
  border-radius: 12.273px;
  background: #fff;
  transition: 0.2s;
  cursor: pointer;

  ${({ isActive }) =>
    isActive &&
    `
      transform: scale(1.23);
      border-radius: 15.909px;
      box-shadow: 4.732px 4.732px 8.281px 0px rgba(0, 0, 0, 0.12);
  `}

  :hover {
    box-shadow: 3.65px 3.65px 6.388px 0px rgba(0, 0, 0, 0.12);
  }
`;

const UserProfilePageSidebar = ({
  activeProfileSection,
  setActiveProfileSection,
}) => {
  const handleActiveSection = useCallback((event, section) => {
    event.stopPropagation();
    setActiveProfileSection(section);
  }, []);

  return (
    <UserProfilePageContainer>
      {sidebarItems.map((item) => {
        const Icon = item.icon;

        return (
          <SidebarButton
            key={item.name}
            isActive={activeProfileSection === item.name}
            onClick={(e) => handleActiveSection(e, item.name)}
          >
            <Icon />
          </SidebarButton>
        );
      })}
    </UserProfilePageContainer>
  );
};

export default memo(UserProfilePageSidebar);
