import React, { useState, useRef } from "react";
import { access_token } from "./config"; // Импорт токена
import {
  createVideo,
  uploadVideo,
  completeUpload,
  getVideoInfo,
} from "./utils"; // Импорт утилит
import styled from "styled-components";

const VimeoVideoUploaderContainer = styled.div`
  padding: 30px;
`;

const ProgressBar = styled.div`
  width: 100%;
  background-color: #f3f3f3;
  margin-top: 10px;
`;

const Progress = styled.div`
  width: ${(props) => props.width}%;
  height: 20px;
  background-color: #4caf50;
`;

const VimeoVideoUploader = () => {
  const [status, setStatus] = useState("");
  const [progress, setProgress] = useState(0);
  const [videoUrl, setVideoUrl] = useState("");
  const fileInputRef = useRef(null);

  const checkVideoStatus = async (videoUri) => {
    setStatus("Проверяем статус видео...");
    try {
      const videoData = await getVideoInfo(videoUri, access_token);

      if (videoData.status === "available") {
        setStatus("Видео доступно для просмотра!");
        setVideoUrl(
          `https://player.vimeo.com/video/${videoData.uri.split("/").pop()}`
        );
        return true; // Видео доступно, прекращаем проверки
      }

      setStatus("Видео всё ещё обрабатывается. Проверяем снова...");
      return false; // Видео пока недоступно
    } catch (error) {
      setStatus(`Ошибка при проверке статуса: ${error.message}`);
      return false; // Продолжаем попытки
    }
  };

  const pollVideoStatus = (videoUri, interval = 30000, maxAttempts = 10) => {
    let attempts = 0;

    const intervalId = setInterval(async () => {
      const isAvailable = await checkVideoStatus(videoUri);

      if (isAvailable || attempts >= maxAttempts) {
        clearInterval(intervalId); // Останавливаем проверку
        if (attempts >= maxAttempts) {
          setStatus("Видео не стало доступным после нескольких попыток.");
        }
      }
      attempts++;
    }, interval);
  };

  const handleUpload = async () => {
    const file = fileInputRef.current.files[0];
    if (!file) {
      setStatus("Пожалуйста, выберите видеофайл.");
      return;
    }

    try {
      // Шаг 1: Создание видео
      setStatus("Создание видео...");
      const createData = await createVideo(access_token, file);
      const uploadLink = createData.upload.upload_link;
      const videoUri = createData.uri;

      // Шаг 2: Загрузка видео
      setStatus("Загрузка видео...");
      await uploadVideo(uploadLink, access_token, file, (progress) => {
        setProgress(progress);
      });

      // Шаг 3: Завершение загрузки
      setStatus("Завершение загрузки...");
      await completeUpload(uploadLink, access_token, file);

      // Шаг 4: Проверка доступности видео
      setStatus("Загрузка завершена. Проверяем доступность видео...");
      pollVideoStatus(videoUri); // Начинаем периодическую проверку
    } catch (error) {
      setStatus(`Ошибка: ${error.message}`);
    }
  };

  return (
    <VimeoVideoUploaderContainer>
      <h1>Загрузка видео на Vimeo</h1>
      <input type="file" ref={fileInputRef} accept="video/*" />
      <button onClick={handleUpload}>Загрузить видео</button>
      <div>{status}</div>
      {progress > 0 && progress < 100 && (
        <ProgressBar>
          <Progress width={progress} />
        </ProgressBar>
      )}
      {videoUrl && (
        <div style={{ marginTop: 20 }}>
          <iframe
            src={videoUrl}
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            style={{ width: "100%", height: "360px" }}
            title="Vimeo Player"
          ></iframe>
        </div>
      )}
    </VimeoVideoUploaderContainer>
  );
};

export default VimeoVideoUploader;
