import React, { memo, useCallback, useEffect, useRef } from "react";
import styled from "styled-components";
import ChaptersNavigation from "./chapters_navigation";

const BibleBookTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 140%;
  margin-bottom: 4px;
  cursor: pointer;
  transition: 0.3s padding;
  user-select: none;
  color: ${({ isExpanded }) => (isExpanded ? "black" : "gray")};
  padding-top: ${({ isExpanded }) => isExpanded && "1px"};

  &:hover {
    padding-left: 5px;
  }

  @media (max-width: 744px) {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0.4px;
  }
`;

const BookNavigation = ({
  book,
  setIsActive,
  isExpanded,
  scrollContainerRef,
  setSelectedVerses,
  currentBibleParams,
  setCurrentBibleParams,
}) => {
  const bookRef = useRef(null);

  useEffect(() => {
    if (isExpanded && bookRef.current && scrollContainerRef?.current) {
      const scrollContainer = scrollContainerRef?.current;
      const bookElement = bookRef.current;

      const bookTopPosition = bookElement.getBoundingClientRect().top;
      const containerTopPosition = scrollContainer.getBoundingClientRect().top;
      const scrollPosition =
        bookTopPosition - containerTopPosition + scrollContainer.scrollTop - 6;

      scrollContainer.scrollTo({
        top: scrollPosition,
      });
    }
  }, [isExpanded, bookRef, scrollContainerRef]);

  const handleClickOnBookTitle = useCallback(() => {
    if (isExpanded) {
      setIsActive(null);
    } else setIsActive(book.book_code);
  }, [isExpanded, book]);

  return (
    <div>
      <BibleBookTitle
        ref={bookRef}
        isExpanded={isExpanded}
        onClick={handleClickOnBookTitle}
      >
        {book.book_name}
      </BibleBookTitle>
      <ChaptersNavigation
        isExpanded={isExpanded}
        book={book}
        setSelectedVerses={setSelectedVerses}
        currentBibleParams={currentBibleParams}
        setCurrentBibleParams={setCurrentBibleParams}
      />
    </div>
  );
};

export default memo(BookNavigation);
