import React, { memo, useCallback, useRef } from "react";
import styled from "styled-components";
import SearchSettingsLineIcon from "../../../../../common/assets/icons/bible_page_icons/search_settings_line_Icon";
import Checkbox from "../../../../../common/components/checkbox";

const BibleSearchSettingsWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background: #fff;
  height: 180px;
  width: 100%;
  z-index: 99999;
  border-radius: 20px 20px 0px 0px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
`;

const BibleSearchSettingsContainer = styled.div`
  max-width: 440px;
  padding: 0 23px 0;
  margin: 0 auto;
`;

const HorizontalLineContainer = styled.div`
  width: max-content;
  margin: 0 auto;
`;

const SearchCheckboxContainer = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
  margin: 16px 0 16px;
`;

const TestamentButtonsContainer = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
  margin-top: 8px;
`;

const TestamentButton = styled.button`
  flex: 1;
  height: 32px;
  border-radius: 15px;
  border: 1px solid #5172ea;
  opacity: 0.5;
  background: #fff;
  color: #1e1e1e;
  font-size: 10px;
  font-weight: 600;
  line-height: 16px;
  transition: 0.2s;

  ${({ isSelected }) =>
    isSelected &&
    `
      opacity: 1;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  `}
`;

const SearchWholeBibleButton = styled(TestamentButton)`
  width: 100%;
`;

const BibleSearchSettingsMobile = ({
  searchWholeWord,
  searchWithRegister,
  setWholeWordCheckbox,
  setWithRegisterCheckbox,
  bibleSearchPlace,
  setBibleSearchPlace,
  setShowBibleSearchSettings,
}) => {
  const touchStartY = useRef(0);
  const touchEndY = useRef(0);

  const handleTouchStart = (e) => {
    touchStartY.current = e.touches[0].clientY;
  };

  const handleTouchEnd = (e) => {
    touchEndY.current = e.changedTouches[0].clientY;
    if (touchEndY.current - touchStartY.current > 50) {
      setShowBibleSearchSettings(false);
    }
  };

  const handleWholeWordCheckbox = useCallback((event) => {
    event.stopPropagation();
    setWholeWordCheckbox((prevState) => !prevState);
  }, []);

  const handleWithRegisterCheckbox = useCallback((event) => {
    event.stopPropagation();
    setWithRegisterCheckbox((prevState) => !prevState);
  }, []);

  const handleTestaments = useCallback((part) => {
    setBibleSearchPlace(part);
  }, []);

  return (
    <BibleSearchSettingsWrapper
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
    >
      <BibleSearchSettingsContainer>
        <HorizontalLineContainer>
          <SearchSettingsLineIcon />
        </HorizontalLineContainer>

        <SearchCheckboxContainer>
          <Checkbox
            checked={searchWholeWord}
            onClickCheckbox={handleWholeWordCheckbox}
            color={searchWholeWord ? "#1e1e1e" : "#828282"}
            fontSize={"12px"}
            letterSpacing={"0.4px"}
            size={"24px"}
          >
            Целое слово
          </Checkbox>

          <Checkbox
            checked={searchWithRegister}
            onClickCheckbox={handleWithRegisterCheckbox}
            color={searchWithRegister ? "#1e1e1e" : "#828282"}
            fontSize={"12px"}
            letterSpacing={"0.4px"}
            size={"24px"}
          >
            Учитывать регистр
          </Checkbox>
        </SearchCheckboxContainer>

        <SearchWholeBibleButton
          isSelected={bibleSearchPlace === ""}
          onClick={() => handleTestaments("")}
        >
          Вся Библия
        </SearchWholeBibleButton>
        <TestamentButtonsContainer>
          <TestamentButton
            isSelected={bibleSearchPlace === "ot"}
            onClick={() => handleTestaments("ot")}
          >
            Ветхий Завет
          </TestamentButton>
          <TestamentButton
            isSelected={bibleSearchPlace === "nt"}
            onClick={() => handleTestaments("nt")}
          >
            Новый Завет
          </TestamentButton>
        </TestamentButtonsContainer>
      </BibleSearchSettingsContainer>
    </BibleSearchSettingsWrapper>
  );
};

export default memo(BibleSearchSettingsMobile);
