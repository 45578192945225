import React, { useCallback, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import axios from "axios";
import styled from "styled-components";
import BibleSearchSettingsMobile from "../../../../../../pages/bible/bible_page_mobile/bible_chapter_mobile/bible_search_mobile/bible_search_settings_mobile";
import BibleSearchHeaderMobile from "../../../../../../pages/bible/bible_page_mobile/bible_chapter_mobile/bible_search_mobile/bible_search_header_mobile";
import BibleModalSearchResultsMobile from "./bible_modal_search_results_mobile";

const BibleModalSearchMobileContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #eaeaea;
`;

const BibleModalSearchMobile = ({
  bibleModalSearchPlace,
  setBibleModalSearchPlace,
  bibleModalSearchInput,
  setBibleModalSearchInput,
  bibleModalSearchResults,
  setBibleModalSearchResults,
  searchIsLoading,
  setSearchIsLoading,
  setActiveScreenMobile,
  currentTranslation,
  activeScreenMobile,
  setCurrentBibleParams,
  setSelectedVerses,
}) => {
  const [inputIsEmpty, setInputIsEmpty] = useState(false);
  const [showedInputIcon, setShowedInputIcon] = useState("search_icon"); //search_icon, remove_icon
  const [searchWholeWord, setWholeWordCheckbox] = useState(false);
  const [searchWithRegister, setWithRegisterCheckbox] = useState(false);
  const [showBibleSearchResults, setShowBibleSearchResults] = useState(false);
  const [showBibleSearchSettings, setShowBibleSearchSettings] = useState(false);

  const bibleSearchTranslate = currentTranslation.bible_code;

  const onSearchSubmit = useCallback(
    async (event) => {
      event.preventDefault();

      if (bibleModalSearchInput.trim().length > 1) {
        setSearchIsLoading(true);
        try {
          const response = await axios.post(
            "https://api.on-bible.com/bible/verses/lookup",
            {
              bible_code: bibleSearchTranslate,
              search_value: bibleModalSearchInput,
              search_option_place: bibleModalSearchPlace,
              search_option_whole_word: searchWholeWord,
              search_option_with_register: searchWithRegister,
            }
          );
          setBibleModalSearchResults({
            searchBible: bibleSearchTranslate,
            searchTestament: bibleModalSearchPlace,
            searchWord: bibleModalSearchInput,
            searchResults: response.data.results,
          });
        } catch (error) {
          console.error("Ошибка при отправке запроса", error);
        } finally {
          setShowedInputIcon("remove_icon");
          setSearchIsLoading(false);
          setShowBibleSearchResults(true);
        }
      } else {
        setInputIsEmpty(true);

        setTimeout(() => {
          setInputIsEmpty(false);
        }, 2000);
      }
    },
    [
      bibleSearchTranslate,
      bibleModalSearchInput,
      bibleModalSearchPlace,
      searchWholeWord,
      searchWithRegister,
      setBibleModalSearchResults,
    ]
  );

  const onClearSearchSettings = useCallback(() => {
    setBibleModalSearchResults({});
    setBibleModalSearchInput("");
    setBibleModalSearchPlace("");
    setShowBibleSearchResults(false);
  }, []);

  useEffect(() => {
    if (
      bibleModalSearchInput.trim().length > 1 &&
      activeScreenMobile === "bible_modal_search"
    ) {
      const performSearch = async () => {
        const fakeEvent = { preventDefault: () => {} };
        await onSearchSubmit(fakeEvent);
      };
      performSearch();
    }
  }, [bibleModalSearchPlace, searchWholeWord, searchWithRegister]);

  useEffect(() => {
    return () => {
      setWholeWordCheckbox(false);
      setWithRegisterCheckbox(false);
      setShowedInputIcon("search_icon");
      setBibleModalSearchPlace("");
    };
  }, [activeScreenMobile]);

  useEffect(() => {
    setShowedInputIcon("search_icon");
  }, [bibleModalSearchInput]);

  return (
    <BibleModalSearchMobileContainer>
      <BibleSearchHeaderMobile
        bibleSearchInput={bibleModalSearchInput}
        inputIsEmpty={inputIsEmpty}
        showedInputIcon={showedInputIcon}
        setBibleSearchInput={setBibleModalSearchInput}
        setShowedInputIcon={setShowedInputIcon}
        setActiveScreenMobile={setActiveScreenMobile}
        setShowBibleSearchSettings={setShowBibleSearchSettings}
        setBibleSearchPlace={setBibleModalSearchPlace}
        onSearchSubmit={onSearchSubmit}
        onClearSearchSettings={onClearSearchSettings}
        backScreen={"bible_modal_chapter"}
      />

      {showBibleSearchResults && (
        <BibleModalSearchResultsMobile
          isLoading={searchIsLoading}
          bibleSearchResults={bibleModalSearchResults}
          currentTranslation={currentTranslation}
          bibleSearchInput={bibleModalSearchInput}
          bibleSearchTranslate={bibleSearchTranslate}
          bibleSearchWord={bibleModalSearchResults.searchWord.trim()}
          setActiveScreenMobile={setActiveScreenMobile}
          setCurrentBibleParams={setCurrentBibleParams}
          setSelectedVerses={setSelectedVerses}
        />
      )}

      {showBibleSearchSettings &&
        createPortal(
          <BibleSearchSettingsMobile
            bibleSearchPlace={bibleModalSearchPlace}
            searchWholeWord={searchWholeWord}
            searchWithRegister={searchWithRegister}
            setWholeWordCheckbox={setWholeWordCheckbox}
            setWithRegisterCheckbox={setWithRegisterCheckbox}
            setBibleSearchPlace={setBibleModalSearchPlace}
            setShowBibleSearchSettings={setShowBibleSearchSettings}
          />,
          document.body
        )}
    </BibleModalSearchMobileContainer>
  );
};

export default BibleModalSearchMobile;
