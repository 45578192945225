import React, { memo, useCallback } from "react";
import styled from "styled-components";
import { useBibleChapterContext } from "./bible_verses_heights_context";
import { useAppContext } from "../../../../app_context";
import { useBiblePageContext } from "../../bible_page_context";
import useWindowBreakpoint from "../../../../common/hooks/use_window_breakpoint";

const StrongsCodeComponentContainer = styled.div`
  display: ${({ showStrongsCode }) =>
    showStrongsCode ? "inline-block" : "none"};
  margin: 0 3px 0 1px;
  color: #5172ea;
  font-size: ${({ fontSize }) => fontSize}px;
  line-height: 110%;
  vertical-align: top;

  @media (max-width: 744px) {
    vertical-align: baseline;
  }
`;

const StrongsCodeComponent = ({ children }) => {
  const { bibleFontSettings } = useAppContext();
  const { showStrongsCode, setStrongsCodeModalData, currentTestament } =
    useBiblePageContext();

  const isMobile = useWindowBreakpoint(744);
  const codeFontSize = !isMobile
    ? bibleFontSettings.fontSize * 0.88
    : bibleFontSettings.fontSize;

  const onClickStrongsCode = useCallback((e) => {
    e.stopPropagation();
    setStrongsCodeModalData(children);
  }, []);

  return (
    <StrongsCodeComponentContainer
      showStrongsCode={showStrongsCode}
      onClick={onClickStrongsCode}
      fontSize={codeFontSize}
    >
      {currentTestament === "ot" ? "H" : "G"}
      {children}
    </StrongsCodeComponentContainer>
  );
};

export default memo(StrongsCodeComponent);
