import React, { memo, useCallback } from "react";
import styled from "styled-components";
import BibleChapterArrowIcon from "../../../../assets/icons/bible_page_icons/bible_chapter_arrow_icon";

const ArrowButtonWrapper = styled.div`
  position: absolute;
  bottom: 20px;
  width: max-content;
  padding: 14px 16px;
  border-radius: 25px;
`;

const ArrowRightButtonWrapper = styled(ArrowButtonWrapper)`
  right: 1px;
`;

const ArrowLeftButtonWrapper = styled(ArrowButtonWrapper)`
  left: 1px;
`;

const BibleChapterArrowButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 26px;
  width: 26px;
  border-radius: 50%;
  background: #fff;
  cursor: pointer;
  padding: 0 2px 0 0;
  transition: 0.2s;

  &:active {
    box-shadow: rgba(50, 50, 93, 0.35) 0px 4px 10px -2px,
      rgba(0, 0, 0, 0.4) 0px 2px 6px -2px;
  }
`;

const BibleChapterArrowRightButton = styled(BibleChapterArrowButton)`
  padding: 0 0 0 1px;
`;

const BibleChapterArrowRightIcon = styled(BibleChapterArrowIcon)`
  transform: rotate(180deg);
`;

const BibleModalChapterArrows = ({
  currentBibleParams,
  setCurrentBibleParams,
  currentTranslationData,
}) => {
  const currentChapterNum = currentBibleParams.chapterId;
  const currentBookCode = currentBibleParams.bookId;
  const currentBookIndex = currentTranslationData?.findIndex(
    (book) => book.book_code === currentBookCode
  );
  const currentBook = currentTranslationData?.[currentBookIndex];

  const isFirstBook = currentBookIndex === 0;
  const isFirstChapter = currentChapterNum === 1;

  const isLastBook = currentBookIndex === currentTranslationData?.length - 1;
  const isLastChapter = currentChapterNum === currentBook?.book_chapters;

  const updateChapterAndBook = (newBookCode, newChapterNum) => {
    setCurrentBibleParams((prevState) => ({
      ...prevState,
      bookId: newBookCode,
      chapterId: newChapterNum,
    }));
  };

  const onClickLeftArrow = useCallback(
    (event) => {
      event.stopPropagation();

      if (currentChapterNum > 1) {
        updateChapterAndBook(currentBookCode, currentChapterNum - 1);
      } else if (currentBookIndex > 0) {
        const prevBook = currentTranslationData[currentBookIndex - 1];
        updateChapterAndBook(prevBook.book_code, prevBook.book_chapters);
      }
    },
    [
      setCurrentBibleParams,
      currentTranslationData,
      currentChapterNum,
      currentBookCode,
      currentBookIndex,
    ]
  );

  const onClickRightArrow = useCallback(
    (event) => {
      event.stopPropagation();

      if (currentChapterNum < currentBook?.book_chapters) {
        updateChapterAndBook(currentBookCode, currentChapterNum + 1);
      } else if (currentBookIndex < currentTranslationData.length - 1) {
        const nextBook = currentTranslationData[currentBookIndex + 1];
        updateChapterAndBook(nextBook.book_code, 1);
      }
    },
    [
      setCurrentBibleParams,
      currentTranslationData,
      currentChapterNum,
      currentBook,
      currentBookCode,
      currentBookIndex,
    ]
  );

  return (
    <>
      {!(isFirstBook && isFirstChapter) && (
        <ArrowLeftButtonWrapper onClick={onClickLeftArrow}>
          <BibleChapterArrowButton>
            <BibleChapterArrowIcon />
          </BibleChapterArrowButton>
        </ArrowLeftButtonWrapper>
      )}

      {!(isLastBook && isLastChapter) && (
        <ArrowRightButtonWrapper onClick={onClickRightArrow}>
          <BibleChapterArrowRightButton>
            <BibleChapterArrowRightIcon />
          </BibleChapterArrowRightButton>
        </ArrowRightButtonWrapper>
      )}
    </>
  );
};

export default memo(BibleModalChapterArrows);
