import React from "react";
import {
  QuestionsFilterOption,
  QuestionsFilterOptionArrow,
} from "../../../question_styled_components";
import BlackArrowDownIcon from "../../../../../../common/assets/icons/admin_page_icons/black_arrow_down_icon";
import BiblePlaceDropdown from "./bible_place_dropdown";

const QuestionsFilterByBible = ({
  extendedOption,
  handleExtendOptions,
  setQuestionsFilterSettings,
}) => {
  return (
    <QuestionsFilterOption
      isExtended={extendedOption === "bible"}
      onClick={(e) => handleExtendOptions(e, "bible")}
    >
      Библия
      <QuestionsFilterOptionArrow isExtended={extendedOption === "bible"}>
        <BlackArrowDownIcon />
      </QuestionsFilterOptionArrow>
      {extendedOption === "bible" && (
        <BiblePlaceDropdown
          handleExtendOptions={handleExtendOptions}
          setQuestionsFilterSettings={setQuestionsFilterSettings}
        />
      )}
    </QuestionsFilterOption>
  );
};

export default QuestionsFilterByBible;
