import React, { memo, useCallback } from "react";
import styled from "styled-components";
import { ArrowIcon } from "../../../../../common/assets/icons";

const TranslationPanelInputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 30px;
  padding: 0 3px 0 14px;
  border-top: 0.5px solid #cacaca;
  border-bottom: 0.5px solid #cacaca;

  @media (max-width: 744px) {
    height: 48px;
  }
`;

const TranslationPanelInputStyled = styled.input`
  width: 80%;
  font-size: 12px;
  letter-spacing: -0.14px;

  @media (max-width: 744px) {
    font-size: 14px;
  }
`;

const TranslationPanelInputButton = styled.div`
  cursor: pointer;
  padding: 5px 11px 5px 9px;
  transition: 0.3s;

  &:hover {
    div,
    svg {
      transform: scale(1.09) !important;
      transition: transform 0.3s;
    }
  }
`;

const ArrowIconStyled = styled(ArrowIcon)`
  @media (max-width: 744px) {
    width: 20px;
    height: 20px;
  }
`;

const LanguagesButton = styled.div`
  color: #f8d254;
  font-size: 12px;
  font-weight: 600;
  line-height: 115%;
  letter-spacing: 0.4px;
  text-transform: uppercase;

  @media (max-width: 744px) {
    font-size: 20px;
    text-transform: capitalize;
  }
`;

const TranslationPanelInput = ({
  placeholder,
  section,
  onClickButton,
  inputSearchValue,
  setInputSearchValue,
  currentBibleLanguage = "",
}) => {
  const handleInputValue = useCallback((e) => {
    setInputSearchValue(e.target.value);
  }, []);

  return (
    <TranslationPanelInputContainer>
      <TranslationPanelInputStyled
        placeholder={placeholder}
        value={inputSearchValue}
        onChange={handleInputValue}
      />

      <TranslationPanelInputButton onClick={onClickButton}>
        {section === "translation" ? (
          <LanguagesButton>
            {currentBibleLanguage?.abbreviation}
          </LanguagesButton>
        ) : (
          <ArrowIconStyled fill={"#F8D254"} />
        )}
      </TranslationPanelInputButton>
    </TranslationPanelInputContainer>
  );
};

export default memo(TranslationPanelInput);
