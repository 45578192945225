import React, { memo, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import VisibilityWrapperComponent from "../../common/components/lesson/visibility_wrapper_component";
import SpinnerLoader from "../../common/components/loaders/spinner_loader";
import { LESSON_COMPONENTS_MAPPING } from "../../common/components/lesson/lesson_utils";
import { handleBibleVerseClick } from "../../common/components/text_editor/text_editor_functions";
import FinishLessonButton from "./finish_lesson_button";

const LessonComponentList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 45px;
  padding: 80px 15px 45px;
  max-width: 620px;
  margin: 0 auto;
`;

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 85%;
  width: 100%;
`;

const UserLessonComponentsList = ({
  userLessonPageRef,
  isLoading,
  filteredComponents,
  appColorTheme,
  courseMainColor,
  courseMainGradient,
  lessonIsFinished,
  userAnswersData,
  setLessonData,
  setUserAnswersData,
  setTextBibleModalData,
  shouldShowFinishButton,
  userLessonInfo,
  setLessonIsFinished,
  setAfterFinishLessonModal,
}) => {
  const containerRef = useRef(null);
  const [textEditorComponentHtml, setTextEditorComponentHtml] = useState(null);

  useEffect(() => {
    const container = containerRef.current;
    const bibleVerseElements = container?.querySelectorAll(".bible-verse");
    const handleClick = (event) =>
      handleBibleVerseClick(event, setTextBibleModalData);

    bibleVerseElements?.forEach((element) => {
      element.addEventListener("click", handleClick);
    });

    return () => {
      bibleVerseElements?.forEach((element) => {
        element.removeEventListener("click", handleClick);
      });
    };
  }, [textEditorComponentHtml]);

  return (
    <>
      {!isLoading ? (
        <LessonComponentList ref={containerRef}>
          {filteredComponents?.map((component) => {
            const CurrentLessonComponent =
              LESSON_COMPONENTS_MAPPING?.[component?.type]?.component;

            return (
              <VisibilityWrapperComponent
                key={component.id}
                withAnimation={true}
              >
                <CurrentLessonComponent
                  userLessonPageRef={userLessonPageRef}
                  componentData={component}
                  withAnimation={true}
                  lessonIsFinished={lessonIsFinished}
                  setLessonData={setLessonData}
                  setTextEditorComponentHtml={setTextEditorComponentHtml}
                  setTextBibleModalData={setTextBibleModalData}
                  inComponent="user_lesson"
                  colorTheme={appColorTheme}
                  courseMainColor={courseMainColor}
                  courseMainGradient={courseMainGradient}
                  userAnswersData={userAnswersData}
                  setUserAnswersData={setUserAnswersData}
                />
              </VisibilityWrapperComponent>
            );
          })}

          {shouldShowFinishButton && !!courseMainGradient && (
            <FinishLessonButton
              userLessonInfo={userLessonInfo}
              userAnswersData={userAnswersData}
              courseMainGradient={courseMainGradient}
              filteredComponents={filteredComponents}
              setLessonIsFinished={setLessonIsFinished}
              setAfterFinishLessonModal={setAfterFinishLessonModal}
            />
          )}
        </LessonComponentList>
      ) : (
        <LoaderContainer>
          <SpinnerLoader />
        </LoaderContainer>
      )}
    </>
  );
};

export default memo(UserLessonComponentsList);
