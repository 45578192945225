import React, { memo, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { useSearchParams } from "react-router-dom";
import BookListNavigation from "./book_list_navigation";
import BibleTranslationsPanel from "./bible_translations_panel";
import BibleSearchAndFontSettings from "./bible_search_and_font_settings";
import BibleSearchPlaceButtons from "./bible_search_place_buttons";

const BibleNavigationPanelContainer = styled.div`
  min-width: 236px;
  max-width: 236px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: 100%;
`;

const BibleNavigationPanelDesktop = ({
  bibleTranslations,
  bibleSearchPlace,
  bibleSearchInput,
  activeScreenDesktop,
  currentTranslationFirst,
  currentTranslationSecond,
  setCurrentTranslationFirst,
  setCurrentTranslationSecond,
  setBibleTranslations,
  setActiveScreenDesktop,
  setBibleSearchPlace,
  setBibleSearchResults,
  setBibleSearchInput,
  setIsLoading,
}) => {
  const [searchParams] = useSearchParams();

  const [expandedSectionName, setExpandedSectionName] = useState(""); // FONT_SETTINGS, TRANSLATE_1, TRANSLATE_2
  const [secondTranslateIsEnabled, setSecondTranslateIsEnabled] =
    useState(false);

  const handleExpandedStateSection = useCallback((sectionName) => {
    setExpandedSectionName((prev) => {
      if (sectionName === prev) return "";
      else return sectionName;
    });
  }, []);

  useEffect(() => {
    if (searchParams.get("2bibleId")) {
      setSecondTranslateIsEnabled(true);
    } else setSecondTranslateIsEnabled(false);
  }, [searchParams.get("2bibleId")]);

  return (
    <BibleNavigationPanelContainer>
      {activeScreenDesktop !== "bible_compare" && (
        <BibleSearchAndFontSettings
          activeScreenDesktop={activeScreenDesktop}
          bibleSearchInput={bibleSearchInput}
          bibleSearchPlace={bibleSearchPlace}
          expandedSectionName={expandedSectionName}
          setActiveScreenDesktop={setActiveScreenDesktop}
          setBibleSearchResults={setBibleSearchResults}
          setBibleSearchInput={setBibleSearchInput}
          setExpandedSectionName={setExpandedSectionName}
          setIsLoading={setIsLoading}
          toggleOpenFontSettings={() =>
            handleExpandedStateSection("FONT_SETTINGS")
          }
        />
      )}
      {activeScreenDesktop !== "bible_compare" && (
        <>
          <BibleTranslationsPanel
            activeScreenDesktop={activeScreenDesktop}
            bibleTranslations={bibleTranslations}
            setBibleTranslations={setBibleTranslations}
            currentTranslation={currentTranslationFirst}
            setCurrentTranslation={setCurrentTranslationFirst}
            isExpanded={expandedSectionName === "TRANSLATE_1"}
            setSecondTranslateIsEnabled={setSecondTranslateIsEnabled}
            setExpandedSectionName={setExpandedSectionName}
            toggleOpenBibleTranslationsPanel={() =>
              handleExpandedStateSection("TRANSLATE_1")
            }
            isSecond={false}
          />

          {secondTranslateIsEnabled && (
            <BibleTranslationsPanel
              activeScreenDesktop={activeScreenDesktop}
              isExpanded={expandedSectionName === "TRANSLATE_2"}
              toggleOpenBibleTranslationsPanel={() =>
                handleExpandedStateSection("TRANSLATE_2")
              }
              bibleTranslations={bibleTranslations}
              currentTranslation={currentTranslationSecond}
              isSecond={true}
              setSecondTranslateIsEnabled={setSecondTranslateIsEnabled}
              setBibleTranslations={setBibleTranslations}
              setCurrentTranslation={setCurrentTranslationSecond}
            />
          )}
        </>
      )}

      {activeScreenDesktop === "bible_chapter" && (
        <BookListNavigation
          currentTranslation={currentTranslationFirst}
          activeScreenDesktop={activeScreenDesktop}
          expandedSectionName={expandedSectionName}
        />
      )}

      {activeScreenDesktop === "bible_search" && (
        <BibleSearchPlaceButtons
          bibleSearchPlace={bibleSearchPlace}
          setBibleSearchPlace={setBibleSearchPlace}
        />
      )}
    </BibleNavigationPanelContainer>
  );
};

export default memo(BibleNavigationPanelDesktop);
