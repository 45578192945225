import React, { memo, useState } from "react";
import styled from "styled-components";
import { useUserDataContext } from "../../user_data_context";
import UserProfilePageSidebar from "./user_profile_page_sidebar";
import UserProfileGeneral from "./user_profile_general";

const UserProfilePageContainer = styled.div`
  max-width: 1280px;
  height: 100%;
  margin: 0 auto;
`;

const UserProfilePage = () => {
  const { fetchUserData, userData } = useUserDataContext();

  const [activeProfileSection, setActiveProfileSection] = useState("general"); // general, security, study, questions

  return (
    <UserProfilePageContainer>
      {/* <UserProfilePageSidebar
        activeProfileSection={activeProfileSection}
        setActiveProfileSection={setActiveProfileSection}
      />

      {activeProfileSection === "general" && (
        <UserProfileGeneral userData={userData} />
      )} */}
    </UserProfilePageContainer>
  );
};

export default memo(UserProfilePage);
