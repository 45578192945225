import React, { memo, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "../../../../axios_config";
import styled from "styled-components";
import { BASE_API_URL } from "../../../../common/endpoints";
import BibleChapterModalWindow from "../../../../common/components/modal_windows/bible_chapter_modal_window";
import LessonPreviewPageHeader from "./lesson_preview_page_header";
import { ToastNotification } from "../../../../common/components/toast_notification";
import { LESSON_COLORS_THEME } from "../../../../common/utils/enums";
import FinishLessonButton from "../../../user_lesson/finish_lesson_button";
import LessonComponentsList from "./lesson_components_list";
import { createPortal } from "react-dom";
import BibleModalWindow from "../../../../common/components/modal_windows/bible_modal_window";

const LessonPreviewPageContainer = styled.div`
  position: relative;
  max-width: 100%;
  height: calc(100vh - 80px);
  overflow: auto;
  ${({ darkTheme }) => darkTheme && "background: #232323;"}

  @media (max-width: 1024px) {
    height: calc(100vh - 96px);
  }

  @media (max-width: 412px) {
    height: calc(100vh - 103px);

    ::-webkit-scrollbar {
      width: 3px;
    }
  }
`;

const LessonPreviewPage = () => {
  const { id } = useParams();

  const [lessonData, setLessonData] = useState({});
  const [courseData, setCourseData] = useState({});
  const [textBibleModalData, setTextBibleModalData] = useState({});
  const [withAnimation, setWithAnimation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [shouldShowFinishButton, setShouldShowFinishButton] = useState(false);
  const [stopDialogueIndex, setStopDialogueIndex] = useState(null);
  const [filteredComponents, setFilteredComponents] = useState([]);
  const [colorTheme, setColorTheme] = useState("LIGHT");
  const [openBibleModalWindow, setOpenBibleModalWindow] = useState(false);
  const [bibleVersesData, setBibleVersesData] = useState({});

  const courseMainGradient =
    LESSON_COLORS_THEME[colorTheme]?.GRADIENTS?.[courseData.course_main_color];
  const courseMainColor =
    LESSON_COLORS_THEME[colorTheme]?.COLORS?.[courseData.course_main_color];

  const fetchCourse = useCallback(async (courseId) => {
    try {
      const response = await axios.get(`${BASE_API_URL}/courses/${courseId}`);
      setCourseData(response.data);
    } catch (error) {
      console.error("Ошибка при запросе:", error);
    }
  }, []);

  useEffect(() => {
    if (lessonData?.lesson_course?._id) {
      const courseId = lessonData.lesson_course._id;
      fetchCourse(courseId);
    }
  }, [lessonData?.lesson_course?._id]);

  useEffect(() => {
    setShouldShowFinishButton(
      lessonData.lesson_data?.length === filteredComponents?.length &&
        !isLoading
    );
  }, [lessonData.lesson_data?.length, filteredComponents?.length, isLoading]);

  useEffect(() => {
    if (stopDialogueIndex === null || !withAnimation) {
      setFilteredComponents(lessonData.lesson_data);
    } else {
      const index = stopDialogueIndex;
      if (index >= 0 && index < lessonData.lesson_data.length) {
        setFilteredComponents(lessonData.lesson_data.slice(0, index + 1));
      }
    }
  }, [stopDialogueIndex, lessonData.lesson_data, withAnimation]);

  useEffect(() => {
    if (lessonData?.lesson_data?.length) {
      const index = lessonData.lesson_data.findIndex((component) => {
        if (component?.type === "DIALOGUE") {
          const lastSelectedBranch =
            component.selectedBranches?.[component.selectedBranches.length - 1];

          if (lastSelectedBranch && component.data[lastSelectedBranch]) {
            const hasOtherBranches =
              component.data[lastSelectedBranch].answers?.answers_data?.length >
              0;
            return hasOtherBranches;
          }
        }
        return false;
      });

      if (index !== -1) {
        setStopDialogueIndex(index);
      } else {
        setStopDialogueIndex(null);
      }
    }
  }, [lessonData]);

  const fetchLessonData = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await axios(BASE_API_URL + `/lessons/${id}`);
      setLessonData(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error("Ошибка загрузки данных урока:", error);
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      fetchLessonData();
    }
  }, [id, withAnimation]);

  return (
    <LessonPreviewPageContainer darkTheme={colorTheme === "DARK"}>
      <LessonPreviewPageHeader
        lessonData={lessonData}
        colorTheme={colorTheme}
        withAnimation={withAnimation}
        setColorTheme={setColorTheme}
        setWithAnimation={setWithAnimation}
      />

      <LessonComponentsList
        isLoading={isLoading}
        filteredComponents={filteredComponents}
        lessonData={lessonData}
        withAnimation={withAnimation}
        courseMainGradient={courseMainGradient}
        courseMainColor={courseMainColor}
        colorTheme={colorTheme}
        setLessonData={setLessonData}
        setTextBibleModalData={setTextBibleModalData}
      />

      {shouldShowFinishButton && !!courseMainGradient && (
        <FinishLessonButton
          courseMainGradient={courseMainGradient}
          setLessonData={setLessonData}
        />
      )}

      {textBibleModalData.bibleId && (
        <BibleChapterModalWindow
          bibleModalData={textBibleModalData}
          setOpen={setTextBibleModalData}
          setBibleVersesData={setBibleVersesData}
          setOpenBibleModalWindow={setOpenBibleModalWindow}
        />
      )}

      {openBibleModalWindow &&
        createPortal(
          <BibleModalWindow
            setOpenBibleModalWindow={setOpenBibleModalWindow}
            editedVersesData={bibleVersesData}
            setEditedVersesData={setBibleVersesData}
            inComponent={"simple_modal"}
          />,
          document.body
        )}
      <ToastNotification />
    </LessonPreviewPageContainer>
  );
};

export default memo(LessonPreviewPage);
