export async function createVideo(accessToken, file) {
  const createResponse = await fetch("https://api.vimeo.com/me/videos", {
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      upload: {
        approach: "tus",
        size: file.size,
      },
      privacy: {
        view: "anybody",
      },
    }),
  });

  if (!createResponse.ok) {
    throw new Error("Ошибка при создании видео: " + createResponse.statusText);
  }

  return await createResponse.json();
}

export function uploadVideo(uploadLink, accessToken, file, onProgress) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open("PATCH", uploadLink, true);
    xhr.setRequestHeader("Tus-Resumable", "1.0.0");
    xhr.setRequestHeader("Upload-Offset", "0");
    xhr.setRequestHeader("Content-Type", "application/offset+octet-stream");
    xhr.setRequestHeader("Authorization", `Bearer ${accessToken}`);

    xhr.upload.onprogress = function (event) {
      if (event.lengthComputable && typeof onProgress === "function") {
        const percentComplete = Math.round((event.loaded / event.total) * 100);
        onProgress(percentComplete); // Вызываем callback с текущим прогрессом
      }
    };

    xhr.onload = function () {
      if (xhr.status === 204) {
        resolve();
      } else {
        reject(new Error("Ошибка при загрузке видео: " + xhr.statusText));
      }
    };

    xhr.onerror = function () {
      reject(new Error("Ошибка сети при загрузке видео"));
    };

    xhr.send(file.slice(0));
  });
}

export async function completeUpload(uploadLink, accessToken, file) {
  const completeResponse = await fetch(uploadLink, {
    method: "PATCH",
    headers: {
      "Tus-Resumable": "1.0.0",
      "Upload-Offset": file.size,
      "Content-Type": "application/offset+octet-stream",
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (!completeResponse.ok) {
    throw new Error(
      "Ошибка при завершении загрузки: " + completeResponse.statusText
    );
  }
}

export async function getVideoInfo(videoUri, accessToken) {
  const videoResponse = await fetch(`https://api.vimeo.com${videoUri}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (!videoResponse.ok) {
    throw new Error(
      "Ошибка при получении информации о видео: " + videoResponse.statusText
    );
  }

  return await videoResponse.json();
}
