import React from "react";

const CancelIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.48839 3.86805C5.90261 3.28226 4.95286 3.28226 4.36707 3.86805C3.78129 4.45384 3.78129 5.40358 4.36707 5.98937L10.3777 12L4.36707 18.0106C3.78129 18.5964 3.78129 19.5462 4.36707 20.1319C4.95286 20.7177 5.90261 20.7177 6.48839 20.1319L12.499 14.1213L18.5092 20.1315C19.095 20.7173 20.0447 20.7173 20.6305 20.1315C21.2163 19.5457 21.2163 18.596 20.6305 18.0102L14.6203 12L20.6305 5.98981C21.2163 5.40403 21.2163 4.45428 20.6305 3.86849C20.0447 3.28271 19.095 3.28271 18.5092 3.86849L12.499 9.87868L6.48839 3.86805Z"
        fill="url(#paint0_linear_7928_18048)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_7928_18048"
          x1="12.0845"
          y1="0.277502"
          x2="12.0845"
          y2="22.0839"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5172EA" />
          <stop offset="1" stopColor="#7A5AEE" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default CancelIcon;
