import React from "react";

const HeaderUserIcon = ({ className }) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_998_978)">
      <path
        d="M22 23.1932V20.8598C22 19.6222 21.4732 18.4352 20.5355 17.56C19.5979 16.6848 18.3261 16.1932 17 16.1932H7C5.67392 16.1932 4.40215 16.6848 3.46447 17.56C2.52678 18.4352 2 19.6222 2 20.8598V23.1932"
        stroke="#383838"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={className}
      />
      <path
        d="M12 11.1932C14.7614 11.1932 17 8.9546 17 6.19318C17 3.43175 14.7614 1.19318 12 1.19318C9.23858 1.19318 7 3.43175 7 6.19318C7 8.9546 9.23858 11.1932 12 11.1932Z"
        stroke="#383838"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={className}
      />
    </g>
    <defs>
      <clipPath id="clip0_998_978">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0 0.193176)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default HeaderUserIcon;
