import React, { memo, useEffect } from "react";
import styled from "styled-components";
import BibileChapterMobile from "./bible_chapter_mobile";
import { useSearchParams } from "react-router-dom";
import { useBiblePageContext } from "../bible_page_context";

const BiblePageMobileContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const BiblePageMobile = ({
  selectedVerses,
  setSelectedVerses,
  selectedSearchVerse,
  bibleSearchPlace,
  bibleSearchInput,
  bibleSearchResults,
  currentTranslationFirst,
  currentTranslationSecond,
  bibleTranslations,
  setCurrentTranslationFirst,
  setCurrentTranslationSecond,
  setBibleTranslations,
  setBibleSearchResults,
  setBibleSearchInput,
  setSelectedSearchVerse,
  setBibleSearchPlace,
}) => {
  const { activeScreenMobile } = useBiblePageContext();

  const [searchParams, setSearchParams] = useSearchParams();
  const bibleId = searchParams.get("bibleId");
  const bibleId2 = searchParams.get("2bibleId");

  useEffect(() => {
    if (activeScreenMobile !== "bible_chapter") {
      const newSearchParams = new URLSearchParams(searchParams);

      newSearchParams.delete("selectVerses");
      newSearchParams.delete("selectBible");
      setSearchParams(newSearchParams);
    }
  }, [activeScreenMobile]);

  useEffect(() => {
    if (!currentTranslationFirst && bibleTranslations?.length) {
      const firstTranslation = bibleTranslations.find(
        (transl) => transl.bible_code === bibleId
      );

      setCurrentTranslationFirst(firstTranslation);
    }
  }, [bibleTranslations, bibleId, currentTranslationFirst]);

  return (
    <BiblePageMobileContainer>
      <BibileChapterMobile
        selectedVerses={selectedVerses}
        setSelectedVerses={setSelectedVerses}
        currentTranslation={currentTranslationFirst}
        bibleTranslations={bibleTranslations}
        isFirstTranslation={true}
        setCurrentTranslation={setCurrentTranslationFirst}
        setBibleTranslations={setBibleTranslations}
        hasSecondTranslaition={!!bibleId2}
        setBibleSearchResults={setBibleSearchResults}
        setBibleSearchInput={setBibleSearchInput}
        setSelectedSearchVerse={setSelectedSearchVerse}
        setBibleSearchPlace={setBibleSearchPlace}
        bibleSearchPlace={bibleSearchPlace}
        bibleSearchInput={bibleSearchInput}
        bibleSearchResults={bibleSearchResults}
        selectedSearchVerse={selectedSearchVerse}
      />

      {bibleId2 && (
        <BibileChapterMobile
          selectedVerses={selectedVerses}
          setSelectedVerses={setSelectedVerses}
          currentTranslation={currentTranslationSecond}
          bibleTranslations={bibleTranslations}
          isFirstTranslation={false}
          setCurrentTranslation={setCurrentTranslationSecond}
          setBibleTranslations={setBibleTranslations}
          hasSecondTranslaition={!!bibleId2}
          setBibleSearchResults={setBibleSearchResults}
          setBibleSearchInput={setBibleSearchInput}
          setSelectedSearchVerse={setSelectedSearchVerse}
          setBibleSearchPlace={setBibleSearchPlace}
          bibleSearchPlace={bibleSearchPlace}
          bibleSearchInput={bibleSearchInput}
          bibleSearchResults={bibleSearchResults}
          selectedSearchVerse={selectedSearchVerse}
        />
      )}
    </BiblePageMobileContainer>
  );
};

export default memo(BiblePageMobile);
