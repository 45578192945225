import React, { memo, useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import axios from "../../../../axios_config";
import { BASE_API_URL } from "../../../../common/endpoints";
import CourseLessonPreview from "./course_lesson_preview";
import LeftArrowIcon from "../../../../common/assets/icons/left_arrow_icon";
import LessonInfoModalWindow from "../courses_modal_window/lesson_info_modal_window";
import DeleteQuestionModalWindow from "../../../../common/components/modal_windows/delete_modal_window";
import PlusIcon from "../../../../common/assets/icons/create_lesson_icons/create_lesson_settings_icons/plus_icon";
import { PlusOrMinusButton } from "../../admin_page_create_lesson/create_lesson_components/create_lesson_styled_components";
import { useUserDataContext } from "../../../../user_data_context";

const NEW_LESSON_DATA = {
  lesson_created_at: new Date().toISOString(),
  lesson_modified_at: "",
  lesson_modified_by: {},
  lesson_name: "",
  lesson_number: 0,
  lesson_data: [],
  lesson_course: {},
  lesson_duration_min: 0,
};

const CourseLessonsListWrapper = styled.div`
  position: relative;
  flex: 1;
  padding-top: 42px;
  height: max-content;
  max-width: 630px;
  margin: 0 auto;
  padding-bottom: 120px;
`;

const CourseLessonsListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 28px;

  & h6 {
    user-select: none;
    margin: 15px auto;
    color: #383838;
  }
`;

const BackButtonContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 16px;
  width: max-content;
  padding: 4px 15px 5px 0;
  border-radius: 8px;

  &:hover {
    margin-left: -5px;
    transition: 0.2s;
  }

  & > h3 {
    color: #1e1e1e;
    font-size: 28px;
    font-weight: 600;
    line-height: 120%;
  }
`;

const LeftArrowIconContainer = styled.div`
  padding: 5px 15px 0 0;
  width: max-content;
  height: 100%;
`;

const AddLessonButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const AdminPageCourseLessonsList = ({
  courseSettingsData,
  lessonSettingsData,
  setLessonSettingsData,
  setActivePreviewsBlock,
  closeLessonSettings,
  fetchCourses,
  fetchCourse,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { userData } = useUserDataContext();

  const [courseLessonsList, setCourseLessonsList] = useState([]);
  const [lessonInfoModalData, setLessonInfoModalData] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const userIsCreatorOrSuperAdmin =
    courseSettingsData?.course_creator?._id === userData?._id ||
    userData?.user_role === "superadmin";

  const fetchCourseLessons = async () => {
    try {
      const response = await axios.get(
        `${BASE_API_URL}/lessons/findby?course_id=${courseSettingsData._id}`
      );

      setCourseLessonsList(response.data);
    } catch (error) {
      console.error("Ошибка при запросе:", error);
    }
  };

  useEffect(() => {
    if (courseSettingsData._id) {
      fetchCourseLessons();
    }
  }, [courseSettingsData]);

  const onClickBackButton = useCallback(
    (event) => {
      event.stopPropagation();

      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.delete("lessons");
      newSearchParams.delete("lessonId");
      setSearchParams(newSearchParams);

      setActivePreviewsBlock("courses");
      setLessonSettingsData({});
    },
    [searchParams, setSearchParams]
  );

  const onClickLessonDeleteButton = useCallback(async () => {
    try {
      await axios.delete(`${BASE_API_URL}/lessons/${lessonInfoModalData._id}`);

      closeLessonSettings();
      setLessonInfoModalData({});
      setShowDeleteModal(false);
      fetchCourses();
      fetchCourse();
    } catch (error) {
      console.error("Ошибка при удалении урока:", error);
    }
  }, [lessonInfoModalData, closeLessonSettings]);

  const onClickCreateNewLessonButton = useCallback(
    async (event) => {
      event.stopPropagation();

      const newLessonData = {
        ...NEW_LESSON_DATA,
        lesson_course: { _id: courseSettingsData._id },
      };

      try {
        const response = await axios.post(
          `${BASE_API_URL}/lessons`,
          newLessonData
        );

        searchParams.set("lessonId", response.data.lesson._id);
        setSearchParams(searchParams);
      } catch (error) {
        console.error("Ошибка при создании урока:", error);
      } finally {
        fetchCourses();
        fetchCourse();
      }
    },
    [courseSettingsData, setSearchParams]
  );

  return (
    <CourseLessonsListWrapper>
      <BackButtonContainer
        onClick={onClickBackButton}
        onMouseDown={(e) => e.stopPropagation()}
      >
        <LeftArrowIconContainer>
          <LeftArrowIcon color="#1e1e1e" />
        </LeftArrowIconContainer>
        <h3>{courseSettingsData.course_name}</h3>
      </BackButtonContainer>

      <CourseLessonsListContainer>
        {courseLessonsList.length > 0 ? (
          <>
            {courseLessonsList.map((lesson, index) => {
              return (
                <CourseLessonPreview
                  key={lesson._id}
                  lesson={lesson}
                  lessonIndex={index}
                  lessonSettingsData={lessonSettingsData}
                  courseSettingsData={courseSettingsData}
                  userIsCreatorOrSuperAdmin={userIsCreatorOrSuperAdmin}
                  setLessonInfoModalData={setLessonInfoModalData}
                  fetchCourses={fetchCourses}
                  fetchCourse={fetchCourse}
                />
              );
            })}
          </>
        ) : (
          <h6>Добавьте первый урок...</h6>
        )}

        {userIsCreatorOrSuperAdmin && (
          <AddLessonButtonContainer>
            <PlusOrMinusButton
              onClick={onClickCreateNewLessonButton}
              onMouseDown={(e) => e.stopPropagation()}
            >
              <PlusIcon />
            </PlusOrMinusButton>
          </AddLessonButtonContainer>
        )}
      </CourseLessonsListContainer>

      {lessonInfoModalData._id && (
        <LessonInfoModalWindow
          lessonInfoModalData={lessonInfoModalData}
          courseSettingsData={courseSettingsData}
          setLessonInfoModalData={setLessonInfoModalData}
          setShowDeleteModal={setShowDeleteModal}
          userIsCreatorOrSuperAdmin={userIsCreatorOrSuperAdmin}
        />
      )}

      {showDeleteModal && (
        <DeleteQuestionModalWindow
          deletionObject="урок"
          setOpen={setShowDeleteModal}
          handleDelete={onClickLessonDeleteButton}
        />
      )}
    </CourseLessonsListWrapper>
  );
};

export default memo(AdminPageCourseLessonsList);
