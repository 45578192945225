import React, { useCallback, useEffect, useRef, useState, memo } from "react";
import { createPortal } from "react-dom";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { useBibleVersesHeightsContext } from "./bible_verses_heights_context";
import VersesLoader from "./verses_loader";
import Verse from "./verse";
import { BASE_API_URL } from "../../../../common/endpoints";
import StrongsCodeComponent from "./strongs_code_component";
import VersesHeader from "./verses_header";
import { useAppContext } from "../../../../app_context";
import BibleStrongModalWindow from "../../../../common/components/modal_windows/bible_strong_modal_window";
import { useBiblePageContext } from "../../bible_page_context";

const VersesWrapper = styled.div`
  padding: 12px 0 0 12px;
  flex: 1;
  color: #1e1e1e;
  border-radius: 15px;
  border: 2px solid #fff;
  height: 100%;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
  overflow: hidden;

  @media (max-width: 1024px) {
    height: 100%;
  }
`;

const VersesContainer = styled.div`
  flex: 1;
  height: calc(100vh - 80px - 116px);
  padding-bottom: 15px;
  overflow-y: auto;

  @media (max-width: 1024px) {
    height: calc(100% - 40px);
  }
  ::-webkit-scrollbar {
    width: 4px;
  }

  @media (max-width: 1024px) {
    ::-webkit-scrollbar {
      width: 3px;
    }
  }
`;

const VerseError = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const transformTextWithStrongCode = (text) => {
  const cleanedText = text
    .replace(/<J>/g, "")
    .replace(/<\/J>/g, "")
    .replace(/<R>/g, "")
    .replace(/<\/R>/g, "")
    .replace(/<br\/?>/g, "")
    .replace(/<n>.*?<\/n>/g, "");

  const parts = cleanedText.split(/(<S>\d+<\/S>)/g);

  return parts.map((part, index) => {
    if (/<S>(\d+)<\/S>/.test(part)) {
      const code = part.replace(/<\/?S>/g, "");
      return <StrongsCodeComponent key={index}>{code}</StrongsCodeComponent>;
    }

    return <span key={index}>{part}</span>;
  });
};

const Verses = ({
  isFirstTranslation,
  selectedVerses,
  setSelectedVerses,
  currentTranslation,
}) => {
  const [searchParams] = useSearchParams();
  const versesRef = useRef(null);

  const { bibleFontSettings } = useAppContext();
  const {
    showStrongsCode,
    setShowStrongsCode,
    strongsCodeModalData,
    setStrongsCodeModalData,
    currentTestament,
    setCurrentTestament,
  } = useBiblePageContext();

  const {
    scrollTop,
    handleScroll,
    setVerseHeightsFirstTransl,
    setVerseHeightsSecondTransl,
    mergedHeights,
    verseHeightsFirstTransl,
    verseHeightsSecondTransl,
    scrollContainerTarget,
    setScrollContainerTarget,
  } = useBibleVersesHeightsContext();

  const [isLoading, setIsLoading] = useState(false);
  const [chapterData, setChapterData] = useState([]);
  const [versesError, setVersesError] = useState(null);

  useEffect(() => {
    if (chapterData) {
      setCurrentTestament(chapterData?.[0]?.book_place);
    }
  }, [chapterData]);

  const bibleId = searchParams.get(isFirstTranslation ? "bibleId" : "2bibleId");
  const bookId = searchParams.get("bookId");
  const chapterId = searchParams.get("chapterId");
  const selectVerses = searchParams.get("selectVerses");
  const selectBible = searchParams.get("selectBible");
  const hasSecondTranslation = searchParams.get("2bibleId");

  useEffect(() => {
    if (selectVerses && selectBible) {
      const selectedVersesNums = selectVerses.split(",").map(Number).sort();
      const selectedVersesObjs = chapterData.filter((verse) =>
        selectedVersesNums.includes(verse.verse_num)
      );
      if (chapterData?.[0]?.bible_code === selectBible) {
        setSelectedVerses({ bible: selectBible, verses: selectedVersesObjs });
      }
    } else {
      setSelectedVerses({});
    }
  }, [selectBible, selectVerses, chapterData]);

  useEffect(() => {
    if (versesRef.current && versesRef.current !== scrollContainerTarget) {
      versesRef.current.scrollTop = scrollTop;
    }
  }, [scrollTop]);

  const onScroll = useCallback(() => {
    if (versesRef.current) {
      setScrollContainerTarget(versesRef.current);
      handleScroll(versesRef.current.scrollTop);
    }
  }, [versesRef.current]);

  const fetchChapterData = async (bibleId, bookId, chapterId) => {
    try {
      const response = await fetch(
        `${BASE_API_URL}/bible/verses?bible=${bibleId}&book=${bookId}&chapter=${chapterId}`
      );
      const json = await response.json();

      if (json.verses) {
        const parsedChapter = json.verses.map((verse) => ({
          ...verse,
          verse_text: transformTextWithStrongCode(verse.verse_text),
        }));
        return { data: parsedChapter, error: null };
      } else {
        return {
          data: [],
          error: "Данной книги/стиха нет в выбранном переводе",
        };
      }
    } catch (e) {
      console.log(e);
      return { data: [], error: "Произошла ошибка при загрузке данных" };
    }
  };

  useEffect(() => {
    if (bibleId && chapterId && bookId) {
      setIsLoading(true);
      fetchChapterData(bibleId, bookId, chapterId)
        .then(({ data, error }) => {
          setChapterData(data);
          setVersesError(error);
        })
        .finally(() => setIsLoading(false));
    }
  }, [bibleId, chapterId, bookId]);

  return (
    <VersesWrapper>
      <VersesHeader
        currentTranslation={currentTranslation}
        chapterData={chapterData}
        bibleId={bibleId}
        hasSecondTranslation={hasSecondTranslation}
        showStrongsCode={showStrongsCode}
        setShowStrongsCode={setShowStrongsCode}
      />

      <VersesContainer
        ref={versesRef}
        size={bibleFontSettings?.fontSize}
        font={bibleFontSettings?.fontVariant}
        onScroll={onScroll}
      >
        {isLoading ? (
          <VersesLoader bibleFontSize={bibleFontSettings?.fontSize} />
        ) : (
          <div>
            <VerseError>{versesError}</VerseError>

            {chapterData?.map((verse, index) => (
              <Verse
                key={verse._id}
                verseIndex={index}
                verse={verse}
                versesRef={versesRef}
                bibleFontSettings={bibleFontSettings}
                selectedVerses={selectedVerses}
                blockHeight={mergedHeights[index]}
                setVerseHeights={
                  isFirstTranslation
                    ? setVerseHeightsFirstTransl
                    : setVerseHeightsSecondTransl
                }
                isFirstTranslation={isFirstTranslation}
                verseHeightsFirstTransl={verseHeightsFirstTransl}
                verseHeightsSecondTransl={verseHeightsSecondTransl}
              />
            ))}
          </div>
        )}
      </VersesContainer>

      {!!strongsCodeModalData &&
        currentTranslation?.bible_strong &&
        createPortal(
          <BibleStrongModalWindow
            strongsCodeModalData={strongsCodeModalData}
            currentTranslation={currentTranslation}
            currentTestament={currentTestament}
            setOpen={setStrongsCodeModalData}
            setShowStrongsCode={setShowStrongsCode}
          />,
          document.body
        )}
    </VersesWrapper>
  );
};

export default memo(Verses);
