import React, { memo, useCallback } from "react";
import styled, { keyframes } from "styled-components";
import VerticalLine from "../../../../../common/assets/icons/vertical_line";
import UnfavoriteTranslationIcon from "../../../../../common/assets/icons/bible_page_icons/unfavorite_translation_icon";
import FavoriteTranslationIcon from "../../../../../common/assets/icons/bible_page_icons/favorite_translation_icon";

const TranslationsListContainer = styled.div`
  width: 100%;
  max-width: 100%;
  padding: 0 10px;
  max-height: 145px;
  overflow-x: hidden;
  overflow-y: auto;

  ::-webkit-scrollbar-track {
    background-color: #fff;
  }

  ::-webkit-scrollbar {
    width: 3px;
  }

  @media (max-width: 1024px) {
    max-height: 120px;
  }

  @media (max-width: 744px) {
    padding: 0;
    max-height: 100%;
  }
`;

const TranslationContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding-bottom: 5px;

  @media (max-width: 744px) {
    padding: 0 10px;
    border-bottom: 0.5px solid #cacaca;
    height: 48px;
  }
`;

const theRunningLineAnimation = (stringSize) => keyframes`
  0% {
    margin-left: 25px;
  }
  10%{
    margin-left: 25px;
  }
  60% {
    margin-left: -${(stringSize * 10) / 2 - 120}px;
  }
  80 %{
    margin-left: 25px;
  }
  100% {
    margin-left: 25px;
  }
`;

const TranslationNameWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  height: 26px;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    > * {
      &:first-child {
        transform: scale(1.3);

        > * {
          &:first-child {
            display: none;
          }

          &:nth-child(2) {
            display: none;
          }

          &:last-child {
            margin-left: -100px;
            animation: ${({ size }) => theRunningLineAnimation(size)} linear
              ${({ size }) => size / 5 || 4}s infinite;
          }
        }
      }
    }
  }
`;

const TranslationNameContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 4px;
  transition: 0.1s;

  @media (max-width: 744px) {
    gap: 5px;
  }
`;

const TranslationAbbreviation = styled.div`
  font-size: 12px;
  font-weight: 600;
  color: ${({ selected }) => (selected ? "#f8d254" : "#828282")};
  line-height: 16px; /* 133.333% */
  white-space: nowrap;
  letter-spacing: 0.4px;
  text-transform: uppercase;

  @media (max-width: 744px) {
    font-size: 16px;
    line-height: 130%;
  }
`;

const TranslationNameText = styled.div`
  color: ${({ selected }) => (selected ? "#1E1E1E" : "#828282")};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: -0.2px;

  @media (max-width: 744px) {
    font-size: 14px;
    line-height: 140%;
    letter-spacing: -0.14px;
  }
`;

const HeartIconContainer = styled.div`
  display: flex;
  align-items: center;
  width: 16px;
  height: 100%;

  &:hover {
    cursor: pointer;
    scale: 1.1;
    transition: 0.2s;
  }

  @media (max-width: 744px) {
    width: 24px;
  }
`;

const TranslationsListIsEmpty = styled.div`
  text-align: center;
  padding: 15px;
  color: #828282;
  font-size: 12px;
  line-height: 130%;
  letter-spacing: -0.2px;

  @media (max-width: 744px) {
    font-size: 14px;
    padding: 25px;
  }
`;

const TranslationsList = ({
  selectedTranslations,
  currentTranslation,
  currentBibleScreen = "",
  setBibleTranslations,
  onTranslationClick,
}) => {
  const toggleFavorite = useCallback((currentItem) => {
    const savedTranslations = JSON.parse(
      localStorage.getItem("favoriteTranslations") || "[]"
    );

    const updatedSavedTransls = savedTranslations.includes(
      currentItem.bible_code
    )
      ? savedTranslations.filter((transl) => transl !== currentItem.bible_code)
      : [...savedTranslations, currentItem.bible_code];

    localStorage.setItem(
      "favoriteTranslations",
      JSON.stringify(updatedSavedTransls)
    );

    setBibleTranslations((prevState) =>
      prevState.map((translation) => ({
        ...translation,
        isFavorite:
          translation.bible_code === currentItem.bible_code
            ? !translation.isFavorite
            : translation.isFavorite,
      }))
    );
  }, []);

  return (
    <TranslationsListContainer>
      {selectedTranslations?.length ? (
        selectedTranslations.map((translation) => (
          <TranslationContainer key={translation.bible_code}>
            <TranslationNameWrapper
              size={translation.bible_name.length}
              onClick={() => onTranslationClick(translation)}
            >
              <TranslationNameContainer>
                <TranslationAbbreviation
                  selected={
                    translation.bible_code === currentTranslation?.bible_code
                  }
                >
                  {translation.bible_code}
                </TranslationAbbreviation>
                <VerticalLine height={"15px"} />
                <TranslationNameText
                  selected={
                    translation.bible_code === currentTranslation?.bible_code
                  }
                >
                  {translation.bible_name}
                </TranslationNameText>
              </TranslationNameContainer>
            </TranslationNameWrapper>

            {currentBibleScreen !== "bible_compare" && (
              <HeartIconContainer onClick={() => toggleFavorite(translation)}>
                {translation.isFavorite ? (
                  <FavoriteTranslationIcon />
                ) : (
                  <UnfavoriteTranslationIcon />
                )}
              </HeartIconContainer>
            )}
          </TranslationContainer>
        ))
      ) : (
        <TranslationsListIsEmpty>Список пустой :(</TranslationsListIsEmpty>
      )}
    </TranslationsListContainer>
  );
};
export default memo(TranslationsList);
