import React, { memo, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { QuestionsFilterOptionsContainer } from "../../../question_styled_components";
import AdminPageCalendarArrow from "../../../../../../common/assets/icons/admin_page_icons/admin_page_calendar_arrow";
import CalendarDropdownMonths from "./calendar_dropdown_months";

const CalendarDropdownContainer = styled(QuestionsFilterOptionsContainer)`
  padding: 0;
  width: 590px;
  left: -5px;
`;

const CalendarDropdownYearContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 38px;
  padding: 0 11px;
  border-bottom: 1px solid #cacaca;
`;

const YearTitle = styled.div`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 700;
  background: linear-gradient(180deg, #5172ea -18.38%, #7a5aee 108.82%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const YearArrowLeft = styled.div`
  display: flex;
  align-items: center;
  padding: 0 12px;
  height: 70%;
  margin-top: 2px;
  transition: 0.2s;
  cursor: pointer;

  &: hover {
    transform: scale(1.2);
  }
`;

const YearArrowRight = styled(YearArrowLeft)`
  visibility: ${({ isVisible }) => (isVisible ? "visible" : "hidden")};
  transform: rotate(180deg);

  &: hover {
    transform: scale(1.2) rotate(180deg);
    transition: transform 0.2s;
  }
`;

const CalendarDropdownButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 10px;
  height: 46px;
  width: 100%;
  padding: 0 16px;
  border-top: 1px solid #cacaca;
`;

const DropdownButton = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
  width: max-content;
  padding: 0 17px;
  border-radius: 8px;
  background: rgb(97 105 236);
  color: #fff;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    opacity: 1;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
      rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  }
`;

const DropdownCancelButton = styled(DropdownButton)`
  opacity: 0.6;
`;
const DropdownApplyButton = styled(DropdownButton)`
  opacity: ${({ hoverOnCancelButton }) => (hoverOnCancelButton ? "0.6" : "1")};
`;

const CalendarDropdown = ({
  setQuestionsFilterSettings,
  handleExtendOptions,
}) => {
  const [hoverOnCancelButton, setHoverOnCancelButton] = useState(false);
  const [extendedRangeCalendars, setExtendedRangeCalendars] = useState(false);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [startRangeDate, setStartRangeDate] = useState(null);
  const [endRangeDate, setEndRangeDate] = useState(null);

  const currentYear = new Date().getFullYear();

  useEffect(() => {
    setSelectedYear(currentYear);
  }, []);

  const toggleHoverOnDeleteButton = useCallback(() => {
    setHoverOnCancelButton((prevState) => !prevState);
  }, []);

  const onPreviewYearButton = useCallback(() => {
    setSelectedYear((prevState) => prevState - 1);
    setExtendedRangeCalendars(false);
  }, []);

  const onNextYearButton = useCallback(() => {
    if (currentYear !== selectedYear) {
      setSelectedYear((prevState) => prevState + 1);
      setExtendedRangeCalendars(false);
    }
  }, [currentYear, selectedYear]);

  const onClickApplyDateButton = useCallback(
    (e) => {
      const newDateRange = {
        year: selectedYear,
        monthOfYear: selectedMonth,
        dateRange: {
          startRangeDate,
          endRangeDate,
        },
      };

      setQuestionsFilterSettings((prevState) => {
        return {
          ...prevState,
          question_date_range: [...prevState.question_date_range, newDateRange],
        };
      });
      handleExtendOptions(e, "");
    },
    [selectedYear, selectedMonth, startRangeDate, endRangeDate]
  );

  const onClickCancelButton = useCallback((event) => {
    handleExtendOptions(event, "");
  }, []);

  return (
    <CalendarDropdownContainer onClick={(e) => e.stopPropagation()}>
      <CalendarDropdownYearContainer>
        <YearArrowLeft onClick={onPreviewYearButton}>
          <AdminPageCalendarArrow />
        </YearArrowLeft>
        <YearTitle>{selectedYear}</YearTitle>
        <YearArrowRight
          onClick={onNextYearButton}
          isVisible={currentYear !== selectedYear}
        >
          <AdminPageCalendarArrow />
        </YearArrowRight>
      </CalendarDropdownYearContainer>

      <CalendarDropdownMonths
        selectedMonth={selectedMonth}
        setSelectedMonth={setSelectedMonth}
        endRangeDate={endRangeDate}
        setEndRangeDate={setEndRangeDate}
        startRangeDate={startRangeDate}
        setStartRangeDate={setStartRangeDate}
        extendedRangeCalendars={extendedRangeCalendars}
        setExtendedRangeCalendars={setExtendedRangeCalendars}
      />

      <CalendarDropdownButtons>
        <DropdownCancelButton
          onMouseEnter={toggleHoverOnDeleteButton}
          onMouseLeave={toggleHoverOnDeleteButton}
          onClick={onClickCancelButton}
        >
          Отмена
        </DropdownCancelButton>
        <DropdownApplyButton
          hoverOnCancelButton={hoverOnCancelButton}
          onClick={onClickApplyDateButton}
        >
          Выбрать
        </DropdownApplyButton>
      </CalendarDropdownButtons>
    </CalendarDropdownContainer>
  );
};

export default memo(CalendarDropdown);
