import React from "react";

const SharePurpleIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.0001 2.89961C16.8403 2.89961 15.9001 3.83981 15.9001 4.99961C15.9001 6.15941 16.8403 7.09961 18.0001 7.09961C19.1599 7.09961 20.1001 6.15941 20.1001 4.99961C20.1001 3.83981 19.1599 2.89961 18.0001 2.89961ZM14.1001 4.99961C14.1001 2.8457 15.8462 1.09961 18.0001 1.09961C20.154 1.09961 21.9001 2.8457 21.9001 4.99961C21.9001 7.15352 20.154 8.89961 18.0001 8.89961C15.8462 8.89961 14.1001 7.15352 14.1001 4.99961Z"
        fill="url(#paint0_linear_9889_9477)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.99998 9.89961C4.84018 9.89961 3.89998 10.8398 3.89998 11.9996C3.89998 13.1594 4.84018 14.0996 5.99998 14.0996C7.15977 14.0996 8.09998 13.1594 8.09998 11.9996C8.09998 10.8398 7.15977 9.89961 5.99998 9.89961ZM2.09998 11.9996C2.09998 9.8457 3.84607 8.09961 5.99998 8.09961C8.15389 8.09961 9.89998 9.8457 9.89998 11.9996C9.89998 14.1535 8.15389 15.8996 5.99998 15.8996C3.84607 15.8996 2.09998 14.1535 2.09998 11.9996Z"
        fill="url(#paint1_linear_9889_9477)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.0001 16.8996C16.8403 16.8996 15.9001 17.8398 15.9001 18.9996C15.9001 20.1594 16.8403 21.0996 18.0001 21.0996C19.1599 21.0996 20.1001 20.1594 20.1001 18.9996C20.1001 17.8398 19.1599 16.8996 18.0001 16.8996ZM14.1001 18.9996C14.1001 16.8457 15.8462 15.0996 18.0001 15.0996C20.154 15.0996 21.9001 16.8457 21.9001 18.9996C21.9001 21.1535 20.154 22.8996 18.0001 22.8996C15.8462 22.8996 14.1001 21.1535 14.1001 18.9996Z"
        fill="url(#paint2_linear_9889_9477)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.81236 13.0564C8.06262 12.6269 8.61364 12.4817 9.0431 12.7319L15.8731 16.7119C16.3026 16.9622 16.4478 17.5132 16.1976 17.9427C15.9473 18.3721 15.3963 18.5174 14.9668 18.2671L8.13684 14.2871C7.70738 14.0369 7.5621 13.4859 7.81236 13.0564Z"
        fill="url(#paint3_linear_9889_9477)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.1873 6.0559C16.4378 6.4852 16.2929 7.03631 15.8636 7.28684L9.04359 11.2668C8.61429 11.5174 8.06318 11.3724 7.81265 10.9431C7.56212 10.5138 7.70704 9.96273 8.13634 9.7122L14.9563 5.7322C15.3856 5.48167 15.9368 5.62659 16.1873 6.0559Z"
        fill="url(#paint4_linear_9889_9477)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_9889_9477"
          x1="17.8116"
          y1="-0.334214"
          x2="17.8116"
          y2="9.58785"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5172EA" />
          <stop offset="1" stopColor="#7A5AEE" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_9889_9477"
          x1="5.81146"
          y1="6.66579"
          x2="5.81146"
          y2="16.5878"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5172EA" />
          <stop offset="1" stopColor="#7A5AEE" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_9889_9477"
          x1="17.8116"
          y1="13.6658"
          x2="17.8116"
          y2="23.5878"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5172EA" />
          <stop offset="1" stopColor="#7A5AEE" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_9889_9477"
          x1="11.7964"
          y1="11.5468"
          x2="11.7964"
          y2="18.8997"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5172EA" />
          <stop offset="1" stopColor="#7A5AEE" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_9889_9477"
          x1="11.7916"
          y1="4.54682"
          x2="11.7916"
          y2="11.8997"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5172EA" />
          <stop offset="1" stopColor="#7A5AEE" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default SharePurpleIcon;
