import React, { memo, useCallback, useState } from "react";
import styled from "styled-components";
import BlackArrowDownIcon from "../../../../../../common/assets/icons/admin_page_icons/black_arrow_down_icon";

const CourseActivityContainer = styled.div`
  border-radius: 8px;
  overflow: hidden;
  transition: 0.2s;

  &:hover {
    box-shadow: 4.732px 4.732px 8.281px 0px rgba(0, 0, 0, 0.12);
  }
`;

const CourseActivityHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 8px 15px;
  height: 40px;
  color: #1e1e1e;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  background: #fff;
  cursor: pointer;
  user-select: none;
`;

const CourseActivityHeaderArrow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 1px;
  transform: scale(1.12);

  ${({ isExtended }) =>
    isExtended &&
    `
    padding-bottom: 3px;
    transform: rotate(180deg) scale(1.12);
  `}
`;

const CourseActivityLesson = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 25px;
  border-top: 1px solid #eaeaea;
  padding: 0 31px;
  background: ${({ status }) =>
    status === "finished"
      ? "#5172EA"
      : status === "in_process"
      ? "#F8D254"
      : "#CACACA"};
  user-select: none;
  ${({ status }) => status === "unfinished" && "opacity: 0.7;"};

  & > p {
    color: ${({ status }) => (status === "finished" ? "#fff" : "#383838")};
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.4px;
  }
`;

const CourseActivity = ({ activityItemInfo }) => {
  const [extendCourseInfo, setExtendCourseInfo] = useState(false);

  const handleExtendCourseInfo = useCallback((event) => {
    event.stopPropagation();
    setExtendCourseInfo((prevState) => !prevState);
  }, []);

  const unfinishedLessonsCount =
    activityItemInfo.course_lessons_count -
    +activityItemInfo?.course_lessons?.length;

  const unfinishedLessonsArray = Array.from(
    { length: unfinishedLessonsCount },
    (_) => ({
      lesson_status: "unfinished",
    })
  );

  return (
    <CourseActivityContainer>
      <CourseActivityHeader onClick={handleExtendCourseInfo}>
        {activityItemInfo.type === "course" && "Курс"}{" "}
        {`"${activityItemInfo.course_name}"`}
        <CourseActivityHeaderArrow isExtended={extendCourseInfo}>
          <BlackArrowDownIcon />
        </CourseActivityHeaderArrow>
      </CourseActivityHeader>

      {extendCourseInfo &&
        [...activityItemInfo.course_lessons, ...unfinishedLessonsArray].map(
          (lesson, index) => {
            const lessonStatusName =
              lesson.lesson_status === "finished"
                ? "Пройден"
                : lesson.lesson_status === "in_process"
                ? "В процессе"
                : "Не пройден";

            return (
              <CourseActivityLesson
                key={index + "lesson"}
                status={lesson.lesson_status}
              >
                <p>Урок {index + 1}</p>
                <p>{lessonStatusName}</p>
              </CourseActivityLesson>
            );
          }
        )}
    </CourseActivityContainer>
  );
};

export default memo(CourseActivity);
