import React, { useCallback, useState } from "react";
import styled from "styled-components";
import {
  HidePasswordIcon,
  ShowPasswordIcon,
} from "../../../common/assets/icons";
import AuthTextInput from "./auth_text_input";

const AuthPasswordInputContainer = styled.div`
  position: relative;
`;

const PasswordVisibleToggler = styled.div`
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 16px 0 6px;
  margin-right: -16px;
  transition: 0.2s;
  cursor: pointer;
`;

const AuthPasswordInput = ({ leftHandMessage, ...props }) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const togglePasswordVisible = useCallback(
    (event) => {
      event.stopPropagation();
      setIsPasswordVisible((prevState) => !prevState);
    },
    [setIsPasswordVisible]
  );

  return (
    <AuthPasswordInputContainer>
      <AuthTextInput
        type={isPasswordVisible ? "text" : "password"}
        isPassword={true}
        leftHandMessage={leftHandMessage}
        autoComplete="off"
        {...props}
      />
      <PasswordVisibleToggler onClick={togglePasswordVisible}>
        {!isPasswordVisible ? <HidePasswordIcon /> : <ShowPasswordIcon />}
      </PasswordVisibleToggler>
    </AuthPasswordInputContainer>
  );
};

export default AuthPasswordInput;
