import React from "react";
import styled from "styled-components";
import CertificateLineLargeIcon from "../../../../common/assets/icons/course_certificate_icons/certificate_line_large_icon";
import CertificateLineSmallIcon from "../../../../common/assets/icons/course_certificate_icons/certificate_line_small_icon";
import signatureLargeImg from "../../../../common/assets/images/course_certificate_images/signature_large.png";
import signatureSmallImg from "../../../../common/assets/images/course_certificate_images/signature_small.png";
import { formatDate } from "../../../../common/utils/functions/time_functions";
import useWindowBreakpoint from "../../../../common/hooks/use_window_breakpoint";

const CertificatePreviewContainer = styled.div`
  width: 100%;
  padding: 40px 55px 50px 70px;

  @media (max-width: 625px) {
    padding: 40px 30px 15px 40px;
  }
`;

const CertificateHeader = styled.div`
  margin-bottom: 65px;

  h6 {
    color: #373962;
    font-family: "Ysabeau Infant";
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
  }

  p {
    margin-top: -9px;
    color: #373962;
    font-family: "Ysabeau Infant";
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
  }

  @media (max-width: 625px) {
    margin-bottom: 49px;

    h6 {
      font-size: 13px;
      line-height: 100%;
    }

    p {
      margin-top: -2px;
      font-size: 10px;
    }
  }
`;

const CertificateRecipientContainer = styled.div`
  h6 {
    color: #373962;
    font-family: Montserrat;
    font-size: 11px;
    font-weight: 600;
    line-height: 130%;
  }

  @media (max-width: 625px) {
    h6 {
      font-size: 6.454px;
    }
  }
`;

const CourseStudentName = styled.div`
  margin-top: 10px;
  margin-bottom: 60px;
  min-height: 81px;
  width: max-content;
  border: 1px solid #373962;
  padding: 18px 54px;
  color: #373962;
  font-family: Montserrat;
  font-size: 34px;
  font-weight: 600;
  line-height: 130%;

  @media (max-width: 625px) {
    margin-top: 6px;
    margin-bottom: 40px;
    padding: 10px 32px;
    font-size: 20px;
    min-height: max-content;
  }
`;

const CourseTitleContainer = styled.div`
  h6 {
    color: #373962;
    font-family: Montserrat;
    font-size: 11px;
    font-weight: 600;
    line-height: 130%;
  }

  @media (max-width: 625px) {
    h6 {
      font-size: 7px;
    }
  }
`;

const CourseTitle = styled.p`
  margin-top: 3px;
  color: #373962;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 600;
  line-height: 130%;

  @media (max-width: 625px) {
    h6 {
      font-size: 12px;
    }
  }
`;

const CourseСonclusion = styled.div`
  display: flex;
  align-items: center;
  height: 155px;

  p {
    margin-top: -30px;
    color: #373962;
    font-family: Montserrat;
    font-size: 11px;
    font-weight: 600;
    line-height: 130%;
  }

  @media (max-width: 625px) {
    height: 80px;

    p {
      margin-top: -15px;
      font-size: 7px;
    }
  }
`;

const SignatureAndDateContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
`;

const SignatureContainer = styled.div`
  display: flex;
  flex-direction: column;

  img {
    margin-left: -60px;
    margin-top: -18px;
  }

  svg {
    margin-left: -15px;
    margin-top: -12px;
  }

  span {
    margin-top: 2px;
    margin-left: 35px;
    color: #373962;
    font-family: Montserrat;
    font-size: 12.68px;
    font-weight: 600;
    line-height: normal;
  }

  @media (max-width: 625px) {
    img {
      margin-left: -35px;
      margin-top: -18px;
    }

    svg {
      margin-left: -10px;
      margin-top: -7px;
    }

    span {
      margin-top: 2px;
      margin-left: 20px;
      font-size: 7.439px;
    }
  }
`;

const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    margin-top: 2px;
    color: #373962;
    font-family: Montserrat;
    font-size: 12.68px;
    font-weight: 600;
    line-height: normal;
  }

  @media (max-width: 625px) {
    font-size: 8px;
  }
`;

const CourseFinishDate = styled.p`
  padding-top: 6px;
  padding-bottom: 4px;
  color: #373962;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  line-height: 140%;

  @media (max-width: 625px) {
    margin-top: -2px;
    padding-bottom: 2px;
    font-size: 8.214px;
  }
`;

const CertificateContent = ({
  courseStudentName,
  courseTitle,
  certificateDescription,
  certificateDate,
}) => {
  const isMobile = useWindowBreakpoint(625);

  return (
    <CertificatePreviewContainer>
      <CertificateHeader>
        <h6>СЕРТИФИКАТ</h6> <p>о прохождении</p>
      </CertificateHeader>

      <CertificateRecipientContainer>
        <h6>С ЛЮБОВЬЮ ПРЕДОСТАВЛЕН ДЛЯ:</h6>
        <CourseStudentName>{courseStudentName}</CourseStudentName>
      </CertificateRecipientContainer>

      <CourseTitleContainer>
        <h6>Который успешно прошел курс:</h6>
        <CourseTitle>{courseTitle || ""}</CourseTitle>
      </CourseTitleContainer>

      <CourseСonclusion>
        <p>{certificateDescription}</p>
      </CourseСonclusion>

      <SignatureAndDateContainer>
        <SignatureContainer>
          <img
            src={isMobile ? signatureSmallImg : signatureLargeImg}
            alt="certificate signature"
          />
          {isMobile ? (
            <CertificateLineSmallIcon />
          ) : (
            <CertificateLineLargeIcon />
          )}
          <span>подпись</span>
        </SignatureContainer>

        <DateContainer>
          <CourseFinishDate>{certificateDate}</CourseFinishDate>
          {isMobile ? (
            <CertificateLineSmallIcon />
          ) : (
            <CertificateLineLargeIcon />
          )}
          <span>дата</span>
        </DateContainer>
      </SignatureAndDateContainer>
    </CertificatePreviewContainer>
  );
};

export default CertificateContent;
