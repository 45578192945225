import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import TranslationPanelInput from "./translation_panel_input";
import VerticalLine from "../../../../../common/assets/icons/vertical_line";

const LanguageSelectContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

const LanguagesContainer = styled.div`
  width: 100%;
  max-width: 100%;
  padding-bottom: 5px;
  max-height: 145px;
  overflow-x: hidden;
  overflow-y: auto;

  ::-webkit-scrollbar-track {
    background-color: #fff;
  }

  ::-webkit-scrollbar {
    width: 3px;
  }

  @media (max-width: 1024px) {
    max-height: 120px;
  }

  @media (max-width: 744px) {
    max-height: 100%;
  }
`;

const LanguageContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 0 10px;
  height: 26px;
  color: #828282;
  cursor: pointer;

  &: hover {
    background: rgb(243 242 242);
    translation: 0.2s;
  }

  @media (max-width: 744px) {
    height: 48px;
    border-bottom: 0.5px solid #cacaca;
    gap: 6px;
  }
`;

const LanguageAbbreviation = styled.div`
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.4px;
  ${({ isActive }) => isActive && "color: #F8D254;"}

  @media (max-width: 744px) {
    font-size: 16px;
    line-height: 130%;
  }
`;

const LanguageName = styled.div`
  font-size: 10px;
  line-height: 140%;
  letter-spacing: -0.1px;
  ${({ isActive }) => isActive && "color: #1E1E1E;"}

  @media (max-width: 744px) {
    font-size: 14px;
    line-height: 140%;
    letter-spacing: -0.14px;
  }
`;

const LanguageSelect = ({
  translationsLanguages,
  currentBibleLanguage,
  setCurrentBibleLanguage,
  setCurrentScreen,
}) => {
  const [inputSearchValue, setInputSearchValue] = useState("");
  const [filteredLanguage, setFilteredLanguage] = useState([]);

  const getFilteredLanguage = useCallback((languages, inputSearchValue) => {
    const filteredLanguageBySearch = languages.filter((lang) => {
      return (
        lang.code.toLowerCase().includes(inputSearchValue.toLowerCase()) ||
        lang.abbreviation
          .toLowerCase()
          .includes(inputSearchValue.toLowerCase()) ||
        lang.name.toLowerCase().includes(inputSearchValue.toLowerCase())
      );
    });
    return filteredLanguageBySearch;
  }, []);

  useEffect(() => {
    if (translationsLanguages) {
      setFilteredLanguage(() =>
        getFilteredLanguage(translationsLanguages, inputSearchValue)
      );
    }
  }, [inputSearchValue, translationsLanguages]);

  const clickedOnTheLanguageItem = useCallback((selectedLanguage) => {
    setCurrentBibleLanguage(selectedLanguage);
    setCurrentScreen("translations");
  }, []);

  const stopPropagationOnClick = (e) => e.stopPropagation();

  return (
    <LanguageSelectContainer onClick={stopPropagationOnClick}>
      <TranslationPanelInput
        placeholder={"Поиск языка..."}
        section={"language"}
        onClickButton={() => setCurrentScreen("translations")}
        inputSearchValue={inputSearchValue}
        setInputSearchValue={setInputSearchValue}
      />

      <LanguagesContainer>
        {filteredLanguage.map((lang, index) => {
          return (
            <LanguageContainer
              key={index + lang.code}
              onClick={() => clickedOnTheLanguageItem(lang)}
            >
              <LanguageAbbreviation
                isActive={currentBibleLanguage.code === lang.code}
              >
                {lang.abbreviation}
              </LanguageAbbreviation>
              <VerticalLine height={"15px"} />
              <LanguageName isActive={currentBibleLanguage.code === lang.code}>
                {lang.name}
              </LanguageName>
            </LanguageContainer>
          );
        })}
      </LanguagesContainer>
    </LanguageSelectContainer>
  );
};

export default LanguageSelect;
