import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useSearchParams } from "react-router-dom";
import useOnclickOutside from "react-cool-onclickoutside";
import {
  FontSettingsIcon,
  MinusTranslationIcon,
  PlusTranslationIcon,
  SearchIcon,
} from "../../../../common/assets/icons";
import VerticalLine from "../../../../common/assets/icons/vertical_line";
import { useBiblePageContext } from "../../bible_page_context";
import FontSettingsBlock from "../../bible_page_desktop/bible_navigation_panel_desktop/bible_search_and_font_settings/font_settings_block";
import { useAppContext } from "../../../../app_context";
import ThreeDotsIcon from "../../../../common/assets/icons/three_dots_icon";
import CheckIcon from "../../../../common/assets/icons/admin_page_icons/check_icon";
import { BIBLE_BOOK_NAMES_DATA } from "../../bible_page_data";

const BibileMainButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 12px 12px 10px;
  user-select: none;
`;

const LeftButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  height: 32px;
  background: #fff;
  border-radius: 15px;
  overflow: hidden;
`;

const BiblePlaceButton = styled.button`
  display: flex;
  align-items: center;
  height: 100%;
  min-width: 66px;
  padding: 0 10px 0 13px;
  color: #1e1e1e;
  font-size: 14px;
  font-weight: 400;
  line-height: 122%;
  letter-spacing: 0.14px;
`;

const BibleTranslationButton = styled.button`
  display: flex;
  align-items: center;
  width: max-content;
  height: 100%;

  p {
    text-transform: uppercase;
    padding: 0 10px;
    color: #f8d254;
    font-size: 14px;
    font-weight: 600;
    line-height: 122%;
    letter-spacing: 0.4px;
    flex: 1;
  }
`;

const PlusTranslationButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 13px 0 10px;
  height: 100%;
`;

const VerticalLineStyled = styled(VerticalLine)`
  width: 1px;
`;

const RightButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  height: 32px;
`;

const BibleSearchButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 38px;
  background: #fff;
  border-radius: 15px;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.12);
  height: 32px;
`;

const BibleFontSettingsButton = styled(BibleSearchButton)`
  height: 32px;
`;

const ThreeDotsIconStyled = styled(ThreeDotsIcon)`
  height: 32px;
  gap: 3px;
`;

const SettingsBlockWrapper = styled.div`
  position: absolute;
  top: 52px;
  right: 12px;
  width: max-content;
`;

const SettingsBlockButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
  width: 100%;
  padding: 10px 8px;
  background: #fff;
  border-radius: 15px;
  box-shadow: 4.462px 4.462px 10px 0px rgba(0, 0, 0, 0.12);
`;

const SettingsBlockButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 28px;
  width: 146px;
  padding: 0 8px;
  color: #1e1e1e;
  font-size: 11px;
  font-weight: 400;
  border-radius: 8px;
  user-select: none;

  &:active {
    background: linear-gradient(
      180deg,
      rgba(81, 114, 234, 0.25) -18.38%,
      rgba(122, 90, 238, 0.25) 108.82%
    );
  }
`;

const CheckIconStyled = styled(CheckIcon)`
  width: 12px;
  height: 12px;
  margin-bottom: 2px;
`;

const StrongButton = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  color: #1e1e1e;
  font-size: 12px;
  background: #fff;
  padding: 0 13px;
  margin-right: 12px;
  height: 24px;
  border-radius: 15px;
  user-select: none;
  cursor: pointer;
  transition: 0.2s;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;

  svg {
    margin-bottom: 0px;
  }
`;

const formatBibleBookName = (name) => {
  if (!name) return "";
  const formattedName = name.replace(/^(\d)([А-Яа-яA-Za-z])/, "$1 $2");
  return formattedName.endsWith(".") ? formattedName : `${formattedName}.`;
};

const BibileMainButtons = ({
  chapterData,
  bibleId,
  isFirstTranslation,
  currentTranslation,
  hasSecondTranslaition,
  setOpenBibleSearch,
  setOpenBibleNavigation,
  setOpenBibleTranslation,
  setShowStrongsCode,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const settingsRef = useRef(null);
  const settingsFontRef = useRef(null);
  const settingsButtonRef = useRef(null);

  const { bibleFontSettings, setBibleFontSettings } = useAppContext();
  const { activeScreenMobile, setActiveScreenMobile, showStrongsCode } =
    useBiblePageContext();

  const [showSettingsBlock, setShowSettingsBlock] = useState(false);
  const [showFontSettings, setShowFontSettings] = useState(false);

  const shouldHideButton = isFirstTranslation && hasSecondTranslaition;
  const hasStrongsBible = bibleId === "rst";

  const currentChapterNum = chapterData?.[0]?.chapter_num;

  const bookNameData =
    BIBLE_BOOK_NAMES_DATA[currentTranslation?.bible_locale]?.[
      chapterData?.[0]?.book_code
    ]?.short_name;

  const formatedBookName = formatBibleBookName(bookNameData);

  useOnclickOutside(() => setShowSettingsBlock(false), {
    refs: [settingsRef, settingsButtonRef],
  });
  useOnclickOutside(() => setShowFontSettings(false), {
    refs: [settingsFontRef, settingsButtonRef],
  });

  useEffect(() => {
    setShowSettingsBlock(false);
    setShowFontSettings(false);
  }, [activeScreenMobile]);

  const handleOpenBibleNavigation = useCallback((event) => {
    event.stopPropagation();
    setActiveScreenMobile("bible_navigation");
    setOpenBibleNavigation(true);
  }, []);

  const handleOpenBibleSearch = useCallback(
    (event) => {
      event.stopPropagation();
      setActiveScreenMobile("bible_search");
      setOpenBibleSearch(true);

      setSearchParams((prev) => {
        const newParams = new URLSearchParams(prev);
        newParams.delete("2bibleId");
        return newParams;
      });
    },
    [setSearchParams]
  );

  const handleOpenBibleTranslation = useCallback((event) => {
    event.stopPropagation();
    setActiveScreenMobile("bible_translation");
    setOpenBibleTranslation(true);
  }, []);

  const handleShowSettingsBlock = useCallback(
    (event) => {
      event.stopPropagation();

      if (hasStrongsBible) {
        setShowSettingsBlock((prevState) => !prevState);
        setShowFontSettings(false);
      } else {
        setShowFontSettings((prevState) => !prevState);
      }
    },
    [setShowFontSettings, hasStrongsBible]
  );

  const onClickFontSettingsButton = useCallback((event) => {
    event.stopPropagation();
    setShowFontSettings(true);
    setShowSettingsBlock(false);
  }, []);

  const handleShowStrongsCodes = useCallback((event) => {
    event.stopPropagation();
    setShowStrongsCode((prevState) => !prevState);
  }, []);

  const handleAddSecondTranslate = useCallback(
    (event) => {
      event.stopPropagation();

      setSearchParams((prev) => {
        const newParams = new URLSearchParams(prev);

        if (!hasSecondTranslaition) {
          newParams.set("2bibleId", searchParams.get("bibleId"));
        } else {
          newParams.delete("2bibleId");
        }

        return newParams;
      });
    },
    [hasSecondTranslaition, searchParams, setSearchParams]
  );

  return (
    <BibileMainButtonsContainer>
      <LeftButtonsContainer>
        <BiblePlaceButton onClick={handleOpenBibleNavigation}>
          {formatedBookName} {currentChapterNum}
        </BiblePlaceButton>
        <VerticalLineStyled height="24" />

        <BibleTranslationButton
          key={bibleId}
          onClick={handleOpenBibleTranslation}
        >
          <p>{bibleId}</p>
        </BibleTranslationButton>

        {!shouldHideButton && (
          <>
            <VerticalLineStyled height="24" />

            <PlusTranslationButton onClick={handleAddSecondTranslate}>
              {!hasSecondTranslaition ? (
                <PlusTranslationIcon />
              ) : (
                <MinusTranslationIcon />
              )}
            </PlusTranslationButton>
          </>
        )}
      </LeftButtonsContainer>

      <RightButtonsContainer>
        {isFirstTranslation && (
          <>
            <BibleSearchButton onClick={handleOpenBibleSearch}>
              <SearchIcon />
            </BibleSearchButton>

            <BibleFontSettingsButton
              ref={settingsButtonRef}
              onClick={handleShowSettingsBlock}
            >
              {hasStrongsBible ? (
                <ThreeDotsIconStyled color={"#383838"} />
              ) : (
                <FontSettingsIcon />
              )}
            </BibleFontSettingsButton>

            {showSettingsBlock && (
              <SettingsBlockWrapper ref={settingsRef}>
                <SettingsBlockButtonsContainer>
                  <SettingsBlockButton onClick={handleShowStrongsCodes}>
                    Стронг {!!showStrongsCode && <CheckIconStyled />}
                  </SettingsBlockButton>
                  <SettingsBlockButton onClick={onClickFontSettingsButton}>
                    Настройки текста <FontSettingsIcon />
                  </SettingsBlockButton>
                </SettingsBlockButtonsContainer>
              </SettingsBlockWrapper>
            )}

            {showFontSettings && (
              <SettingsBlockWrapper ref={settingsFontRef}>
                <FontSettingsBlock
                  bibleFontSettings={bibleFontSettings}
                  setBibleFontSettings={setBibleFontSettings}
                />
              </SettingsBlockWrapper>
            )}
          </>
        )}

        {!isFirstTranslation && hasStrongsBible && (
          <StrongButton onClick={handleShowStrongsCodes}>
            Стронг {!!showStrongsCode && <CheckIconStyled />}
          </StrongButton>
        )}
      </RightButtonsContainer>
    </BibileMainButtonsContainer>
  );
};

export default memo(BibileMainButtons);
