import React, { memo, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { useSearchParams } from "react-router-dom";
import TranslationHeaderMobile from "./translation_header_mobile";
import { BIBLE_TRANSLATIONS_LANGUAGES } from "../../bible_page_data";
import TranslationSelect from "../../bible_page_desktop/bible_navigation_panel_desktop/bible_translations_panel/translation_select";
import LanguageSelect from "../../bible_page_desktop/bible_navigation_panel_desktop/bible_translations_panel/language_select";
import { useBiblePageContext } from "../../bible_page_context";

const BibleTranslationMobileContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 15px 0 20px 0;
  background: #fff;
`;

const BibleTranslationMobile = ({
  currentTranslation,
  isFirstTranslation,
  bibleTranslations,
  setCurrentTranslation,
  setBibleTranslations,
}) => {
  const [searchParams] = useSearchParams();
  const { setActiveScreenMobile } = useBiblePageContext();

  const [currentScreen, setCurrentScreen] = useState("translations"); //translations, languages
  const [currentBibleLanguage, setCurrentBibleLanguage] = useState(null);

  const bibleId = searchParams.get(isFirstTranslation ? "bibleId" : "2bibleId");
  const translationsLanguages = Object.values(BIBLE_TRANSLATIONS_LANGUAGES);

  useEffect(() => {
    if (
      bibleId &&
      bibleTranslations &&
      currentTranslation?.bible_code !== bibleId
    ) {
      const translation = bibleTranslations.find(
        (transl) => transl.bible_code === bibleId
      );

      setCurrentTranslation(translation);
    }
  }, [bibleTranslations, bibleId, currentTranslation]);

  useEffect(() => {
    if (currentTranslation) {
      const language =
        BIBLE_TRANSLATIONS_LANGUAGES[currentTranslation.bible_locale];
      setCurrentBibleLanguage(language);
    }
  }, [currentTranslation]);

  const onClickCloseButton = useCallback((event) => {
    event.stopPropagation();
    setActiveScreenMobile("bible_chapter");
  }, []);

  return (
    <BibleTranslationMobileContainer>
      <TranslationHeaderMobile
        currentTranslation={currentTranslation}
        onClickCloseButton={onClickCloseButton}
      />

      {currentScreen === "translations" && (
        <TranslationSelect
          setCurrentTranslation={setCurrentTranslation}
          bibleTranslations={bibleTranslations}
          setBibleTranslations={setBibleTranslations}
          translationsLanguages={translationsLanguages}
          setCurrentScreen={setCurrentScreen}
          currentBibleLanguage={currentBibleLanguage}
          currentTranslation={currentTranslation}
          isSecond={!isFirstTranslation}
        />
      )}

      {currentScreen === "languages" && (
        <LanguageSelect
          translationsLanguages={translationsLanguages}
          setCurrentScreen={setCurrentScreen}
          currentBibleLanguage={currentBibleLanguage}
          setCurrentBibleLanguage={setCurrentBibleLanguage}
        />
      )}
    </BibleTranslationMobileContainer>
  );
};

export default memo(BibleTranslationMobile);
