import React, { memo, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import VerticalLine from "../../../../assets/icons/vertical_line";
import { SearchIcon } from "../../../../assets/icons";
import { BIBLE_BOOK_NAMES_DATA } from "../../../../../pages/bible/bible_page_data";

const BibileModalHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 12px 12px 10px;
  user-select: none;
`;

const LeftButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  height: 32px;
  background: #fff;
  border-radius: 15px;
  overflow: hidden;
`;

const BiblePlaceButton = styled.button`
  display: flex;
  align-items: center;
  height: 100%;
  min-width: 66px;
  padding: 0 10px 0 17px;
  color: #1e1e1e;
  font-size: 14px;
  font-weight: 400;
  line-height: 122%;
  letter-spacing: 0.14px;
`;

const BibleTranslationButton = styled.button`
  display: flex;
  align-items: center;
  min-width: 50px;
  width: max-content;
  height: 100%;

  p {
    text-transform: uppercase;
    padding: 0 14px 0 12px;
    color: #f8d254;
    font-size: 14px;
    font-weight: 600;
    line-height: 122%;
    letter-spacing: 0.4px;
    flex: 1;
  }
`;

const VerticalLineStyled = styled(VerticalLine)`
  width: 1px;
`;

const BibleSearchButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 38px;
  background: #fff;
  border-radius: 15px;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.12);
`;

const BibileModalHeader = ({
  chapterData,
  setActiveScreenMobile,
  currentTranslation,
  currentBibleParams,
}) => {
  const [bibleChapterAddress, setBibleChapterAddress] = useState("");

  const abbreviation = chapterData?.[0]?.bible_code;

  useEffect(() => {
    if (currentTranslation?.bible_locale && currentBibleParams?.bookId) {
      setBibleChapterAddress(
        BIBLE_BOOK_NAMES_DATA?.[currentTranslation?.bible_locale]?.[
          currentBibleParams?.bookId
        ]?.name +
          " " +
          currentBibleParams?.chapterId
      );
    }
  }, [currentTranslation, currentBibleParams]);

  const handleOpenBibleNavigation = useCallback((event) => {
    event.stopPropagation();
    setActiveScreenMobile("bible_modal_navigation");
  }, []);

  const handleOpenBibleTranslation = useCallback((event) => {
    event.stopPropagation();
    setActiveScreenMobile("bible_modal_translation");
  }, []);

  const handleOpenBibleSearch = useCallback((event) => {
    event.stopPropagation();
    setActiveScreenMobile("bible_modal_search");
  }, []);

  return (
    <BibileModalHeaderContainer>
      <LeftButtonsContainer>
        <BiblePlaceButton onClick={handleOpenBibleNavigation}>
          {bibleChapterAddress}
        </BiblePlaceButton>
        <VerticalLineStyled height="24" />

        <BibleTranslationButton onClick={handleOpenBibleTranslation}>
          <p>{abbreviation || ""}</p>
        </BibleTranslationButton>
      </LeftButtonsContainer>

      <BibleSearchButton onClick={handleOpenBibleSearch}>
        <SearchIcon />
      </BibleSearchButton>
    </BibileModalHeaderContainer>
  );
};

export default memo(BibileModalHeader);
