import React, { createContext, useContext, useState } from "react";

const initialRegistrationData = {
  email: "",
  name: "",
  secondName: "",
  password: "",
  confirmPassword: "",
  country: "",
  city: "",
  countryId: null,
  cityId: null,
};

const AuthPageContext = createContext();

export const useAuthPageState = () => {
  const [currentAuthScreen, setCurrentAuthScreen] = useState(""); // registration_code, password_recovery_code, new_password
  const [redirectPath, setRedirectPath] = useState("");
  const [verificationToken, setVerificationToken] = useState("");
  const [registrationData, setRegistrationData] = useState(
    initialRegistrationData
  );

  return {
    currentAuthScreen,
    setCurrentAuthScreen,
    redirectPath,
    setRedirectPath,
    verificationToken,
    setVerificationToken,
    registrationData,
    setRegistrationData,
  };
};

export const AuthPageProvider = ({ children }) => {
  const {
    currentAuthScreen,
    setCurrentAuthScreen,
    redirectPath,
    setRedirectPath,
    verificationToken,
    setVerificationToken,
    registrationData,
    setRegistrationData,
  } = useAuthPageState();

  return (
    <AuthPageContext.Provider
      value={{
        currentAuthScreen,
        setCurrentAuthScreen,
        redirectPath,
        setRedirectPath,
        verificationToken,
        setVerificationToken,
        registrationData,
        setRegistrationData,
      }}
    >
      {children}
    </AuthPageContext.Provider>
  );
};

export const useAuthPageContext = () => {
  return useContext(AuthPageContext);
};
