import React, { useCallback } from "react";
import FramedBibleVerse from "../../../../framed_bible_verse";
import { bibleVersesWithoutTags } from "../../../../../utils/functions/bible_page_functions";

const SearchVerse = ({
  verse,
  bibleSearchWord,
  currentTranslation,
  setActiveScreenDesktop,
  setCurrentBibleParams,
  setSelectedVerses,
}) => {
  const onBibleSearchAddress = useCallback(
    (verse) => {
      const selectedVerse = {
        ...verse,
        verse_text: [bibleVersesWithoutTags(verse.verse_text[0])],
      };
      setCurrentBibleParams((prevState) => ({
        ...prevState,
        bookId: verse.book_code,
        chapterId: verse.chapter_num,
      }));
      setSelectedVerses([selectedVerse]);
      setActiveScreenDesktop("bible_modal_chapter");
    },
    [setCurrentBibleParams]
  );

  return (
    <div>
      <FramedBibleVerse
        background={"#EAEAEA"}
        bibleSearchWord={bibleSearchWord}
        inComponent={"bible_modal_search"}
        verse={verse}
        currentTranslation={currentTranslation}
        onBibleSearchAddress={onBibleSearchAddress}
      />
    </div>
  );
};

export default SearchVerse;
