import React, { memo, useCallback } from "react";
import styled from "styled-components";
import { Slide, toast } from "react-toastify";
import copy from "copy-to-clipboard";
import ShareIcon from "../../../../common/assets/icons/share_icon";
import CourseInfoAuthor from "./course_author";
import { APPLICATION_ROUTES } from "../../../../common/utils/routes";

const CourseInfoWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 6px;
  height: 100%;
  max-height: 100%;
  color: #ffffff;
  padding-top: 22px;

  @media (max-width: 1024px) {
    padding-top: 20px;
  }

  @media (max-width: 800px) {
    padding-top: 17px;
  }

  @media (max-width: 744px) {
    padding-top: 12px;
  }
`;

const CourseInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;

  @media (max-width: 880px) {
    gap: 10px;
  }
`;

const CourseInfoTitle = styled.p`
  font-weight: 600;
  letter-spacing: -0.4px;
  font-size: 32px;
  line-height: 120%;
  width: 65%;

  @media (max-width: 1024px) {
    font-size: 28px;
    line-height: 120%;
  }

  @media (max-width: 920px) {
    font-size: 24px;
    line-height: 120%;
  }

  @media (max-width: 880px) {
    font-size: 22px;
    line-height: 120%;
  }

  @media (max-width: 744px) {
    font-size: 18px;
    line-height: 120%;
    letter-spacing: 0.42px;
  }

  @media (max-width: 670px) {
    font-size: 24px;
  }

  @media (max-width: 412px) {
    font-size: 20px;
    line-height: 140%;
  }
`;

const CourseInfoText = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 145%;
  width: 66%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;

  @media (max-width: 1024px) {
    font-size: 12px;
  }

  @media (max-width: 744px) {
    font-size: 10px;
    letter-spacing: 0.32px;
  }

  @media (max-width: 670px) {
    font-size: 12px;
  }
`;

const AuthorsContainer = styled.div`
  display: flex;
  gap: 15px;
  flex-wrap: wrap;

  @media (max-width: 1024px) {
    gap: 4px;
  }

  @media (max-width: 744px) {
    margin-bottom: 2px;
  }
`;

const ShareCourseButton = styled.div`
  position: absolute;
  top: 22px;
  right: 22px;
  transition: 0.2s;
  cursor: pointer;
  opacity: 0.5;

  &:hover {
    transform: scale(1.05);
  }

  @media (max-width: 1024px) {
    top: 18px;
    right: 18px;
  }

  @media (max-width: 800px) {
    top: 14px;
    right: 14px;
  }

  @media (max-width: 670px) {
    top: 18px;
    right: 18px;
  }

  svg {
    width: 30px;
    height: 29px;

    @media (max-width: 1024px) {
      width: 27px;
      height: 26px;
    }

    @media (max-width: 880px) {
      width: 25px;
      height: 23px;
    }

    @media (max-width: 800px) {
      width: 23px;
      height: 21px;
    }

    @media (max-width: 744px) {
      width: 21px;
      height: 20px;
    }

    @media (max-width: 670px) {
      width: 25px;
      height: 23px;
    }
  }
`;

const CourseInfo = ({ courseData }) => {
  const showNotification = () => {
    toast("Ссылка на курс скопирована!", {
      position: "top-left",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      closeButton: false,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: "light",
      pauseOnFocusLoss: false,
      transition: Slide,
    });
  };

  const copyToClipboard = (text) => {
    try {
      const isCopied = copy(text);
      if (isCopied) {
        showNotification();
      } else {
        console.error("Не удалось скопировать.");
        alert("Ошибка при копировании ссылки");
      }
    } catch (error) {
      console.error("Ошибка копирования: ", error);
      alert("Ошибка при копировании ссылки");
    }
  };

  const handleCopyCourseLink = useCallback(
    (event) => {
      event.stopPropagation();
      const courseLink = `${process.env.REACT_APP_BASE_URL}${APPLICATION_ROUTES.STUDY_COURSES}/${courseData._id}`;
      copyToClipboard(courseLink);
    },
    [courseData]
  );

  return (
    <CourseInfoWrapper>
      <ShareCourseButton onClick={handleCopyCourseLink}>
        <ShareIcon />
      </ShareCourseButton>
      <CourseInfoContainer>
        <CourseInfoTitle>{courseData.course_name}</CourseInfoTitle>
        <CourseInfoText>{courseData.course_description}</CourseInfoText>
      </CourseInfoContainer>

      <AuthorsContainer>
        {courseData.course_authors.map((author) => (
          <CourseInfoAuthor key={author._id} authorId={author._id} />
        ))}
      </AuthorsContainer>
    </CourseInfoWrapper>
  );
};

export default memo(CourseInfo);
